import { DropStatus } from "../../enums/drop-status";
import db from "../driverappdb";
import queries from "../queries";

export const DropRepository = {
    getDrop: async (dropId) => {
        const drop = await db.drops.where({ id: dropId })
            .with({ location: 'location', driverRemarks: 'driverremarks' });

        const result = await queries.joinDropData(drop);
        return result?.length > 0 ? result[0] : null;
    },
    getDropsForRoute: async (routeId) => {
        return await queries.joinDropData(await db.drops.where({ routeId: routeId }).with({ location: 'location' }));
    },
    getDrops: async (dropIds) => {
        return await queries.joinDropData(await db.drops.where('id').anyOf(dropIds ?? []).with({ location: 'location' }));
    },
    getBusyDrop: async (routeId) => {
        return (await queries.joinDropData(await db.drops.where({ routeId: routeId }).toArray()))
            .find(drop => drop.status === DropStatus.BUSY);
    }
};