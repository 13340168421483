import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ConnectionChecker() {
    const [t] = useTranslation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [online, setOnline] = useState(true);

    const handleClose = useCallback((key) => {
        return <FontAwesomeIcon icon={["fal", "times"]} 
                    onClick={() => { closeSnackbar(key); }} style={{ marginRight: 10 }} />;
    }, [closeSnackbar]);

    const isOnline = useCallback(() => {
        enqueueSnackbar(t('YouAreOnline'), {
            variant: 'success',
            action: (key) => handleClose(key),
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right'
            },
            autoHideDuration: 300000
        });
    }, [enqueueSnackbar, t, handleClose]);

    const isOffline = useCallback(() => {
        enqueueSnackbar(t('YouAreOffline'), {
            variant: 'error',
            action: (key) => handleClose(key),
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right'
            },
            autoHideDuration: 300000
        });
    }, [enqueueSnackbar, t, handleClose]);

    const connectionCheck = useCallback(() => {
        if (navigator.onLine && (online === false)) {
            setOnline(true);
            isOnline();
        }

        if (!navigator.onLine && (online === true)) {
            setOnline(false);
            isOffline();
        }
    }, [isOnline, isOffline, online]);

    useEffect(() => {
        connectionCheck();
        const timer = setInterval(() => connectionCheck(), 1000);
        return () => { window.clearInterval(timer); };
    }, [connectionCheck]);

    return '';
}

export default ConnectionChecker;