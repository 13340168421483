import React, { useState } from 'react';

const TimelineContext = React.createContext([{}, () => { }]);

const TimelineProvider = (props) => {   
    const [state, setState] = useState(props.value);    
    return (
        <TimelineContext.Provider value={[state, setState]}>                 
            {props.children}
        </TimelineContext.Provider>
    );
}

export { TimelineContext, TimelineProvider };