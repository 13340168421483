import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import postmanPicture from '../../../../assets/images/general/postman.jpg'

const PlanningFinished = () => {
    const [t] = useTranslation();

    return (
        <Box display='flex' flexDirection='column' alignItems='center' my={6}>
            <Box my={4} style={{borderRadius: '25%', textAlign: 'center'}}>
                <img className="img-fluid" src={postmanPicture} alt="" style={{maxWidth: '50%'}} />
            </Box>
            <Typography variant="h6" align='center'>{t("AllDeliveriesAreFinished")}</Typography>
            <Typography variant="subtitle2" align='center'>{t("RoutesChangeInSettings")}</Typography>
        </Box>
    );
};

export default PlanningFinished;