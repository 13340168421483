import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../utils/contexts/appContext';

import './Error.css';
import pic404 from '../../assets/images/general/pic-404.jpg';
import Support from '../../components/support';

function Error() {
    const [t] = useTranslation();
    const [,dispatch] = useAppContext();

    useEffect(() => {
        dispatch({ type: 'changeHeader', header: { title: t("Error"), child: false, path: '/routes' } });
    }, [dispatch, t]);

    return (
        <div className="center global-container">
            <div className="error-header">{t("SomethingUnexpectedHappend")}</div>
            <img width="50%" src={pic404} alt={t("TryAgain")} />
            <p>{t("TryAgain")}</p>
            <p>{t("GeneralErrorMessage")}</p>
            <Support />
            <Button href="/routes" variant="contained" color="primary">{t("Routes")}</Button>
        </div>
    );
}
 
export default Error;