import React, { useState, useEffect, useContext } from 'react';
import { SwipeableDrawer, useTheme, Divider, List, ListItem, ListItemIcon, ListItemText, makeStyles, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../utils/contexts/appContext';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/logos/movetex.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { version } from '../../../package.json';
import db from '../../utils/dexie/driverappdb';
import { syncActions, fetchRoutes, syncOfflineData, fetchOfflineData, syncGeolocation } from '../../utils/dexie/sync';
import queries from '../../utils/dexie/queries';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { AuthContext } from '../../providers/AuthProvider';

const useStyles = makeStyles(theme => ({
    drawerContent: {
        flexGrow: '1 !important'
    },
    drawerCopyright: {
        margin: 15,
        textAlign: 'center',
        position: 'relative',
        left: 0,
        bottom: 0
    }
}));

const Drawer = () => {
    const theme = useTheme();
    const [{ header, drawerOpen, changesDetected }, dispatch] = useAppContext();
    const [t,] = useTranslation();
    const classes = useStyles();
    const [isAdmin, setIsAdmin] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const auth = useContext(AuthContext);
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

    const showNotification = useCallback(() => {
        enqueueSnackbar(t("RoutesHaveBeenChanged"), {
            className: "notification-changes-detected",
            variant: "info",
            autoHideDuration: 3000,
            preventDuplicate: true,
            action: (key) => (
                <IconButton color="inherit" onClick={() => closeSnackbar(key)} style={{paddingTop: 4, paddingBottom: 4}}>
                    <FontAwesomeIcon icon={["fal", "times"]} />
                </IconButton>
            ),
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right'
            }
        });
    }, [enqueueSnackbar, closeSnackbar, t]);

    const detectChanges = useCallback(() => {
        queries.getNotificationCount()
            .then(count => {
                if (count > 0) {
                    if (!changesDetected) {
                        showNotification();
                        dispatch({
                            type: 'changesDetection',
                            changesDetected: true
                        });
                    }
                } else {
                    dispatch({
                        type: 'changesDetection',
                        changesDetected: false
                    });
                }
            });
    }, [changesDetected, dispatch, showNotification]);

    useEffect(() => { }, [header]);

    useEffect(() => {
        
        const render = async () => {
            return await auth.getUser()
                .then(async (user) => {
                    if (user && user.profile) {
                        let userId = user.profile.sub;
                        if (userId !== null) {
                            await auth.fetch('get', `users/${userId}`)
                                .then(user => setIsAdmin(user ? user.data.isAdmin : false));
                        }

                        return await db.datastore.put({
                                key: 'syncing',
                                value: 0
                            })
                            .then(async () => {
                                return await fetchOfflineData(auth) 
                                    .then(() => syncActions(auth))
                                    .then(({ canFetchNewRoutes, actionsExecuted }) => fetchRoutes(auth, user, canFetchNewRoutes, actionsExecuted))
                                    .then(detectChanges)
                                    .then(async () => await syncOfflineData(auth))
                                    .catch(error => console.error(error));
                            })
                            .catch(error => console.error(error));
                    }
                });
        };

        render();

        const timer = setInterval(async () => {
            await auth.getUser()
                .then(async (user) => {
                    return await syncActions(auth)
                        .then(({canFetchNewRoutes, actionsExecuted}) => fetchRoutes(auth, user, canFetchNewRoutes, actionsExecuted))
                        .then(detectChanges)
                        .then(async () => await syncOfflineData(auth))
                        .catch(error => console.error(error));
                });
        }, 60000);

        const geoLocationTimer = setInterval(async () => {
            await syncGeolocation(auth);
        }, 15000);

        return () => { 
            window.clearInterval(timer); 
            window.clearInterval(geoLocationTimer);
        };

    }, [auth, dispatch, detectChanges]);

    const handleToggler = () => {
        dispatch({ type: 'toggleDrawer' });
    }

    return (
        <nav>
            <SwipeableDrawer disableBackdropTransition={!iOS} disableDiscovery={iOS}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={drawerOpen}
                onClose={handleToggler}
                onOpen={handleToggler}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}>
                <div className={classes.drawerContent}>
                    <NavLink to="/">
                        <img src={logo} style={{ padding: "20px 0px 0px 10px" }} width="150" alt="Movetex" />
                    </NavLink>
                    <Divider light={true} />
                    <List>
                        <ListItem button onClick={handleToggler}>
                            <NavLink to="/routes" replace={header.child}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={["fas", "route"]} size="lg" />
                                </ListItemIcon>
                                <ListItemText primary={t("Routes")} />
                            </NavLink>
                        </ListItem>
                        <ListItem button onClick={handleToggler}>
                            <NavLink to="/settings" replace={header.child}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={["fas", "cog"]} size="lg" />
                                </ListItemIcon>
                                <ListItemText primary={t("Settings")} />
                            </NavLink>
                        </ListItem>
                        {
                            isAdmin ?
                                <ListItem button onClick={handleToggler}>
                                    <NavLink to="/admin" replace={header.child}>
                                        <ListItemIcon>
                                            <FontAwesomeIcon icon={["fas", "user-cog"]} size="lg" />
                                        </ListItemIcon>
                                        <ListItemText primary="Admin" />
                                    </NavLink>
                                </ListItem> : <></>
                        }
                    </List>
                    <Divider light={true} />
                    <List>
                        <ListItem button>
                            <NavLink to="/logout">
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={["fas", "sign-out-alt"]} size="lg" />
                                </ListItemIcon>
                                <ListItemText primary="Uitloggen" />
                            </NavLink>
                        </ListItem>
                    </List>
                </div>
                <div className={classes.drawerCopyright}>
                    <p>
                        &copy; MOVETEX - v {version}<br />
                        All rights reserved.
                    </p>
                </div>
            </SwipeableDrawer>
        </nav>
    );

}

export default Drawer;