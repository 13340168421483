import React from 'react';
import i18n from "../../i18n";
import { Chip } from '@material-ui/core';

const DropType = ({ dropType }) => {

    const lookUpTranslation = () => {
        let translation;

        if (dropType != null && dropType.translations != null && dropType.translations.length > 0) {
            translation = dropType.translations.find(t => t.language?.abbreviation.toLowerCase() === i18n.language.toLowerCase());
            
            // Fallback for a language which doesn't exists inside the labels.
            if (translation == null)
                translation = dropType.translations[0];
        }

        return translation?.translatedTerm;
    }

    return (
        <>
            {
                (dropType != null && (!Array.isArray(dropType) || dropType.length > 0)) ?
                    <Chip label={lookUpTranslation()} size="small" color="primary" style={{
                        color: dropType.foregroundColor,
                        backgroundColor: dropType.backgroundColor
                    }} /> : <></>
            }
        </>
    );

}

export default DropType;