import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import Loader from '../loader';
import ImageLibraryView from './ImageLibraryView';
import db from '../../utils/dexie/driverappdb';
import queries from '../../utils/dexie/queries';
import { useHistory } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

const ImageLibraryContainer = forwardRef(({ dropId, onlineImagesEnabled = false, isEditable = false, internalImages = false, onDeleteCallback = () => {} }, ref) => {

    const [t] = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [loadingVariant, setLoadingVariant] = useState();
    const [loadingText, setLoadingText] = useState("");
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [images, setImages] = useState([]);
    const [appParams, setAppParams] = useState([]);
    const [drop, setDrop] = useState();
    const { enqueueSnackbar } = useSnackbar();
    let history = useHistory();

    useEffect(() => {
        
        db.transaction('r', db.drops, db.images, db.params, async () => {
            setAppParams(await db.params.where('id').notEqual(0).first());
            setDrop(await db.drops.get(parseInt(dropId)));

            await db.images.where({ dropId: parseInt(dropId) }).filter(image => image.internal === internalImages).toArray()
                .then((result) => {
                    let imgs = [];
                    for (let index = 0; index < result.length; index++) {
                        const img = result[index];
                        imgs.push({
                            src: img.blob,
                            width: 3,
                            height: 3,
                            id: img.id,
                            key: img.id.toString(),
                            lastModified: img.lastModified
                        });
                    }
                    return imgs;
                })
                .then(imgs => setImages(imgs));
        })
        .finally(() => setIsLoading(false));
        
    }, [dropId, internalImages]);

    const onImageDelete = (imageId) => {
        try {

            if (imageId != null) {
                db.transaction('rw', db.images, db.actions, async () => {
                    await db.images.where({ id: parseInt(imageId) }).first()
                        .then((image) => {
                            let now = new Date();
                            var utc = now.getTime() - now.getTimezoneOffset() * 60000;
                            db.actions.put({ type: "ImageDelete", key: image.documentId, time: utc });
                        });

                    await db.images.delete(imageId)
                        .then(() => {
                            let array = [...images];
                            var item = array.find(x => x.id === imageId);
                            var index = array.indexOf(item)
                            if (index !== -1) {
                                array.splice(index, 1);
                                setImages(array);
                            }
                        });
                })
                .then(onDeleteCallback);
            }

        } catch (error) {
            queries.addErrorToLogs(error);
            history.push(`/error`);
        }
    }

    useImperativeHandle(ref, () => ({

        addImages: async (files) => {
            try {
                setLoadingVariant("determinate");
                setIsLoading(true);

                const compressOptions = {
                    maxSizeMB: appParams.imagesMaxSizeMb,
                    onProgress: setLoadingProgress
                };

                let filesToHandle = [...files];
                let supportedFileTypes = ["image/jpeg", "image/png", "image/bmp"];
                if (filesToHandle)
                    filesToHandle = filesToHandle.filter(file => supportedFileTypes.indexOf(file.type) > -1);

                if (files.length > filesToHandle.length) {
                    enqueueSnackbar(t('UnsupportedFileFormatSelection'), {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        },
                        autoHideDuration: 5000
                    });
                }

                if (filesToHandle && filesToHandle.length > 0) {
                    for (let fileIndex = 0; fileIndex < filesToHandle.length; fileIndex++) {
                        let file = filesToHandle[fileIndex];
                        setLoadingText(`${t("Image")} ${fileIndex + 1} ${t("Of")} ${filesToHandle.length}`);
                        setLoadingProgress(0);

                        await imageCompression(file, compressOptions)
                            .then(compressedFile => imageCompression.getDataUrlFromFile(compressedFile))
                            .then(dataUrl => {
                                return db.transaction('rw', db.images, db.actions, async () => {
                                    db.images.put({
                                        dropId: parseInt(dropId), 
                                        blob: dataUrl, 
                                        name: file.name, 
                                        documentId: 0,
                                        lastModified: new Date(file.lastModified),
                                        internal: internalImages
                                    })
                                    .then(id => {
                                        let image = {
                                            src: dataUrl,
                                            width: 3,
                                            height: 3,
                                            id: id,
                                            key: id.toString(),
                                            lastModified: new Date(file.lastModified)
                                        }
                    
                                        setImages(images => [...images, image]);
                    
                                        let now = new Date();
                                        var utc = now.getTime() - now.getTimezoneOffset() * 60000;
                                        db.actions.put({ type: "ImageAdd", key: id, time: utc });
                                        setLoadingText("");
                                    })
                                });
                            })
                            .then(() => {
                                if (fileIndex === filesToHandle.length - 1) {
                                    setIsLoading(false);
                                    setLoadingVariant();
                                }
                            });
                    }
                } else {
                    setIsLoading(false);
                    setLoadingVariant();
                }

            } catch (error) {
                queries.addErrorToLogs(error);
                history.push(`/error`);
            }
        }

    }));

    return (
        <div>
            {
                isLoading ?
                    <Loader variant={loadingVariant} loadingText={loadingText} progress={loadingProgress} /> : 
                    <ImageLibraryView dropId={dropId} drop={drop} images={images} isEditable={isEditable} 
                        onlineImagesEnabled={onlineImagesEnabled} internalImages={internalImages} onDeleteCallback={onImageDelete} />
            }
        </div>
    );

})

export default ImageLibraryContainer;