import { detect } from "detect-browser";

export default class UserAgentService {
    
    browser = detect();

    getOperatingSystem = () => {
        if (!this.browser || !this.browser.name) { // This browser cannot be detected.
            return '';
        }

        return `${this.browser.os}`;
    }

    checkBrowserSupport = () => {

        if (!this.browser || !this.browser.name) { // This browser cannot be detected.
            return {
                supported: false,
                recommendedBrowser: 'Google Chrome'
            };
        }

        switch (this.browser.os) {
            case 'iOS':
            case 'Mac OS':
                // Supported only if using ios or Safari
                return {
                    supported: ['ios', 'safari'].indexOf(this.browser.name) >= 0,
                    recommendedBrowser: 'Safari'
                }
            case 'Android OS':
                // Currently allow all kind of browsers on Androidd 
                return {
                    supported: true,
                    recommendedBrowser: 'Google Chrome'
                };
            default:
                // Supported only if using Chrome
                return {
                    supported: this.browser.name === 'chrome',
                    recommendedBrowser: 'Google Chrome'
                };
        }
        
    }

    /**
     * Returns whether the feature is supported by detection of the User-Agent information.
     */
    isFeatureSupported = (feature) => {
        if (!this.browser || !this.browser.name) { // This browser cannot be detected.
            return false;
        }

        switch(feature) {
            case 'browser_data_clear':
            case 'settings_system_memory_usage':
                return this.browser.name === 'chrome' && ['iOS', 'Mac OS'].indexOf(this.browser.os) < 0;
            default:
                return false;
        }
    }

}