import { useContext } from "react";
import Depot from "./depot"
import { RoutePlanningContext, RoutePlanningDispatchContext } from "../../../../../utils/contexts/route-planning-context";
import PlanningItemActions from "../planning-item-actions";
import UpdateStatusButton from "../action-buttons/update-status-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FontAwesomeDuoToneStyle } from "../../../../../fontawesome";
import { useRouteStatusManagement } from "../../../../../hooks/route-status-management";
import { AppContext } from "../../../../../utils/contexts/appContext";
import { RouteStatus } from "../../../../../utils/enums/route-status";
import { RouteRepository } from "../../../../../utils/dexie/repositories/route-repository";
import { useSinglePress } from "../../../../../hooks/single-press";
import PlanningItemHeader from "../planning-item-header";
import PlanningItemHeaderBadge from "../planning-item-header-badge";
import { Typography } from "@material-ui/core";

const StartDepot = (props) => {
    const [{ pause }] = useContext(AppContext);
    const { routeId, route, openRoutesCount } = useContext(RoutePlanningContext);
    const dispatch = useContext(RoutePlanningDispatchContext);

    const updateRouteStatus = useRouteStatusManagement(routeId);

    const startRoute = useSinglePress(async (e) => {
        await updateRouteStatus(RouteStatus.BUSY)
            .then(dispatch({ 
                type: 'route.status', 
                payload: { 
                    newStatus: RouteStatus.BUSY, 
                    openRoutesCount: await RouteRepository.countOpenRoutes()
                }
            }));
    });

    return (
        route != null ?
            <Depot {...props}
                header={
                    <PlanningItemHeader>
                        <PlanningItemHeaderBadge>
                            <FontAwesomeIcon icon={["fad", "clock"]} style={FontAwesomeDuoToneStyle} />
                            <Typography component="span">
                                {route.startString}
                            </Typography>
                        </PlanningItemHeaderBadge>
                    </PlanningItemHeader>
                }>
                {
                    (route.status === RouteStatus.NEW || route.status === RouteStatus.DISPATCHED || route.status === RouteStatus.INTERRUPTED) ?
                        <PlanningItemActions>
                            <UpdateStatusButton iconColor="success" icon={<FontAwesomeIcon size="lg" icon={["fad", (route.status === RouteStatus.INTERRUPTED ? "repeat-alt" : "check")]} />}
                                disabled={pause || openRoutesCount > 0} onClick={startRoute} />
                        </PlanningItemActions> : null
                }
            </Depot> 
            : null
    );
}

export default StartDepot;