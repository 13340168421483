import React, { forwardRef } from 'react';
import FeedbackView from './FeedbackView';
import Loader from '../../components/loader';
import { Grid } from '@material-ui/core';
import { useLiveQuery } from 'dexie-react-hooks';
import { FeedbackRepository } from '../../utils/dexie/repositories/feedback-repository';

const FeedbackContainer = forwardRef(({ dropId, feedbackKey, onRequiredChange = (required) => {} }, ref) => {
    const feedback = useLiveQuery(() => FeedbackRepository.getFeedback(dropId), [dropId]);
    const feedbackItems = useLiveQuery(() => FeedbackRepository.getFeedbackItems(feedbackKey), [feedbackKey]);

    const isLoading = !feedback || !feedbackItems;

    return (
        <Grid container>
            { isLoading ? <Loader /> : <FeedbackView ref={ref} dropId={dropId} items={feedbackItems} feedback={feedback} onRequiredChange={onRequiredChange} /> }
        </Grid>
    );
});

export default FeedbackContainer;