import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Divider } from '@material-ui/core';
import { AuthContext } from '../../providers/AuthProvider';
import { useSnackbar } from 'notistack';
import Loader from '../loader';
import db from '../../utils/dexie/driverappdb';

const ImageViewerActionButtons = ({ innerProps, components, getStyles, modalProps, isModal, index, children, isEditable, onImageDelete = () => {} }) => {
    const { CloseButton, FullscreenButton } = components;
    const { onClose, toggleFullscreen } = modalProps;

    return (
        <>
            {
                isModal &&
                    <div style={{ position: "absolute", top: 0, right: 0, display: "flex", zIndex: 1, padding: "12px" }}>
                        {
                            isEditable &&
                                <button style={{ height: "40px" }} className="react-images__header_button react-images__header_button--close css-m6j0xf css-1ycyyax"
                                    onClick={onImageDelete}>
                                    <FontAwesomeIcon icon={["fad", "trash-alt"]} size="lg" />
                                </button>
                        }
                        <FullscreenButton getStyles={getStyles} style={{ height: "40px" }} innerProps={{ onClick: toggleFullscreen, title: "fullscreen" }}>
                            <FontAwesomeIcon icon={["fad", "expand"]} size="lg" />
                        </FullscreenButton>
                        <CloseButton getStyles={getStyles} style={{ height: "40px" }} innerProps={{ onClick: onClose, title: "fullscreen" }}>
                            <FontAwesomeIcon icon={["fad", "times"]} size="lg" />
                        </CloseButton>
                    </div>
            }
        </>
    );
}

const OnlineImagesView = ({ dropId, mainProjectId, internalImages }) => {

    const shownFileExtensions = [".jpg", ".jpeg", ".png"];

    const auth = useContext(AuthContext);
    const [t] = useTranslation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [imageViewerOpen, setImageViewerOpen] = useState(false);
    const [, setCurrentImageId] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [onlineImagesRequested, setOnlineImagesRequested] = useState(false);
    const [onlineImagesIsLoading, setOnlineImagesIsLoading] = useState(false); 
    const [visibleOnlineImages, setVisibleOnlineImages] = useState([]);

    const isNullOrWhiteSpace = (value) => {
        return value == null || value.match(/^\s*$/) !== null;
    }

    const loadOnlineImages = (e) => {
        if (!navigator.onLine) {
            e.preventDefault();
            enqueueSnackbar(t('YouAreOffline'), {
                variant: 'error',
                action: (key) => handleCloseSnackbar(key),
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                },
                autoHideDuration: 3000
            });
        }
        else {
            setOnlineImagesRequested(true);

            auth.getUser()
                .then(user => {
                    setOnlineImagesIsLoading(true);
                    const url =  mainProjectId != null ? `documents/formainproject/${mainProjectId}` : `documents/${dropId}`;
                    auth.fetch('get', `${url}?internalUseOnly=${internalImages}`)
                        .then(async (result) => {
                            if (result && result.status === 200 && result.data != null) {
                                var filteredDocuments = result.data.filter(doc => shownFileExtensions.includes(doc.extension));

                                return db.transaction('r', db.images, async () => {
                                    await db.images.where({ dropId: parseInt(dropId) }).toArray()
                                        .then((localImages) => {
                                            let localDocumentIds = localImages ? localImages.map(img => img.documentId) : [];

                                            let onlineImages = [];
                                            for (let index = 0; index < filteredDocuments.length; index++) {
                                                const img = filteredDocuments[index];
                                                if (!localDocumentIds.includes(img.documentId)) {
                                                    let src = `${process.env.REACT_APP_WEB_URL}`;
                                                    if (!isNullOrWhiteSpace(img?.filePath)) {
                                                        let filePath = img.filePath.replaceAll('\\', '/');
                                                        if (!filePath.startsWith('/'))
                                                            filePath += '/';
                                                        src += filePath;
                                                    }

                                                    onlineImages.push({
                                                        src: src,
                                                        width: 3,
                                                        height: 3,
                                                        id: img.documentId,
                                                        key: img.documentId.toString()
                                                    });
                                                }
                                            }

                                            setVisibleOnlineImages(onlineImages);
                                        });
                                });
                            }
                        })
                        .finally(() => setOnlineImagesIsLoading(false));
                });
        }
    }

    const handleCloseSnackbar = (key) => {
        return <FontAwesomeIcon icon={["fal", "times"]} 
                    onClick={() => { closeSnackbar(key); }} style={{ marginRight: 10 }} />;
    };

    const openImageViewer = (event, { photo, index }) => {
        setCurrentImageId(photo.id);
        setCurrentImageIndex(index);
        setImageViewerOpen(true);
    }

    const closeImageViewer = () => {
        setCurrentImageIndex(0);
        setImageViewerOpen(false);
    }

    return (
        <>
            <Box>
                <Button onClick={loadOnlineImages} variant="outlined" color="primary" style={{float: "right", marginBottom: 10}}>
                    <FontAwesomeIcon icon={["fad", "cloud-download-alt"]} size="2x" style={{marginRight: 15}} /> {t("OldPictures")}
                </Button>
            </Box>
            <Box style={{clear: "both"}}>
                {
                    onlineImagesRequested ?
                        <div>
                            {
                                onlineImagesIsLoading ?
                                    <Loader /> :
                                    <>
                                        {
                                            (!visibleOnlineImages || visibleOnlineImages.length === 0) ?
                                                t("NoPicturesAvailable") :
                                                <Gallery photos={visibleOnlineImages} direction="column" columns={3} onClick={openImageViewer} />
                                        }
                                    </>
                            }
                        </div> : <></>
                }
            </Box>
            <ModalGateway>
                {
                    imageViewerOpen ?
                        <Modal 
                            allowFullscreen={true} 
                            closeOnBackdropClick={false} 
                            onClose={closeImageViewer}>
                            <Carousel 
                                currentIndex={currentImageIndex}
                                components={{ 
                                    Header: (props) => <ImageViewerActionButtons {...props} isEditable={false} /> 
                                }}
                                views={visibleOnlineImages.map(x => ({
                                    ...x,
                                    srcset: x.srcSet,
                                    caption: x.title
                                }))} />
                        </Modal> : <></>
                }
            </ModalGateway>
        </>
    );

}

const ImageLibraryView = ({ dropId, drop, images, isEditable, onlineImagesEnabled, internalImages, onDeleteCallback = () => {} }) => {

    const [t] = useTranslation();
    const [imageViewerOpen, setImageViewerOpen] = useState(false);
    const [currentImageId, setCurrentImageId] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [visibleImages, setVisibleImages] = useState([]);

    useEffect(() => {
        setVisibleImages(images?.sort((a, b) => b.lastModified - a.lastModified).map(({ lastModified, ...rest }) => rest));
    }, [images]);

    const openImageViewer = (event, { photo, index }) => {
        setCurrentImageId(photo.id);
        setCurrentImageIndex(index);
        setImageViewerOpen(true);
    }

    const closeImageViewer = () => {
        setCurrentImageIndex(0);
        setImageViewerOpen(false);
    }

    const onImageDelete = () => {
        if (currentImageId != null) {
            onDeleteCallback(currentImageId);
            closeImageViewer();
        }
    }

    return (
        <div>
            {
                (!visibleImages || visibleImages.length === 0) ?
                    t("NoPicturesAvailable") :
                    <>
                        <Gallery photos={visibleImages} direction="column" columns={3} onClick={openImageViewer} />
                        <ModalGateway>
                            {
                                imageViewerOpen ?
                                    <Modal 
                                        allowFullscreen={true} 
                                        closeOnBackdropClick={false} 
                                        onClose={closeImageViewer}>
                                        <Carousel 
                                            currentIndex={currentImageIndex}
                                                components={{ 
                                                    Header: (props) => <ImageViewerActionButtons {...props} isEditable={isEditable} onImageDelete={onImageDelete} /> 
                                                }}
                                                views={visibleImages.map(x => ({
                                                    ...x,
                                                    srcset: x.srcSet,
                                                    caption: x.title
                                                }))} />
                                    </Modal> : <></>
                            }
                        </ModalGateway>
                    </>
            }
            {
                onlineImagesEnabled ?
                    <>
                        <Divider />
                        <OnlineImagesView dropId={dropId} mainProjectId={drop.mainProjectId} internalImages={internalImages} />
                    </> : <></>
            }
        </div>
    );

}

export default ImageLibraryView;