/*import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import utils from "../../../../utils";
import { useContext } from "react";
import { RoutePlanningContext } from "../../../../utils/contexts/route-planning-context";
import { useApplicationParams } from "../../../../hooks/app-params";*/

const PlanningItemDelay = () => {
    /*const appParams = useApplicationParams();
    const { route } = useContext(RoutePlanningContext);
    const [delay, setDelay] = useState(0);*/

    /*useEffect(() => {
        if (route != null)
            setDelay(utils.calculateDelay(new Date(route.start), new Date()));
    }, [route]);

    return (
        appParams?.displayDelay && delay > 0 ? 
            <Typography component="span" style={{marginLeft: 5}}>
                {utils.getDelayString(delay)}
            </Typography>
            : null
    );*/

    return null;
};

export default PlanningItemDelay;