import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import db from "../../utils/dexie/driverappdb";
import { useState } from "react";
import { useCallback } from "react";
import i18n from "../../i18n";
import { useEffect } from "react";
import { useImperativeHandle } from "react";
import { forwardRef } from "react";

const PauseDialog = forwardRef(({
    children,
    isOpen = false,
    onCancel = () => {},
    onConfirm = (breakType, remark) => {}
}, ref) => {
    const [t,] = useTranslation();
    const [remark, setRemark] = useState({ value: null, defaultValue: null });
    const [breakType, setBreakType] = useState('');
    const [breakTypes, setBreakTypes] = useState([]);

    const resetDialog = useCallback(async () => {
        db.transaction('rw', db.breaktypes, async () => {
            let breakTypes = await db.breaktypes.toArray();
            setBreakTypes(breakTypes.sort((a, b) => a.translatedTerm?.localeCompare(b.translatedTerm)));

            let defaultBreakType = breakTypes.find(bt => bt.isDefault);
            if (defaultBreakType != null) {
                setBreakType(defaultBreakType.id);

                if (defaultBreakType != null) {
                    let defaultTranslatedRemark = lookUpTranslatedRemark(defaultBreakType);
                    setRemark({ value: defaultTranslatedRemark, defaultValue: defaultTranslatedRemark});
                }
            } else {
                setBreakType('');
                setRemark({ value: null, defaultValue: null});
            }
        });
    }, []);

    useImperativeHandle(ref, () => {
        return {
            resetDialog
        };
    }, [resetDialog]);

    useEffect(resetDialog, [resetDialog]);

    const validate = () => {
        let remarkValid = remark != null && remark.value != null && remark.value.replace(/\s/g, '').length > 0; 
        let breakTypeValid = breakTypes.length === 0 || breakType;
        return remarkValid && breakTypeValid;
    }

    const onChangeBreakType = (e) => {
        let newSelectedBreakTypeId = e.target.value;
        setBreakType(newSelectedBreakTypeId);
        
        let breakType = breakTypes.find(bt => bt.id === newSelectedBreakTypeId);
        if (breakType != null && (!remark.value || remark.value === remark.defaultValue)) {
            let defaultTranslatedRemark = lookUpTranslatedRemark(breakType);
            setRemark({ value: defaultTranslatedRemark, defaultValue: defaultTranslatedRemark });
        }
    };

    const lookUpTranslatedRemark = (breakType) => {
        var translation;

        if (breakType != null && breakType.remarkTranslations != null && breakType.remarkTranslations.length > 0) {
            translation = breakType.remarkTranslations.find(t => t.language?.abbreviation.toLowerCase() === i18n.language.toLowerCase());
            
            // Fallback for a language which doesn't exists inside the labels.
            if (translation == null)
                translation = breakType.remarkTranslations[0];
        }

        return translation?.translatedTerm;
    }

    return (
        <>
            {children}
            <Dialog
                open={isOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{t("Pause")}</DialogTitle>
                <DialogContent id="alert-dialog-description">
                    <TextField 
                        label={ t("Remark") }
                        value={remark.value ?? ''}
                        required={true}
                        fullWidth={true} 
                        multiline={true} 
                        rows={3}
                        variant="outlined"
                        style={{ marginBottom: "10px" }} 
                        onChange={(e) => setRemark(state => ({ ...state, value: e.target.value }))} />
                    { 
                        breakTypes.length > 0 ?
                        <>
                            <DialogContentText>{t("StartPauseTypeSelection")}</DialogContentText>
                            <DialogContent style={{ marginBottom: "10px" }}>
                                <Select labelId="breakTypeLabel" id="breakType" value={breakType} onChange={onChangeBreakType}>
                                    {breakTypes.length > 0 && breakTypes.map(breakType => <MenuItem key={breakType.id} value={breakType.id}>{breakType.translatedTerm}</MenuItem>)}
                                </Select>
                            </DialogContent>
                        </> :
                        <></>
                    }
                    {
                        (breakTypes.length === 0 || breakType) &&
                            <DialogContentText>{t("StartPauseActivitiesQuestion")}</DialogContentText>
                    }
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={onCancel}>{t("Cancel")}</Button>
                    <Button color="primary" onClick={() => onConfirm(breakType, remark)} autoFocus disabled={!validate()}>{t("Yes")}</Button>
                </DialogActions>
            </Dialog>
        </>
    ); 
});

export default PauseDialog;