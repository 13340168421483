import React, { useContext, useEffect, useCallback } from "react";
import { withSnackbar } from 'notistack';
import { firebaseConfig } from "./firebase-config";
import firebase from 'firebase/app';
import 'firebase/messaging';
import { AuthContext } from '../providers/AuthProvider';
import db from '../utils/dexie/driverappdb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const defaultCloudMessagingTopics = [ "litefleet-driver-app" ];

const FirebaseManager = (props) => {
    const auth = useContext(AuthContext);
    
    const handleMessageReceived = useCallback((payload) => {
        if (payload && payload.notification) {
            const notification = payload.notification;
            props.enqueueSnackbar(notification.title + " - " + notification.body, {
                variant: 'info',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                },
                autoHideDuration: 20000,
                action: (key) => <FontAwesomeIcon icon={["fal", "times"]} onClick={() => props.closeSnackbar(key)} style={{ marginRight: 10 }} />
            });
        }
    }, [props]);

    const sendTokenToServer = useCallback(async (token) => {
        // Load the previous token if it exists
        let oldToken = await db.datastore.where({ key: "fcm_token" }).first();

        if (navigator.onLine) {
            let body = {
                token: token,
                oldToken: oldToken ? oldToken.value : null
            };

            auth.getUser()
                .then((user) => {
                    auth.fetch('put', `cloudmessaging/token/refresh`, body)
                        .then(async (result) => {
                            if (result && result.status === 200) {
                                // Store the new token
                                await db.datastore.put({ key: "fcm_token", value: token });
                            }
                        });
                });
        }
    }, [auth]);

    const subscribeForDefaultTopics = useCallback((token) => {
        if (navigator.onLine) {
            auth.getUser()
                .then((user) => {
                    let body = {
                        token: token,
                        topics: defaultCloudMessagingTopics
                    };

                    auth.fetch('post', `cloudmessaging/topics/subscribe`, body);
                });
        }
    }, [auth]);

    const initializeFirebase = useCallback(async (requestPermission) => {
        // Initialize the Firebase app
        firebase.initializeApp(firebaseConfig);
    
        // Initialize the Cloud Messaging
        const messaging = firebase.messaging();
    
        // If we're using a custom service worker, we need to configure Firebase to use our service worker instead of looking for the default service worker of Firebase.
        navigator.serviceWorker.getRegistration()
            .then(serviceWorkerRegistration => {
                if (serviceWorkerRegistration) {
                    messaging.useServiceWorker(serviceWorkerRegistration);
                }
            })
            .then(async () => {
                if (requestPermission) {
                    await messaging.requestPermission()
                        .then(() => messaging.getToken())
                        .then(async (token) => {
                            await sendTokenToServer(token);
                            return token;
                        })
                        .then(token => subscribeForDefaultTopics(token))
                        .catch(err => console.error(err));
                }
            });
        
        return messaging;
    }, [sendTokenToServer, subscribeForDefaultTopics]);

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready
                .then(() => {
                    initializeFirebase(true)
                        .then(messaging => {
                            messaging.onTokenRefresh(() => {
                                messaging.getToken()
                                    .then(refreshedToken => sendTokenToServer(refreshedToken))
                                    .catch(error => console.log('Unable to retrieve refreshed token ', error));
                            });
                            messaging.onMessage(payload => handleMessageReceived(payload));
                        })
                        .catch(error => console.error(error));
                });
        } else {
            console.error("Unable to initialize Cloud Messaging because this browser doesn't support Service Workers.");
        }
    }, [handleMessageReceived, initializeFirebase, sendTokenToServer]);

    return <>{props.children}</>;
};

export default withSnackbar(FirebaseManager);