import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import utils from '../../utils';
import queries from '../../utils/dexie/queries';
import { useApplicationParams } from '../../hooks/app-params';

const UsageChecker = () => {
    const [t] = useTranslation();
    const appParams = useApplicationParams();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        if (appParams?.displayModeDetection && utils.isLiveEnvironment()) { 
            const standAloneModeSelector = '(display-mode: standalone)';
            if (!(navigator.standalone || window.matchMedia(standAloneModeSelector).matches)) {
                queries.addLogMessage("[USAGE] This user is using the DriverApp in the browser and not as PWA.");
    
                enqueueSnackbar(t('WrongDisplayMode'), {
                    className: "notification-flex",
                    variant: 'error',
                    action: (key) => (
                        <IconButton color="inherit" onClick={() => closeSnackbar(key)} style={{paddingTop: 4, paddingBottom: 4}}>
                            <FontAwesomeIcon icon={["fal", "times"]} />
                        </IconButton>
                    ),
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    autoHideDuration: 30000
                });
            }
        }
    }, [appParams?.displayModeDetection, enqueueSnackbar, closeSnackbar, t]);

    return null;
};

export default UsageChecker;