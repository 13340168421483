import { useContext, useEffect } from "react";
import { AppContext } from "../../../utils/contexts/appContext";
import { useTranslation } from "react-i18next";
import PageContainer from "../../../components/page-container";
import { List, ListItem, ListItemSecondaryAction, ListItemText, Switch } from "@material-ui/core";
import { useState } from "react";
import { SettingsRepository } from "../../../utils/dexie/repositories/settings-repository";
import { SettingsGroup } from "../../../utils/enums/settings-group";
import utils from "../../../utils";

const VisibilitySetting = ({ setting }) => {
    const [t] = useTranslation();
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setChecked(utils.toBoolean(setting.value));
    }, [setting.value])

    const handleChange = async (event) => {
        await SettingsRepository.updateSetting(setting.id, utils.toInteger(event.target.checked))
            .then(() => setChecked(prev => !prev));
    };

    return (
        <ListItem key={setting.id}>
            <ListItemText primary={t(setting.name)} />
            <ListItemSecondaryAction>
                <Switch onChange={handleChange} checked={checked} color="primary" />
            </ListItemSecondaryAction>
        </ListItem>
    );
};

const VisibilitySettings = () => {
    const [, dispatchApp] = useContext(AppContext);
    const [t] = useTranslation();
    const [displaySettings, setDisplaySettings] = useState([])

    useEffect(() => {
        async function init() {
            await SettingsRepository.getSettingsByGroup(SettingsGroup.DISPLAY_SETTINGS)
                .then(settings => setDisplaySettings(settings));
        };

        init();
        
        dispatchApp({ 
            type: 'changeHeader', 
            header: { 
                title: t('DisplaySettings'), 
                child: true 
            } 
        });
    }, [dispatchApp, t]);

    return (
        <PageContainer>
            <List>
                {
                    displaySettings.map(setting => <VisibilitySetting key={setting.id} setting={setting} />)
                }
            </List>
        </PageContainer>
    );
};

export default VisibilitySettings;