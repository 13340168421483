import { useCallback } from "react";
import { useEffect } from "react";
import { useRef } from "react";

/* Based on the famous hook: https://usehooks-ts.com/react-hook/use-is-mounted */
export function useIsMounted() {
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    return useCallback(() => isMounted.current, []);
}