import { Link } from "@material-ui/core";
import ReactDOMServer from 'react-dom/server';
import { RemarkFieldType } from "../../components/remarks";
import { buildGroupedRemarks, buildRemarkGroupsDictionary, lookUpLabel, lookUpValue } from "../../components/remarks/utils";
import { Alert, AlertTitle } from "@material-ui/lab";
import { ConditionalRemarkGroupWrapper } from "../../components/remarks/Remarks";

const convertToLocalRoutePlanItem = (apiRoutePlanItem, includePayload = false) => {
    if (apiRoutePlanItem == null)
       return null;
 
    let result = {
        hashCode: apiRoutePlanItem.hashCode,
        type: apiRoutePlanItem.type,
        planIndex: apiRoutePlanItem.planIndex,
        executionIndex: apiRoutePlanItem.executionIndex,
        payloadId: apiRoutePlanItem.payloadId,
    };

    if (includePayload)
        result.payload = apiRoutePlanItem.payload;

    return result;
 }

export const convertToLocalRoutePlan = (apiRoutePlan, includePayload = false) => {
    if (apiRoutePlan == null)
       return null;
 
    return {
       id: apiRoutePlan.route.id,
       route: apiRoutePlan.route,
       items: apiRoutePlan.items.map(item => convertToLocalRoutePlanItem(item, includePayload))
    };
 }

 export const convertToLocalLocation = (apiLocation) => {
    if (apiLocation == null)
       return null;
 
    return {
       id: apiLocation.id,
       lon: apiLocation.lon,
       lat: apiLocation.lat,
       name: apiLocation.name,
       address: apiLocation.address,
       remark: apiLocation.remark
    };
 }

 export const convertToLocalRoute = (apiRoute) => {
    if (apiRoute == null)
        return null;

    return {
        id: apiRoute.id,
        name: apiRoute.name,
        index: apiRoute.index,
        status: apiRoute.status,
        date: apiRoute.date,
        startLocation: apiRoute.startLocation.id,
        start: apiRoute.start,
        startString: apiRoute.startString,
        stopLocation: apiRoute.stopLocation.id,
        stop: apiRoute.stop,
        stopString: apiRoute.stopString,
        distance: apiRoute.distance,
        realStart: apiRoute.realStart,
        realStop: apiRoute.realStop,
        lastChangedByPlanner: apiRoute.lastChangedByPlanner,
        dispatchedAt: apiRoute.dispatchedAt,
        lastSynchronized: apiRoute.lastSynchronized,
        dropsOpen: apiRoute.dropsOpen,
        dropsTotal: apiRoute.dropsTotal,
        delay: apiRoute.delay,
        vehicle: apiRoute.vehicle
    };
 };

 export const convertToLocalDropType = (apiDropType) => {
    if (apiDropType == null)
        return null;

    return {
        id: apiDropType.dropTypeId,
        description: apiDropType.description,
        translatedTerm: apiDropType.translatedTerm,
        translations: apiDropType.translations,
        foregroundColor: apiDropType.foregroundColor,
        backgroundColor: apiDropType.backgroundColor
    };
 };

 export const convertToLocalArticle = (article) => ({
    id: article.articleId,
    name: article.name,
    inActive: article.inactive,
    unitDescription: article.unitDescription,
    brandCode: article.brandCode,
    brandDescription: article.brandDescription,
    supplierArticleCode: article.supplierArticleCode,
    isReturnable: article.isReturnable,
    defaultOutQuantity: article.defaultOutQuantity,
    defaultInQuantity: article.defaultInQuantity,
    displayIn: article.displayIn,
    displayOut: article.displayOut,
    seqNr: article.seqNr,
    aliasArticleId: article.aliasArticleId,
    descriptionTranslations: article.descriptionTranslations,
    remarkTranslations: article.remarkTranslations
 });

 export const convertReturnableToLocalData = (dropId, returnable) => {
    if (returnable == null)
        return null;
    
    return {
        dropId,
        returnableId: returnable.articleId,
        deliveredQuantityOut: returnable.defaultOutQuantity,
        deliveredQuantityIn: returnable.defaultInQuantity
    };
 };

 export const convertDeliveryOrderDetailToDropDetail = (dropId, deliveryOrderDetail) => {
    if (deliveryOrderDetail == null)
        return null;

    let data = {
        deliveryOrderDetailId: deliveryOrderDetail.deliveryOrderDetailId,
        simulationDeliveryDetailId: null,
        dropId: dropId,
        articleId: deliveryOrderDetail.article.articleId,
        partnerId: deliveryOrderDetail.partnerId,
        deliveryOrderId: deliveryOrderDetail.deliveryOrderId
    };

    return {
        ...data,
        idHash: [data.deliveryOrderDetailId, data.simulationDeliveryDetailId].join(',')
    };
 };

 export const convertSimulationDeliveryDetailToDropDetail = (dropId, simulationDeliveryDetail) => {
    if (simulationDeliveryDetail == null)
        return null;

    let data = {
        deliveryOrderDetailId: null,
        simulationDeliveryDetailId: simulationDeliveryDetail.simulationDeliveryDetailId,
        dropId: dropId,
        articleId: simulationDeliveryDetail.article.articleId,
        partnerId: simulationDeliveryDetail.partnerId
    };

    return {
        ...data,
        idHash: [data.deliveryOrderDetailId, data.simulationDeliveryDetailId].join(',')
    };
 };

 export const convertDeliveryOrderDetailToLocalData = (dropId, deliveryOrderDetail) => {
    if (deliveryOrderDetail == null)
        return null;

    // Remark: 
    // Put the OriginalQuantity values inside the local data because there doesn't exists dropdetails for articles which are selected locally from the catalog.
    // In that case the values are copied from the selected article inside the local data.
    // So for a universal approach, we put them also in the local data for dropdetails which are created server-side.
    return {
        deliveryOrderDetailId: deliveryOrderDetail.deliveryOrderDetailId,
        simulationDeliveryDetailId: null,
        dropId: dropId,
        articleId: deliveryOrderDetail.article.articleId,
        originalQuantityOut: deliveryOrderDetail.originalQuantityOut,
        deliveredQuantityOut: deliveryOrderDetail.originalQuantityOut,
        originalQuantityIn: deliveryOrderDetail.originalQuantityIn,
        deliveredQuantityIn: deliveryOrderDetail.originalQuantityIn
    };
 }

 export const convertSimulationDeliveryDetailToLocalData = (dropId, simulationDeliveryDetail) => {
    if (simulationDeliveryDetail == null)
        return null;

    // Remark:
    // Put the OriginalQuantity values inside the local data because there doesn't exists dropdetails for articles which are selected locally from the catalog.
    // In that case the values are copied from the selected article inside the local data.
    // So for a universal approach, we put them also in the local data for dropdetails which are created server-side.
    return {
        deliveryOrderDetailId: null,
        simulationDeliveryDetailId: simulationDeliveryDetail.simulationDeliveryDetailId,
        dropId: dropId,
        articleId: simulationDeliveryDetail.article.articleId,
        originalQuantityOut: simulationDeliveryDetail.originalQuantityOut,
        deliveredQuantityOut: simulationDeliveryDetail.originalQuantityOut,
        originalQuantityIn: simulationDeliveryDetail.originalQuantityIn,
        deliveredQuantityIn: simulationDeliveryDetail.originalQuantityIn
    };
 };

 export const convertToLocalDrop = (drop, routeId, locationId) => {
    if (drop == null)
        return null;

    // Concatenate the fixed remarks
    let fixedRemarkGroupsContents;
    if (drop.remarks != null && drop.remarks.length > 0) {
        let groupDictionary = buildRemarkGroupsDictionary(drop.remarks);
        let groupedRemarks = buildGroupedRemarks(drop.remarks);

        fixedRemarkGroupsContents = groupedRemarks.map(g => {
            let groupId = g.groupId;
            let remarkGroup = groupDictionary[groupId];
            let remarkIdsInGroup = g.remarks;
            let isTopLevelGroup = groupId == null || isNaN(parseInt(groupId));
            let remarkGroupContent = '';
            
            drop.remarks
                .filter(r => remarkIdsInGroup.indexOf(r.remarkId) >= 0)
                .forEach(r => {
                    let remarkValue = lookUpValue(r) ?? "";
                    if (r.highlighted) {
                        let highlightedTitle = lookUpLabel(r);
                        let highlightedContent = null;

                        switch(r.fieldType) {
                            case RemarkFieldType.CHECKBOX:
                                //
                                // General notes:
                                // For checkboxes, we need to use the default input-element because of an error in the rendering of the static markup 
                                // due to the implementation of the MUI-checkbox component (usage of useLayoutEffect) which throws a lot of errors in the console.
                                //
                                const checkBoxLabel = highlightedTitle;
                                highlightedTitle = null;
                                highlightedContent = 
                                <>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {checkBoxLabel != null ? `${checkBoxLabel}: ` : ''}
                                        <input type="checkbox" disabled={true} defaultChecked={!!JSON.parse(String(remarkValue).toLowerCase())} />
                                    </div>
                                </>;
                                break;
                            case RemarkFieldType.EMAIL:
                                let highlightedEmail = remarkValue;
                                highlightedContent = <Link href={`mailto:${highlightedEmail}`}>{highlightedEmail}</Link>;
                                break;
                            case RemarkFieldType.PHONENUMBER:
                                let highlightedPhoneNumber = remarkValue;
                                highlightedContent = <Link href={`tel:${highlightedPhoneNumber}`}>{highlightedPhoneNumber}</Link>
                                break;
                            case RemarkFieldType.URL:
                                let highlightedRemarkUrl = remarkValue != null && remarkValue.replace(/\s/g, '').length > 0 && !remarkValue.startsWith('http') ? `http://${remarkValue}` : remarkValue;
                                highlightedContent = <Link href={highlightedRemarkUrl} target="_blank" rel="noreferrer">{remarkValue}</Link>;
                                break;
                            default:
                                highlightedTitle = lookUpLabel(r);
                                highlightedContent = remarkValue;
                                break;
                        }

                        if (highlightedTitle != null || (highlightedContent != null && highlightedContent !== "")) {
                            remarkGroupContent += ReactDOMServer.renderToStaticMarkup(
                                <Alert severity="info" icon={false} style={{ marginBottom: 5 }}>
                                { highlightedTitle != null ? <AlertTitle style={{ fontWeight: 600 }} color="primary">{lookUpLabel(r)}</AlertTitle> : <></> }
                                { highlightedContent != null ? highlightedContent : <></> }
                                </Alert>
                            );
                        }
                    } else {
                        let remarkLabel = lookUpLabel(r);
                        let remarkContent = null;

                        switch(r.fieldType) {
                            case RemarkFieldType.CHECKBOX:
                                const checkBoxLabel = remarkLabel;
                                remarkLabel = null;
                                remarkContent = 
                                <>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {checkBoxLabel != null ? `${checkBoxLabel}: ` : ''}
                                        <input type="checkbox" disabled={true} defaultChecked={!!JSON.parse(String(remarkValue).toLowerCase())} />
                                        <br />
                                    </div>
                                </>;
                                break;
                            case RemarkFieldType.EMAIL:
                                let email = remarkValue;
                                remarkContent = <Link href={`mailto:${email}`}>{email}</Link>;
                                break;
                            case RemarkFieldType.PHONENUMBER:
                                let phoneNumber = remarkValue;
                                remarkContent = <Link href={`tel:${phoneNumber}`}>{phoneNumber}</Link>;
                                break;
                            case RemarkFieldType.URL:
                                let remarkUrl = remarkValue != null && remarkValue.replace(/\s/g, '').length > 0 && !remarkValue.startsWith('http') ? `http://${remarkValue}` : remarkValue;
                                remarkContent = <Link href={remarkUrl} target="_blank">{remarkValue}</Link>;
                                break;
                            default:
                                remarkLabel = lookUpLabel(r);
                                remarkContent = remarkValue;
                                break;
                        }

                        if (remarkLabel != null || (remarkContent != null && remarkContent !== "")) {
                            remarkGroupContent += ReactDOMServer.renderToStaticMarkup(
                                <div style={{marginBottom: 5}}>
                                { remarkLabel != null ? `${remarkLabel}: ` : '' }
                                { remarkContent ?? '' }
                                </div>
                            );
                        }
                    }
                });

                return remarkGroupContent !== '' ? 
                ReactDOMServer.renderToStaticMarkup(
                    <ConditionalRemarkGroupWrapper isTopLevelGroup={isTopLevelGroup} group={remarkGroup} 
                        key={isTopLevelGroup ? 'top-level-remarks' : `remark-group-${groupId}`} isStaticRendering={true}>
                            <div dangerouslySetInnerHTML={{ __html: remarkGroupContent }}></div>
                    </ConditionalRemarkGroupWrapper>
                ) : null;
        });
    }

    return {
        id: drop.id,
        routeId: routeId,
        index: drop.index,
        realIndex: drop.realIndex,
        eta: drop.eta,
        etaString: drop.etaString,
        etfString: drop.etfString,
        realETA: drop.realETA,
        realETAString: drop.realETAString,
        location: locationId,
        remark: fixedRemarkGroupsContents?.filter(c => c != null)?.join('') ?? null,
        tw: drop.tw,
        signonglass: drop.signOnGlass,
        dropType: drop.dropType?.dropTypeId,
        projectId: drop.projectId,
        projectPhaseId: drop.projectPhaseId,
        projectType: drop.projectType,
        mainProjectId: drop.mainProjectId,
        email: drop.email,
        mobilePhone: drop.mobilePhone,
        colleagueSchedules: drop.colleagueSchedules,
        jobInstruction: drop?.deliveryOrder?.jobInstruction
    };
 };

export const convertDropToLocalData = (drop) => {
    if (drop == null)
        return null;

    return {
        dropId: drop.id,
        status: drop.status,
        externalStatusId: drop.externalStatusId,
        signature: null,
        lat: 0,
        lon: 0
     };
};

export const convertRemarkToLocalRemark = (remark) => {
    if (remark == null)
        return null;

    return {
        remarkId: remark.remarkId,
        dropId: remark.dropId,
        seqNr: remark.seqNr,
        fieldType: remark.fieldType,
        enabled: remark.enabled,
        required: remark.required,
        label: remark.label,
        remark: remark.remark,
        configuration: remark.configuration,
        remarkGroup: remark.remarkGroup,
        translations: remark.translations?.map(t => convertToLocalTranslation(t))
    };
}

export const convertToLocalTranslation = (dtoTranslation) => {
    if (dtoTranslation == null)
        return null;

    return {
        id: dtoTranslation.id,
        langId: dtoTranslation.langId,
        language: convertToLocalLanguage(dtoTranslation.language),
        translatedTerm: dtoTranslation.translatedTerm,
        table: dtoTranslation.table,
        recordId: dtoTranslation.recordId
    };
}

export const convertToLocalLanguage = (dtoLanguage) => {
    if (dtoLanguage == null)
        return null;

    return {
        languageId: dtoLanguage.languageId,
        abbreviation: dtoLanguage.abbreviation
    };
}

export const convertToLocalArticleInventoryLevel = (articleInventoryLevel) => {
    if (articleInventoryLevel == null)
        return null;

    return {
        articleInventoryLevelId: articleInventoryLevel.articleInventoryLevelId,
        articleId: articleInventoryLevel.articleId,
        available: articleInventoryLevel.available
    };
};

export const convertToLocalBufferSlot = (bufferSlot, routeId, locationId) => {
    if (bufferSlot == null)
        return null;

    return {
        id: bufferSlot.id,
        routeId: routeId,
        locationId: locationId,
        title: bufferSlot.title,
        description: bufferSlot.description,
        startTime: bufferSlot.startTime,
        endTime: bufferSlot.endTime
    };
};

export const convertPlanningItemToLocalData = (routePlanId, apiRoutePlanItem) => {
    if (apiRoutePlanItem == null)
        return null;

    return {
        routePlanId: routePlanId,
        type: apiRoutePlanItem.type,
        payloadId: apiRoutePlanItem.payloadId,
        status: apiRoutePlanItem.planStatus
    };
};

export const convertToLocalUnproductiveTime = (apiUnproductiveTime, routeId) => {
    if (apiUnproductiveTime == null)
        return null;

    return {
        id: apiUnproductiveTime.id,
        routeId: routeId,
        startTime: apiUnproductiveTime.startTime,
        endTime: apiUnproductiveTime.endTime
    };
};