import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, CardContent, makeStyles, Paper, Typography } from '@material-ui/core';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DropType from '../../../components/dropType/DropType';
import RemarksOverview from '../../../components/remarksOverview/RemarksOverview';
import { FontAwesomeDuoToneStyle } from '../../../fontawesome';
import db from '../../../utils/dexie/driverappdb';
import ArticlesManager from '../job/articlesManager';
import ReturnablesManager from '../job/returnablesManager';
import { ExternalStatus } from '../../../components/external-status';

const useStyle = makeStyles({
    root: {
        marginTop: 15,
        marginBottom: 15
    },
    section: {
        marginTop: 15
    },
    itemWithIcon: {
        display: 'flex',
        alignSelf: 'center'
    },
    itemIcon: {
        paddingRight: '15px !important'
    },
    itemContent: {
        flexGrow: 1
    },
    articleName: {
        fontWeight: 'bold'
    },
    badges: {
        marginTop: 15,
        marginBottom: 15,
        display: 'inline-flex',
        gap: '.5rem'
    }
});

const DropLocationOverview = ({ location }) => {
    const classes = useStyle();
    const [t,] = useTranslation();

    return (
        location != null ? 
            <Box className={classes.section}>
                <Box className={classes.itemWithIcon}>
                    <Box className={classes.itemIcon}>
                        <FontAwesomeIcon icon={["fad", "home"]} size="lg" style={FontAwesomeDuoToneStyle} />
                    </Box>
                    <Box className={classes.itemContent}>
                        <Typography>{t("Address")}:</Typography>
                        <Typography>{location.address}</Typography>
                    </Box>
                </Box>
            </Box> :
            <></>
    )
}
 
const DropOverview = ({ drop }) => {
    const classes = useStyle();
    const [t,] = useTranslation();
    const [showArticles, setShowArticles] = useState(true);
    const [showReturnables, setShowReturnables] = useState(true);

    useEffect(() => {
        db.transaction('rw', db.dropdetailsdata2, db.returnablesdata, async () => {
            setShowArticles(await db.dropdetailsdata2.where({ dropId: drop.id } ).count() > 0);
            setShowReturnables(await db.returnablesdata.where({ dropId: drop.id }).count() > 0);
        });
    }, [drop.id]);

    return (
        <>
            {
                drop != null ?
                    <Paper elevation={3} className={classes.root}>
                        <CardContent>
                            { drop.location != null ? <Typography variant="h6">{drop.index}) {drop.location.name}</Typography> : <></> }
                            <DropLocationOverview location={drop.location} />
                            <Box className={classes.section}>
                                <Box className={classes.itemWithIcon}>
                                    <Box className={classes.itemIcon}>
                                        <FontAwesomeIcon icon={["fad", "clock"]} size="lg" style={FontAwesomeDuoToneStyle} />
                                    </Box>
                                    <Box>
                                        <Box>
                                            <Typography>{t("TimeWindow")}:</Typography>
                                            <Typography>{drop.tw || t("NoTimewindowAvailable")}</Typography>
                                        </Box>
                                        <Box className={classes.section}>
                                            <Typography>{t("ETA")} - {t("ETF")}:</Typography>
                                            <Typography>{drop.etaString} - {drop.etfString}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            {
                                (drop.remark != null && typeof(drop.remark) != undefined && drop.remark.trim !== "") ?
                                    <Box className={classes.section}>
                                        <Box className={classes.itemWithIcon}>
                                            <Box className={classes.itemIcon}>
                                                <FontAwesomeIcon icon={["fad", "info-circle"]} size="lg" style={FontAwesomeDuoToneStyle} />
                                            </Box>
                                            <Box dangerouslySetInnerHTML={{ __html: drop.remark }} />
                                        </Box>
                                    </Box> :
                                    <></>
                            }
                            <Box className={classes.badges}>
                                {drop.dropType != null ? <DropType dropType={drop.dropType}/> : <></>}
                                <ExternalStatus drop={drop} readOnly={true} />
                            </Box>
                            <RemarksOverview remarks={drop.driverremarks} enabledOnly={true} nonEmptyOnly={true} />
                            {
                                showArticles ?
                                    <Box className={classes.section}>
                                        <Typography gutterBottom variant="subtitle2" color="textSecondary" className={classes.section}>
                                            {t("Articles").toUpperCase()}
                                        </Typography>
                                        <ArticlesManager readonly dropId={drop.id} />
                                    </Box> : <></>
                            }
                            {
                                showReturnables ?
                                    <Box className={classes.section}>
                                        <Typography gutterBottom variant="subtitle2" color="textSecondary" className={classes.section}>
                                            {t("Returnables").toUpperCase()}
                                        </Typography>
                                        <ReturnablesManager readonly dropId={drop.id} />
                                    </Box> : <></>
                            }
                        </CardContent>
                    </Paper> :
                    <></>
            }
        </>
    );

}

const StartStopLocation = ({ icon, location, children }) => {
    const classes = useStyle();

    return (
        <Paper elevation={3} className={classes.root}>
            <CardContent>
                <Box className={classes.section}>
                    <Box className={classes.itemWithIcon}>
                        <Box className={classes.itemIcon}>{icon}</Box>
                        <Box>
                            <Typography variant="h6">{location.name}</Typography>
                            <Typography variant="body1">{location.address}</Typography>
                            {children}
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Paper>
    )
};

const StartLocation = ({ route }) => {
    const classes = useStyle();
    const [t,] = useTranslation();

    return (
        <StartStopLocation location={route.startLocation} 
            icon={<FontAwesomeIcon icon={["fad", "house-leave"]} size="lg" style={FontAwesomeDuoToneStyle} />}>
            <Box className={classes.section}>{t("ETF")}: {route.startString}</Box>
        </StartStopLocation>
    );
};

const StopLocation = ({ route }) => {
    const classes = useStyle();
    const [t,] = useTranslation();

    return (
        <StartStopLocation location={route.stopLocation} 
            icon={<FontAwesomeIcon icon={["fad", "house-return"]} size="lg" style={FontAwesomeDuoToneStyle} />}>
            <Box className={classes.section}>{t("ETA")}: {route.stopString}</Box>
        </StartStopLocation>
    );
};

const RouteOverviewView = ({ route, drops, appParams }) => {

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <StartLocation route={route} />
            { drops != null ? drops.map((drop, i) => <DropOverview key={i} drop={drop} />) : <></> }
            <StopLocation route={route} />
        </motion.div>
    );

};

export default RouteOverviewView;