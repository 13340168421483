/**
 * This optional code is used to register a service worker.
 * 
 * REMARK: register() is not called by default.
 * 
 * This lets the app load faster on subsequent visits in the specified environments,
 * and gives the app offline capabilities. However, it also means that developers 
 * (and users) will only see deployed updates, on subsequent visits to a page, after 
 * all the existing open tabs on the page have been closed. Since previously cached 
 * resources are upated in the background.
 * 
 * To learn more about the benefits of this model and to get instructions on how to 
 * opt-in, read https://bit.ly/CRA-PWA
 */

const isLocalhost = Boolean(
   window.location.hostname === 'localhost' ||
   // [::1] is the IPv6 localhost address.
   window.location.hostname === '[::1]' ||
   // 127.0.0.1/8 is considered localhost for IPv4.
   window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
   )
);

/**
 * Unregister the service worker.
 */
export const unregister = () => {
   if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
         .then(registration => registration.unregister());
   }
};

/**
 * Registers each of the given service workers when running in the specified environments.
 * @param {*} param0 
 */
export const register = ({
   environments = ['production'],
   swFiles = ['sw.js'],
   config = {
      onUpdate: (registration) => {},
      onSuccess: (registration) => {}
   }
} = {}) => {

   // Checks if this is executing in an environment where we need to register the service worker 
   // and also checks if the browser supports service workers.
   if (environments.includes(process.env.NODE_ENV) && 'serviceWorker' in navigator) {

      // The URL constructor is available in all browsers that support service workers.
      const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);

      // Our service worker won't work if PUBLIC_URL is on a different origin 
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      if (publicUrl.origin !== window.location.origin)
         return;

      // Deferring the registration of the service worker until the initial page has been loaded,
      // to ensure that the service worker's initial installation doesn't degrade a user's first-visit experience.
      window.addEventListener('load', () => {

         swFiles.forEach((fileName) => {

            // Add a custom service worker until CRA team provides a way to inject config into workbox.
            const swUrl = `${process.env.PUBLIC_URL}/${fileName}`;

            if (isLocalhost) {
               // This is running on localhost.
               // So let's check if a service worker still exists or not.
               registerServiceWorkerIfValid(swUrl, config);

               // Add some additional logging to localhost, pointing developers to the service worker/PWA documentation.
               navigator.serviceWorker.ready
                  .then(() => console.log('This web app is being served cache-first by a service worker. To learn more, visit https://bit.ly/CRA-PWA'));
            } else {
               // This is not running on localhost.
               // So just register the service worker.
               registerServiceWorker(swUrl, config);
            }

         });

      });

   }

};

/**
 * Only registers the service worker if it's valid.
 * @param {*} swUrl 
 * @param {*} config 
 */
const registerServiceWorkerIfValid = (swUrl, config) => {
   
   // Check if the service worker can be found. If not, reload the page.
   fetch(swUrl)
      .then(response => {
         // Ensure the service worker exists and that we really are getting a JS file.
         const contentType = response.headers.get('content-type');

         if (response.status === 404 || (contentType != null && contentType.indexOf('javascript') === -1)) {
            // No service worker found. Probably a different app. Reload the page.
            navigator.serviceWorker.ready
               .then(registration => {
                  registration.unregister()
                     .then(() => window.location.reload());
               });
         } else {
            // Service worker found. Proceed as normal.
            registerServiceWorker(swUrl, config);
         }
      })
      .catch(() => console.log('No internet connection found. App is running in offline mode.'));

}

/**
 * Executes the actual registration of the service worker.
 * @param {*} swUrl 
 * @param {*} config 
 */
const registerServiceWorker = (swUrl, config) => {

   navigator.serviceWorker
      .register(swUrl, {
         updateViaCache: 'none'
      })
      .then(registration => {
         let installingWorker;

         if (registration.waiting) {
            // A new version is already waiting to take control
            installingWorker = registration.waiting;
         }

         registration.onupdatefound = () => {
            installingWorker = registration.installing;
            if (installingWorker == null)
               return;
            
            installingWorker.onstatechange = () => {
               if (installingWorker.state === 'installed') {
                  if (navigator.serviceWorker.controller) {

                     // At this point, the updated precached content has been fetched,
                     // but the previous service worker will still serve the older
                     // content until all client tabs are closed.
                     console.log(
                        'New content is available and will be used when all ' +
                          'tabs for this page are closed. See https://bit.ly/CRA-PWA.x')

                     // Execute callback
                     if (config && config.onUpdate) {
                        config.onUpdate(registration);
                     }

                  } else {
                     // At this point, everything has been precached.
                     // It's the perfect time to display a
                     // "Content is cached for offline use." message.
                     console.log('Content is cached for offline use.');

                     // Execute callback
                     if (config && config.onSuccess) {
                        config.onSuccess(registration);
                     }
                  }
               }
            };
         };

      })
      .catch(error => console.error('Error during service worker registration:', error));

};