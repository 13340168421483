import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PlanningItemBody from "../planning-item-body";
import PlanningTimelineItem from "../planning-timeline-item"
import PlanningItemContent from "../planning-item-content";
import { Box, Typography, useTheme } from "@material-ui/core";
import PlanningItemHeader from "../planning-item-header";
import { FontAwesomeDuoToneStyle } from "../../../../../fontawesome";
import PlanningItemHeaderBadge from "../planning-item-header-badge";
import { DateUtils } from "../../../../../utils/date-utils";
import PlanningItemActions from "../planning-item-actions";
import NavigateButton from "../action-buttons/navigate-button";
import { useContext } from "react";
import { RoutePlanningContext } from "../../../../../utils/contexts/route-planning-context";
import UpdateStatusButton from "../action-buttons/update-status-button";
import { useState } from "react";
import { PlanningItemStatus } from "../../../../../utils/enums/planning-item-status";
import { useBufferSlotStatusManagement } from "../../hooks/bufferslot-status-management";
import { useRouteAutoStart } from "../../../../../hooks/route-auto-start";
import { useEffect } from "react";
import PlanningItemDelay from "../planning-item-delay";
import { useTranslation } from "react-i18next";
import { useSinglePress } from "../../../../../hooks/single-press";

const BufferSlotItem = ({ 
    planningItem, 
    readOnly = true
}) => {
    const theme = useTheme();
    const [t,] = useTranslation();
    const { status: planningItemStatus, payload: bufferSlot, hashCode } = planningItem;
    const updateBufferSlotStatus = useBufferSlotStatusManagement(bufferSlot.id, bufferSlot.routeId, hashCode);
    const [routeAutoStartEnabled, autoStartRoute] = useRouteAutoStart(planningItem);

    const { navigationApp } = useContext(RoutePlanningContext);
    const [executing, setExecuting] = useState(false);

    useEffect(() => {
        setExecuting(planningItemStatus === PlanningItemStatus.IN_PROGRESS);
    }, [planningItemStatus]);

    const onClickStart = useSinglePress(async () => {
        if (routeAutoStartEnabled)
            await autoStartRoute();

        await updateBufferSlotStatus(PlanningItemStatus.IN_PROGRESS);
    });

    const onClickStop = useSinglePress(async () => {
        await updateBufferSlotStatus(PlanningItemStatus.FINISHED);
    });

    const getTimelineItemProps = () => {
        const alternativeOpacity = .5;

        let icon = <FontAwesomeIcon icon={["fab", "buffer"]} />;
        let iconColor = theme.palette.bufferSlot.main;
        let iconOpacity;

        switch (planningItemStatus) {
            case PlanningItemStatus.FINISHED:
                iconOpacity = alternativeOpacity;
                break;
            default:
                break;
        }

        return { icon, iconColor, iconOpacity };
    }

    return (
        <PlanningTimelineItem {...getTimelineItemProps()}>
            <PlanningItemHeader>
                <PlanningItemHeaderBadge>
                    <Typography component="span" style={{paddingLeft: 0}}>{planningItem.executionIndex}</Typography>
                </PlanningItemHeaderBadge>
                <PlanningItemHeaderBadge>
                    <FontAwesomeIcon icon={["fad", "clock"]} style={FontAwesomeDuoToneStyle} />
                    <Typography component="span">
                        {DateUtils.getFormattedTimeString(new Date(bufferSlot.startTime))} - {DateUtils.getFormattedTimeString(new Date(bufferSlot.endTime))}
                        <PlanningItemDelay />
                    </Typography>
                </PlanningItemHeaderBadge>
            </PlanningItemHeader>
            <PlanningItemBody highlighted={planningItem.status === PlanningItemStatus.IN_PROGRESS}>
                <PlanningItemContent translucent={planningItem.status === PlanningItemStatus.FINISHED}>
                    <Typography variant="h6">{bufferSlot.title ?? (bufferSlot.description == null || bufferSlot.description.trim() === '' ? t('BufferSlot') : '')}</Typography>
                    {bufferSlot.description != null && bufferSlot.description.trim() !== '' ? <Typography variant="body1">{bufferSlot.description}</Typography> : null}
                    {
                        bufferSlot.locationId != null ? 
                            <Box mt={5}>
                                <Typography variant="body1">{bufferSlot.location.address}</Typography>
                            </Box> 
                            : null
                    }
                </PlanningItemContent>
                <PlanningItemActions>
                    {bufferSlot.locationId != null ? <NavigateButton location={bufferSlot.location} navigationApp={navigationApp} /> : null}
                    <UpdateStatusButton icon={<FontAwesomeIcon size="lg" icon={["fad", executing ? "stop-circle" : "play-circle"]} />} 
                        disabled={readOnly} onClick={executing ? onClickStop : onClickStart} />
                </PlanningItemActions>
            </PlanningItemBody>
        </PlanningTimelineItem>
    );
};

export default BufferSlotItem;