import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import { ListItem, ListItemText, ListItemAvatar, Stepper, Step, StepLabel, List, Card, CardContent, Typography, Button, Box, Fab, Chip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import FilterButton from '../../components/filterButton';
import { useContext } from 'react';
import { AuthContext } from '../../providers/AuthProvider';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ProjectList = ({ projects, showFloatingActionButton, onSelected = (projectId) => {} }) => {
    const [t, ] = useTranslation();

    return (
        <>
            <List style={{ paddingBottom: 70 }}>
                {
                    projects.map(project => 
                        <ListItem key={project.projectId} onClick={event => onSelected(project)}>
                            <ListItemAvatar>
                                <FontAwesomeIcon icon={["fas", "caret-right"]} size="2x" style={{ color: '#3498db' }} />
                            </ListItemAvatar>
                            <ListItemText primary={project.customerName} secondary={
                                <>
                                    <span style={{ display: "block" }}>
                                        <Typography
                                            component="span"
                                            variant="subtitle2">
                                            {project.address}
                                        </Typography>
                                        {" — "} {project.description}
                                    </span>
                                    <Chip style={{ marginTop: 10 }} label={t("ElevatorNumber") + ": " + project.elevatorNumber} size="small" color="primary" variant="outlined" component="span" />
                                </>
                            }>
                            </ListItemText>
                        </ListItem>
                    )
                }
            </List>
            {
                showFloatingActionButton &&
                <Fab color="primary" style={{ position: 'fixed', bottom: 15, right: 15 }} onClick={() => window.scrollTo(0, 0)}>
                    <FontAwesomeIcon icon={["fas", "arrow-up"]} size="lg" />
                </Fab>
            }
        </>
    );

}

const ProjectConfirmation = ({ project, onConfirm, onCancel = () => {} }) => {
    const [t,] = useTranslation();

    return (
        <div>
            <Typography variant="body1" style={{ marginTop: 20 }}>
                {t("RequestTaskFromModernisationProjectConfirmation")}
            </Typography>
            <Card style={{marginTop: 20, marginBottom: 20}}>
                <CardContent>
                    <Typography variant="overline" color="textSecondary" gutterBottom>
                        {t("ModernisationProject").toUpperCase()}
                    </Typography>
                    <Typography gutterBottom variant="h5">
                        {project.customerName}
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        {project.description}
                    </Typography>
                </CardContent>
            </Card>
            <div style={{ textAlign: 'right' }}>
                <Button variant="contained" onClick={onCancel}>{t("Back")}</Button>
                <Button variant="contained" color="primary" onClick={onConfirm} style={{ marginLeft: 10 }}>{t("Confirm")}</Button>
            </div>
        </div>
    )
}

const Actions = ({
    onFilterChange = (filter) => {}
}) => {

    return (
        <div style={{ backgroundColor: "#FFF" }}>
            <div className="align-self-center" style={{ textAlign: 'right', paddingRight: 15, paddingBottom: 5 }}>
                <FilterButton onChange={onFilterChange} />
            </div>
        </div>
    );
}

const ModernisationsView = ({ projects }) => {
    const auth = useContext(AuthContext);
    const [t,] = useTranslation();
    const history = useHistory();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [activeStep, setActiveStep] = useState(0);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState();
    const [showFloatingActionButton, setShowFloatingActionButton] = useState(false);
    const steps = [ t("SelectModernisationProject"), t("ConfirmRequest") ];

    useEffect(() => {
        setFilteredProjects(projects);
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [projects]);

    const nextStep = () => {
        setActiveStep(currentStep => currentStep + 1);
        window.scrollTo(0, 0);
    }

    const previousStep = () => {
        setActiveStep(currentStep => currentStep - 1);
        window.scrollTo(0, 0);
    }

    const getStepContent = (stepIndex) => {
        switch(stepIndex) {
            case 0:
                return (
                    <Box>
                        <ProjectList projects={filteredProjects} onSelected={onProjectSelected} showFloatingActionButton={showFloatingActionButton}/>
                    </Box>
                );
            default:
                return <></>;
        }
    }

    const filterProjects = (filter) => {
        setFilteredProjects(
            projects.filter(project => {
                if (filter.text === '')
                    return true;
                let projectFilter = filter.text.toLowerCase();
                return project.customerName.toLowerCase().includes(projectFilter) || project.description.toLowerCase().includes(projectFilter) || 
                    project.address.toLowerCase().includes(projectFilter) || project.elevatorNumber.includes(projectFilter);
            })
        );
    };

    const onProjectSelected = (project) => {
        setSelectedProject(project);
        nextStep();
    }

    const requestTask = () => {
        if (navigator.onLine) {
            auth.getUser()
                .then((user) => {
                    auth.fetch('post', `projects/add/${selectedProject.projectId}`)
                        .then((result) => {
                            if (result && result.status === 200) {
                                history.push('/routes');
                            }
                        });
                });
        } else {
            enqueueSnackbar(t('YouAreOffline'), {
                variant: 'error',
                action: (key) => handleCloseSnackbar(key),
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                },
                autoHideDuration: 3000
            });
        }
    }

    const onScroll = () => {
        setShowFloatingActionButton(window.scrollY > 0);
    }

    const isFixedHeader = () => activeStep === 0;

    const handleCloseSnackbar = (key) => {
        return <FontAwesomeIcon icon={["fal", "times"]} 
                    onClick={() => { closeSnackbar(key); }} style={{ marginRight: 20 }} />
    }

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <Box style={{ position: "relative" }}>
                <div style={{ 
                        position: isFixedHeader() ? "fixed" : "inherit", 
                        width: "100%", 
                        left: 0, 
                        zIndex: 1, 
                        backgroundColor: "#FFF", 
                        paddingTop: 16, 
                        paddingRight: isFixedHeader() ? 16 : 0, 
                        paddingBottom: 0, 
                        paddingLeft: isFixedHeader() ? 16 : 0,
                        boxShadow: isFixedHeader() && showFloatingActionButton ? "0 .125rem .25rem rgba(0,0,0,.075)" : ""
                    }}>
                    <Stepper activeStep={activeStep} alternativeLabel style={{ paddingBottom: 10 }}>
                        {
                            steps.map(label =>
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            )
                        }
                    </Stepper>
                    {
                        activeStep === 0 &&
                            <Actions onFilterChange={filterProjects} />
                    }
                </div>
                <Box>
                    <div style={{ paddingTop: isFixedHeader() ? 160 : 0 }}>
                        {
                            activeStep === steps.length - 1 ? 
                                <ProjectConfirmation project={selectedProject} onConfirm={requestTask} onCancel={previousStep} /> :
                                getStepContent(activeStep)
                        }
                    </div>
                </Box>
            </Box>
        </motion.div>
    );
}

export default ModernisationsView;