import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../utils/contexts/appContext';

function Home() {
    const [t] = useTranslation();
    const [,dispatch] = useAppContext();

    useEffect(() => {
        dispatch({ type: 'changeHeader', header: { title: t("Routes"), child: false, path: '/routes' } });
        window.location = '/routes';
    }, [dispatch, t]);
    
    return <div></div>;
}
 
export default Home;