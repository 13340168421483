import { Card, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    highlighted: {
        borderWidth: '3px',
        borderStyle: 'solid',
        borderImage: `linear-gradient(to bottom, ${theme.palette.highlighted.main}, rgba(0, 0, 0, 0)) 1 100%`
    }
}));

const PlanningItemBody = ({ children, highlighted, ...otherProps }) => {
    const classes = useStyles();

    return (
        <Card elevation={2} className={highlighted ? classes.highlighted : null} {...otherProps}>
            {children}
        </Card>
    )
};

export default PlanningItemBody;