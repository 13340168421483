import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../utils/contexts/appContext';
import db from '../../utils/dexie/driverappdb';
import utils from '../../utils';
import { FormGroup, Switch, FormControlLabel } from '@material-ui/core';
import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Settings.css';
import { useSnackbar } from 'notistack';
import { UserAgentContext } from '../../providers/UserAgentProvider';

const pageVariants = {
    in: {
        opacity: 1
    },
    out: {
        opacity: 0
    }
};

const Divider = () => (
    <div className="full-divider"></div>
)

const FinishedDeliveriesToggle = ({ id, value }) => {
    const [t] = useTranslation();
    const defaultState = utils.toBoolean(value);
    const [checked, setChecked] = useState(defaultState);

    const handleChange = (event) => {
        update(id, event.target.checked);
    }

    const update = (id, value) => {
        db.settings.update(id, { value: utils.toInteger(value) });
        setChecked(value);
    }

    return (
        <div className="settings-container">
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Switch value="showFinishedDeliveries" onChange={handleChange} checked={checked} color="primary" />
                    }
                    label={t("ShowFinishedDeliveries")}
                    labelPlacement="start"
                />
            </FormGroup>
        </div>
    );
}

const NavigationLink = ({ id, value }) => {
    const [t] = useTranslation();

    return (
        <Link to={`/settings/navigation/${id}/${value}`} className="settings-container flex-column">
            <div style={{ paddingBottom: "5px", paddingTop: "5px", height: "45px" }}>
                <div>{t("NavigationApp")}</div>
                <div className="settings-selected-value">{utils.capitalize(value)}</div>
            </div>
            <FontAwesomeIcon icon={["fas", "angle-right"]} />
        </Link>
    );
}

const SettingItem = (props) => {
    let id = props.setting.id;
    let name = props.setting.name;
    let value = props.setting.value;

    if (name === "NavigationApp")
        return (<NavigationLink id={id} value={value} />);

    if (name === "ShowFinishedDeliveries")
        return (<FinishedDeliveriesToggle id={id} value={value} />);

    return null;
}

function Settings() {
    const [t] = useTranslation();
    const [, dispatch] = useAppContext();
    const [settings, setSettings] = useState([]);
    const [, setAppParams] = useState([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const userAgent = useContext(UserAgentContext);

    useEffect(() => {
        dispatch({ type: 'changeHeader', header: { title: t("Settings"), child: false } });

        db.transaction('r', db.settings, db.params, async () => {
            await db.settings.toArray().then(data => setSettings(data.filter(setting => setting.group == null)));
            await db.params.where('id').notEqual(0).first().then(data => setAppParams(data));
        }).catch(error => {
            alert('Er is iets fout gelopen...');
        });
    }, [dispatch, t]);

    const copyUrlClearBrowserData = () => {
        const url = document.createElement('textarea');
        url.value = "chrome://history";
        document.body.appendChild(url);
        url.select();
        document.execCommand('copy');
        document.body.removeChild(url);
        
        enqueueSnackbar(t('ClearBrowserDataUrlCopiedToClipboard'), {
            variant: 'success',
            action: (key) => handleClose(key),
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right'
            },
            autoHideDuration: 5000
        });
        return false;
    }

    const handleClose = useCallback((key) => {
        return <FontAwesomeIcon icon={["fal", "times"]} 
                    onClick={() => { closeSnackbar(key); }} style={{ marginRight: 10 }} />;
    }, [closeSnackbar]);

    return (
        <motion.div initial="out" animate="in" exit="out" variants={pageVariants}>
            <div className="global-container">
                {settings.map((setting, i) =>
                    <div key={i}>
                        <SettingItem setting={setting} />
                        {i !== (settings.length - 1) ? (<Divider />) : (<></>)}
                    </div>
                )}
                <Divider />
                <div>
                    <Link to={`/settings/visibility`} className="settings-container flex-column">
                        <div style={{ paddingBottom: "5px", paddingTop: "5px", height: "45px" }}>
                            <div>{t("DisplaySettings")}</div>
                        </div>
                        <FontAwesomeIcon icon={["fas", "angle-right"]} />
                    </Link>
                </div>
                <Divider />
                <div>
                    <Link to={`/settings/permissions`} className="settings-container flex-column">
                        <div style={{ paddingBottom: "5px", paddingTop: "5px", height: "45px" }}>
                            <div>{t("AppPermissions")}</div>
                        </div>
                        <FontAwesomeIcon icon={["fas", "angle-right"]} />
                    </Link>
                </div>
                <Divider />
                <div>
                    <Link to={`/settings/logs`} className="settings-container flex-column">
                        <div style={{ paddingBottom: "5px", paddingTop: "5px", height: "45px" }}>
                            <div>{t("Logs")}</div>
                        </div>
                        <FontAwesomeIcon icon={["fas", "angle-right"]} />
                    </Link>
                </div>
                {
                    userAgent.isFeatureSupported('browser_data_clear') ?
                        <>
                            <Divider />
                            <div>
                                <Link to={'/settings'} onClick={copyUrlClearBrowserData} className="settings-container flex-column" replace>
                                    <div style={{ paddingBottom: "5px", paddingTop: "5px", height: "45px" }}>
                                        <div>{t("DeleteHistory")}</div>
                                    </div>
                                    <FontAwesomeIcon icon={["fas", "angle-right"]} />
                                </Link>
                            </div>
                        </> : <></>
                }
                <Divider />
                <div>
                    <Link to={'/settings/data-management'} className="settings-container flex-column">
                        <div style={{ paddingBottom: "5px", paddingTop: "5px", height: "45px" }}>
                            <div>{t("DataManagement")}</div>
                        </div>
                        <FontAwesomeIcon icon={["fas", "angle-right"]} />
                    </Link>
                </div>
                <Divider />
                <div>
                    <Link to={'/settings/system'} className="settings-container flex-column" replace>
                        <div style={{ paddingBottom: "5px", paddingTop: "5px", height: "45px" }}>
                            <div>{t("SystemInfo")}</div>
                        </div>
                        <FontAwesomeIcon icon={["fas", "angle-right"]} />
                    </Link>
                </div>
            </div>
        </motion.div>
    );
}

export default Settings;