export const DateUtils = {

    convertLocalToUTC: (date) => {
        return date.getTime() - date.getTimezoneOffset() * 60000;
    },

    getFormattedDateString: (date) => {
        return date?.toLocaleDateString(navigator.language, { day: "2-digit", month: "2-digit", year: "numeric" });
    },

    getFormattedTimeString: (date) => {
        let timeParts = [];

        timeParts.push(date.getHours());
        timeParts.push(date.getMinutes());

        return timeParts.map(part => part < 10 ? `0${part}` : `${part}`).join(':');
    },

    getFormattedDateTimeString: (date) => {
        return `${DateUtils.getFormattedDateString(date)} ${DateUtils.getFormattedTimeString(date)}`;
    }
    
};