import { Button } from '@material-ui/core';
import React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../providers/AuthProvider';
import db from '../../utils/dexie/driverappdb';
import { version } from '../../../package.json';
import utils from '../../utils';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';

const ExportLogsButton = forwardRef(({ label }, ref) => {
    const [t] = useTranslation();
    const auth = useContext(AuthContext);

    const exportLogsToFile = async () => {
        try {
            var currentUser = await auth.getUser();
            var exportData = await generateLogsExportData(currentUser);

            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportData)));
            element.setAttribute('download', `driverapp-logs-${new Date().toLocaleString()}.json`);
        
            element.style.display = 'none';
            document.body.appendChild(element);
        
            element.click();
        
            document.body.removeChild(element);
        }
        catch (error) {
            // Make sure we do not create extra errors
            console.error(error);
        }
    };

    useImperativeHandle(ref, () => ({
        generateDataToExport: generateLogsExportData
    }));

    const generateLogsExportData = async (user) => {
        var logs = await db.logs.toArray();
        var actions = await db.actions.toArray();
        
        return {
            Version: version,
            Timestamp: utils.toLocaleString(new Date()),
            UserAgent: navigator.userAgent.toLowerCase(),
            User: {
                profile: user?.profile,
                expires_at: user?.expires_at
            },
            Logs: logs,
            Actions: actions
        };
    };

    return (
        <Button onClick={exportLogsToFile}>
            {label ?? t("ExportLogs")}
        </Button>
    );

});

export default ExportLogsButton;