import React, { forwardRef, useState, useEffect, useImperativeHandle } from 'react';
import db from '../../utils/dexie/driverappdb';
import { Box, Checkbox, FormControl, FormControlLabel, InputLabel, makeStyles, MenuItem, Select, TextField, Card, CardHeader, CardContent } from '@material-ui/core';
import { RemarkFieldType } from './RemarkFieldType';
import { useTranslation } from 'react-i18next';
import hexToRgba from 'hex-to-rgba';
import { buildGroupedRemarks, buildRemarkGroupsDictionary, lookUpLabel, lookUpValue } from './utils';
import { primaryColor } from '../../themes/litefleet';
import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    errorLabel: {
        color: "#FF0000 !important"
    },
    fieldInput: {
        marginBottom: theme.spacing(5)
    },
    fieldInputIcon: {
        marginRight: theme.spacing(2)
    }
}));

const useRemarkGroupStyles = makeStyles(theme => ({
    highlightedRemarkGroup: {
        borderLeftWidth: 8,
        // We can't use the settings of the theme because the component which uses those styles 
        // is also used during the sync and at that moment the correct theme-settings aren't applied yet. 
        borderLeftColor: hexToRgba(primaryColor.main, .8)
    }
}));

export const ConditionalRemarkGroupWrapper = ({ isTopLevelGroup, group, contentStyles, isStaticRendering = false, children }) => {

    const [, i18n] = useTranslation();
    const classes = useRemarkGroupStyles();

    /**
     * Remark:
     * We cannot place this logic inside the useEffect hook because that hook isn't called during static rendering,
     * which is currenlty used to combine the fixed remarks into a single field during sync.
     */
    const lookUpLabel = useCallback((group) => {
        if (group == null)
            return '';

        var lookedUpLabel = group.translatedTerm;
        var translations = group.translations;

        if (translations != null && translations.length > 0) {
            var translation = translations.find(t => t.language?.abbreviation.toLowerCase() === i18n.language.toLowerCase());

            // Fallback for a language which doesn't exists inside the labels.
            if (translation == null)
                translation = translations[0];

            if (translation != null)
                lookedUpLabel = translation.translatedTerm;
        }
        
        return lookedUpLabel;
    }, [i18n.language]);

    const [label, setLabel] = useState(isStaticRendering ? lookUpLabel(group) : '');

    useEffect(() => {
        if (group == null)
            return null;

        setLabel(lookUpLabel(group))
    }, [group, lookUpLabel]);

    return (
        <React.Fragment>
            {
                isTopLevelGroup ?
                    children :
                    <Card variant="outlined" style={{marginTop: 15, marginBottom: 15}} className={group?.highlighted ? classes.highlightedRemarkGroup : ''}>
                        <CardHeader subheader={label} />
                        <CardContent style={{marginTop: -30, ...contentStyles}}>
                            {children}
                        </CardContent>
                    </Card>
            }
        </React.Fragment>
    );
};

const Remarks = forwardRef(({ 
    remarkType = "driver", 
    remarks, 
    onChange = () => {}, 
    onRequiredChange
}, ref) => {
    const classes = useStyles();
    const [, i18n] = useTranslation();
    const [enabledRemarks, setEnabledRemarks] = useState([]);
    const [groupDictionary, setGroupDictionary] = useState({});
    const [groupedRemarks, setGroupedRemarks] = useState([]);

    useEffect(() => {
        if (remarks) {
            let filteredRemarks = remarks.filter(r => r.enabled)
                .map((remark, i) => {
                    return { ...remark, error: false };
                });
            setEnabledRemarks(filteredRemarks);

            setGroupDictionary(buildRemarkGroupsDictionary(filteredRemarks));
            setGroupedRemarks(buildGroupedRemarks(filteredRemarks));
        }
    }, [remarkType, remarks]);

    useEffect(() => {
        if (remarks && typeof(onRequiredChange) === "function") { 
            // Do not use default function for 'onRequiredChange' because it triggers a re-rendering continuously which fails on the skip-view because it does not pass a custom function
            onRequiredChange(remarks.filter(r => r.enabled && r.required).length > 0);
        }
    }, [remarks, onRequiredChange]);

    const handleChangeRemark = (remarkId, value) => {
        Promise.resolve(
            setEnabledRemarks(prevRemarks => prevRemarks.map((remark, i) => {
                if (remark.remarkId !== remarkId)
                    return remark;

                let remarkValue;
                switch (remark.fieldType) {
                    case RemarkFieldType.CHECKBOX:
                        remarkValue = `${`${value}`?.toLowerCase() === "true"}`;
                        break;
                    default:
                        remarkValue = value;
                        break;
                }

                return { ...remark, remark: remarkValue };
            }))
        )
        .then(() => onChange());
    }

    const validateTextField = (remarkValidationState) => {
        if (remarkValidationState != null && remarkValidationState.required && 
            (remarkValidationState.remark == null || remarkValidationState.remark.replace(/\s/g, '').length < 1)) {
                return false;
        }

        return true;
    }

    const validateDropDown = (remarkValidationState) => {
        if (remarkValidationState != null && remarkValidationState.required && 
            (remarkValidationState.remark == null || remarkValidationState.remark.replace(/\s/g, '').length < 1)) {
                return false;
        }

        return true;
    }

    const validateCheckbox = (remarkValidationState) => {
        if (remarkValidationState != null && remarkValidationState.required && 
            (remarkValidationState.remark == null || remarkValidationState.remark !== "true"))
                return false;  

        return true;
    }

    const validateEmail = (remarkValidationState) => {
        if (remarkValidationState != null) {
            const remarkIsEmpty = remarkValidationState.remark == null || remarkValidationState.remark.replace(/\s/g, '').length < 1;
            if (remarkValidationState.required && remarkIsEmpty)
                return false;
            else if (!remarkIsEmpty && !remarkValidationState.remark.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
                return false;
        }

        return true;
    }

    const validatePhoneNumber = (remarkValidationState) => {
        if (remarkValidationState != null) {
            const remarkIsEmpty = remarkValidationState.remark == null || remarkValidationState.remark.replace(/\s/g, '').length < 1;
            if (remarkValidationState.required && remarkIsEmpty)
                return false;
            else if (!remarkIsEmpty && !remarkValidationState.remark.match(/^(\+?[0-9 -/]*)$/gm))
                return false;
        }

        return true;
    }

    const validateUrl = (remarkValidationState) => {
        if (remarkValidationState != null) {
            const remarkIsEmpty = remarkValidationState.remark == null || remarkValidationState.remark.replace(/\s/g, '').length < 1;
            if (remarkValidationState.required && remarkIsEmpty)
                return false;
            else if (!remarkIsEmpty && !remarkValidationState.remark.match(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/))
                return false;
        }

        return true;
    }

    const validateInteger = (remarkValidationState) => {
        if (remarkValidationState != null) {
            const remarkIsEmpty = remarkValidationState.remark == null || remarkValidationState.remark.replace(/\s/g, '').length < 1;
            if (remarkValidationState.required && remarkIsEmpty)
                return false;
            else if (!remarkIsEmpty && !Number.isInteger(parseFloat(remarkValidationState.remark)))
                return false;
        }

        return true;
    }

    const validateDouble = (remarkValidationState) => {
        if (remarkValidationState != null) {
            const remarkIsEmpty = remarkValidationState.remark == null || remarkValidationState.remark.replace(/\s/g, '').length < 1;
            if (remarkValidationState.required && remarkIsEmpty)
                return false;
            else if (!remarkIsEmpty && Number.isNaN(remarkValidationState.remark))
                return false;
        }

        return true;
    }

    /**
     * Returns the (translated) options for a dropdown remark by checking the configuration if necessary.
     */
    const lookUpDropDownOptions = (remark) => {
        let options = [];
        let configuration = remark.configuration;

        if (configuration != null && configuration.labelAndText != null && 
            configuration.labelAndText.remarkConfigTexts != null && configuration.labelAndText.remarkConfigTexts.length > 0) {
            options = configuration.labelAndText.remarkConfigTexts.map(configText => {
                let textModel = configText.textModels.find(m => m.languageAbbr.toLowerCase() === i18n.language.toLowerCase());

                // Fallback for a language which doesn't exists inside the TextModels.
                if (textModel == null && configText.textModels.length > 0)
                    textModel = configText.textModels[0];
                
                return textModel;
            });
        }

        return options;
    }

    useImperativeHandle(ref, () => ({

        // SilenceMode will validate without showing the results in the UI.
        validate: (silenceMode) => {
            let validationResult = true;
            setEnabledRemarks(enabledRemarks.map((remarkValidationState, i) => {
                let valid = true;

                switch(remarkValidationState.fieldType) {
                    case RemarkFieldType.CHECKBOX:
                        valid = validateCheckbox(remarkValidationState);
                        break;
                    case RemarkFieldType.EMAIL:
                        valid = validateEmail(remarkValidationState);
                        break;
                    case RemarkFieldType.PHONENUMBER:
                        valid = validatePhoneNumber(remarkValidationState);
                        break;
                    case RemarkFieldType.URL:
                        valid = validateUrl(remarkValidationState);
                        break;
                    case RemarkFieldType.INT:
                        valid = validateInteger(remarkValidationState);
                        break;
                    case RemarkFieldType.DOUBLE:
                    case RemarkFieldType.FLOAT:
                        valid = validateDouble(remarkValidationState);
                        break;
                    case RemarkFieldType.DROPDOWN:
                        valid = validateDropDown(remarkValidationState);
                        break;
                    default:
                        valid = validateTextField(remarkValidationState);
                        break;
                }

                if (!valid)
                    validationResult = false;

                return silenceMode ? remarkValidationState : { ...remarkValidationState, error: !valid }; 
            }));
            return validationResult;
        },

        save: () => {
            switch(remarkType) {
                case "skip":
                    enabledRemarks.forEach(d => {
                        db.skipremarks.update(parseInt(d.id), {
                            remark: d.remark
                        });
                    });
                    break;
                default:
                    enabledRemarks.forEach(d => {
                        db.driverremarks.update(parseInt(d.id), {
                            remark: d.remark
                        });
                    });
                    break;
            }
            
            return enabledRemarks;
        }

    }));

    return (
        <Box style={{width: '100%'}}>
            {
                groupedRemarks.map(groupedRemarks => {
                    var groupId = groupedRemarks.groupId;
                    var remarkGroup = groupDictionary[groupId];
                    var remarkIdsInGroup = groupedRemarks.remarks;
                    var isTopLevelGroup = groupId == null || isNaN(parseInt(groupId));

                    return (
                        <ConditionalRemarkGroupWrapper isTopLevelGroup={isTopLevelGroup} group={remarkGroup} 
                            key={isTopLevelGroup ? 'top-level-remarks' : `remark-group-${groupId}`}>
                            {
                                // Still keep using the elements of the enabledRemarks to make sure all change-events and rerendering will still work for remarks in or outside a remarkgroup
                                // This way the remarkGroups are only used to combine the elements in the UI.
                                enabledRemarks.filter(r => remarkIdsInGroup.indexOf(r.remarkId) >= 0)
                                    .map(remark => {
                                        let labelText = lookUpLabel(remark);
                                        let label = labelText + (remark.required ? ' *' : '');

                                        let remarkValue = lookUpValue(remark);
                    
                                        const defaultTextFieldProps = {
                                            key: remark.remarkId,
                                            label: label,
                                            value: remarkValue || "",
                                            required: remark.required,
                                            error: remark.error,
                                            onChange: (e) => handleChangeRemark(remark.remarkId, e.target.value),
                                            variant: "outlined",
                                            className: classes.fieldInput, 
                                            fullWidth: true
                                        };

                                        switch(remark.fieldType) {
                                            case RemarkFieldType.CHECKBOX:
                                                return <FormControlLabel 
                                                    key={remark.remarkId}
                                                    label={label}
                                                    labelPlacement="end"
                                                    control={<Checkbox color="primary" required={remark.required} 
                                                    checked={lookUpValue(remark, fallbackValue => handleChangeRemark(remark.remarkId, fallbackValue))?.toLowerCase() === "true"} />}
                                                    onChange={(e) => handleChangeRemark(remark.remarkId, e.target.checked)}
                                                    className={clsx([classes.fieldInput, remark.error ? classes.errorLabel : ""]) }
                                                />;
                                            case RemarkFieldType.EMAIL:
                                                return <TextField
                                                    {...defaultTextFieldProps}
                                                    type="email"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <FontAwesomeIcon icon={["fad", "at"]} className={classes.fieldInputIcon} />
                                                        )
                                                    }} />;
                                            case RemarkFieldType.PHONENUMBER:
                                                return <TextField
                                                    {...defaultTextFieldProps}
                                                    type="tel"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <FontAwesomeIcon icon={["fad", "phone"]} className={classes.fieldInputIcon} />
                                                        )
                                                    }} />
                                            case RemarkFieldType.URL:
                                                return <TextField
                                                    {...defaultTextFieldProps}
                                                    type="url"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <FontAwesomeIcon icon={["fad", "link"]} className={classes.fieldInputIcon} />
                                                        )
                                                    }} />;
                                            case RemarkFieldType.INT:
                                            case RemarkFieldType.DOUBLE:
                                            case RemarkFieldType.FLOAT:
                                                return <TextField {...defaultTextFieldProps} type="number" />;
                                            case RemarkFieldType.DROPDOWN:
                                                return (
                                                    <FormControl key={remark.remarkId} fullWidth variant="outlined" required={false} className={classes.fieldInput} error={remark.error}>
                                                        <InputLabel id={`${remark.remarkId}`}>{label}</InputLabel>
                                                        <Select 
                                                            labelId={`${remark.remarkId}`}
                                                            label={label} 
                                                            onChange={(e) => handleChangeRemark(remark.remarkId, e.target.value)}
                                                            value={lookUpValue(remark, fallbackValue => handleChangeRemark(remark.remarkId, fallbackValue)) || ''}>
                                                            <MenuItem value=''></MenuItem>
                                                            {
                                                                lookUpDropDownOptions(remark)
                                                                    .map(o => <MenuItem key={`${remark.remarkId}-${o.id}`} value={o.id}>{o.description}</MenuItem>)        
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                );
                                            default:
                                                return <TextField {...defaultTextFieldProps} rows="6" multiline />;
                                        }
                                })
                            }
                        </ConditionalRemarkGroupWrapper>
                    );
                })
            }
        </Box>
    );
})

export default Remarks;