import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography, AppBar, TableContainer, Table, TableHead, TableCell, TableBody, TableRow, IconButton, Collapse, Container, makeStyles, Chip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { motion } from "framer-motion";
import { useEffect } from 'react';
import utils from '../../../utils';
import ExportLogsButton from '../../../components/exportLogsButton';

/**
 * During creation of the logs and actions, the dates are saved with a correction of the timezone.
 * To be able to use util function to display a locale string of this date, we need to undo this correction first.
 * @param {*} date 
 * @returns 
 */
const resetTimeZoneCorrection = (date) => {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} {...other}>
            {
                value === index && (
                    <Box p={3} component="div">
                        <div>{children}</div>
                    </Box>
                )
            }
        </div>
    );
}

const useTableRowStyles = makeStyles(theme => ({
    accordionLabel: {
        wordBreak: 'break-all'
    },
    label: {
        fontWeight: 700
    }
}));

const LogTableRow = ({ log, openByDefault = false }) => {

    const classes = useTableRowStyles();
    const [open, setOpen] = useState(openByDefault);

    useEffect(() => { }, [openByDefault]);

    return (
        <>
            <TableRow key={log.id}>
                <TableCell>
                    <IconButton size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    {
                        log.isError ? 
                            <Chip label="ERROR" size="small" color="secondary" /> : 
                            <Chip label="INFO" size="small" color="primary" />
                    }
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                    {utils.toLocaleString(resetTimeZoneCorrection(new Date(log.date)))}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3} className={classes.accordionLabel}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Container margin={1}>
                            <Typography variant="body1" gutterBottom component="h6">
                                <span className={classes.label}>Id:</span> {log.id}
                            </Typography>
                            <Typography variant="body1" gutterBottom component="h6" className={classes.label}>Message:</Typography>
                            <Typography variant="body2" gutterBottom component="div">{log.message}</Typography>
                            {
                                log.isError  ? 
                                    <>
                                        <Typography variant="body1" gutterBottom component="div" className={classes.label}>Stacktrace:</Typography>
                                        <Typography variant="body2" gutterBottom component="div">{log.stackTrace}</Typography>
                                    </> : <></>
                            }
                            {
                                log.data != null ?
                                    <>
                                        <Typography variant="body1" gutterBottom component="div" className={classes.label}>Data:</Typography>
                                        <Typography variant="body2" gutterBottom component="div">{JSON.stringify(log.data)}</Typography>
                                    </> : <></>
                            }
                        </Container>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

const ActionTableRow = ({ action }) => {

    const classes = useTableRowStyles();
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow key={action.id}>
                <TableCell>
                    <IconButton size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" className={classes.accordionLabel}>
                    {action.type} [{action.key}] ({action.attempt ?? 0})
                </TableCell>
                <TableCell align="right">
                    {utils.toLocaleString(resetTimeZoneCorrection(new Date(action.time)))}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">Id: {action.id}</Typography>
                            <Typography variant="h6" gutterBottom component="div">Key:</Typography>
                            <div>{action.key}</div>
                            <Typography variant="h6" gutterBottom component="div">Attempt:</Typography>
                            <div>{action.attempt}</div>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );

}

const LogsTable = ({ logs }) => {

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell align="right">Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {logs.map((log, index) => <LogTableRow key={log.id} log={log} openByDefault={index === 0}/>)}
                </TableBody>
            </Table>
        </TableContainer>
    );
    
}

const ActionsTable = ({ actions }) => {

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell align="right">Time</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        actions.map(action => <ActionTableRow key={action.id} action={action}/>)
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );

}

const LogsView = ({ logs, actions }) => {
    const [t] = useTranslation();
    const [currentTab, setCurrentTab] = useState(0);
    
    const handleTabChange = (event, newTab) => {
        setCurrentTab(newTab);
    }

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} style={{maxWidth: "100%"}}>
            <Box textAlign={'center'} marginBottom={2}>
                <ExportLogsButton label={t("Export")} />
            </Box>
            <AppBar position="static" color="default">
                <Tabs value={currentTab} onChange={handleTabChange} textColor="primary" indicatorColor="primary" variant="fullWidth">
                    <Tab label={t("Logs")} />
                    <Tab label={t("Actions")} />
                </Tabs>
            </AppBar>
            <TabPanel value={currentTab} index={0}>
                <LogsTable logs={logs.sort((a, b) => a.date - b.date)} />
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
                <ActionsTable actions={actions.sort((a, b) => a.time - b.time)} />
            </TabPanel>
        </motion.div>
    );

}

export default LogsView;