import React from 'react';
import { CardActions, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    actionsWrapper: {
        '@media (max-width: 390px)': {
            // Scale the action buttons on small screens
            '& .MuiButton-root': {
                minWidth: 40
            }
        },
    }
}));

const PlanningItemActions = (props) => {
    const classes = useStyles();

    return (
        React.Children.count(props.children) ?
            <CardActions className={classes.actionsWrapper}>{props.children}</CardActions> : null
    );
};

export default PlanningItemActions;