import { Box, makeStyles, useTheme } from "@material-ui/core";
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
    oppositeContent: {
        flex: 0,
        padding: 0
    },
    connector: {
        width: 1,
        backgroundColor: 'rgba(0, 0, 0, .08)'
    },
    content: {
        marginBottom: theme.spacing(6)
    }
}));

const PlanningTimelineItem = ({ children, icon, iconOpacity, iconColor}) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <TimelineItem>
            <TimelineOppositeContent className={classes.oppositeContent} />
            <TimelineSeparator>
                <Box component='span' 
                    style={{
                        color: iconColor ?? theme.palette.primary.main, 
                        opacity: iconOpacity
                    }}
                >
                    <TimelineDot color="inherit" variant="outlined">{icon}</TimelineDot>
                </Box>
                <TimelineConnector className={classes.connector} />
            </TimelineSeparator>
            <TimelineContent className={classes.content}>{children}</TimelineContent>
        </TimelineItem>
    );
}

export default PlanningTimelineItem;