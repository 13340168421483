import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../../utils/contexts/appContext';
import db from '../../../utils/dexie/driverappdb';
import utils from '../../../utils';
import queries from '../../../utils/dexie/queries';
import Loader from '../../../components/loader';
import { TimelineProvider, TimelineContext } from '../../../utils/contexts/timelineContext';
import PauseButton from '../../../components/pauseButton';
import FilterButton from '../../../components/filterButton';

import './Timeline.css';
import picPostman from '../../../assets/images/general/postman.jpg';
import { Chip, makeStyles, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import hexToRgba from 'hex-to-rgba';
import { syncActions } from '../../../utils/dexie/sync';
import { AuthContext } from '../../../providers/AuthProvider';
import DropType from '../../../components/dropType/DropType';
import { Alert } from '@material-ui/lab';
import { FontAwesomeDuoToneStyle } from '../../../fontawesome';
import DataReloader from '../../../components/dataReloader/data-reloader';
import { ExternalStatus } from '../../../components/external-status';
import { useApplicationParams } from '../../../hooks/app-params';
import { Actions } from '../../../utils/enums/actions';

const pageVariants = {
    in: {
        opacity: 1
    },
    out: {
        opacity: 0
    }
};

const TimelineIcon = ({ status }) => {
    if (status === 20) {
        return <FontAwesomeIcon icon={["far", "circle"]} className="timeline-icon drop-dispatched" />;
    } else if (status === 30) {
        return <FontAwesomeIcon icon={["far", "location-circle"]} className="timeline-icon drop-busy" />;
    } else if (status === 40) {
        return <FontAwesomeIcon icon={["far", "check-circle"]} className="timeline-icon drop-done" />;
    } else if (status === 50) {
        return <FontAwesomeIcon icon={["far", "times-circle"]} className="timeline-icon drop-skipped" />;
    } else if (status === 70) {
        return <FontAwesomeIcon icon={["far", "ban"]} className="timeline-icon drop-skipped" />;
    }
    return (<FontAwesomeIcon icon={["far", "circle"]} className="timeline-icon drop-dispatched" />);
}

const TimelineActions = ({ 
    appParams,
    onFilterChange = (filter) => {}
}) => {
    const [timelineState,] = useContext(TimelineContext);

    return (
        <div className="align-self-center" style={{float: 'right'}}>
            <PauseButton visible={appParams.pauseAllowed && timelineState.status === 30} />
            <FilterButton onChange={onFilterChange} />
        </div>
    );
}

const useTimelineDepotStyles = makeStyles(theme => ({
    
    actionButton: {
        color: theme.palette.success.main,
        borderColor: hexToRgba(theme.palette.success.main, .5)
    }

}));

const TimelineDepot = ({ 
    type, 
    location, 
    eta, 
    delay, 
    openRoutesCount, 
    numberOfBusyDrops, 
    navigationApp, 
    onUpdateRouteStatus = (newStatus) => {} 
}) => {
    const [{ pause },] = useAppContext();
    const [routeState, setRouteState] = useContext(TimelineContext);
    const [dialogInterruptConfirmationIsOpen, setDialogInterruptConfirmationIsOpen] = useState(false);
    const classes = useTimelineDepotStyles();
    const [t,] = useTranslation();
    const auth = useContext(AuthContext);

    let history = useHistory();
    let visible = true;

    if (type === "start" && routeState.status >= 30) {
        visible = routeState.status === 70 || routeState.showFinishedDeliveries;
    } else if (type === "stop" && routeState.status === 40) {
        visible = routeState.showFinishedDeliveries;
    }
 
    const updateRoute = (e, newStatus) => {
        try {
            e.preventDefault();
            let routeId = routeState.routeId;

            let actionType;
            switch(newStatus) {
                case 30: // Busy
                    actionType = "RouteStart";
                    break;
                case 40: //Finished
                    actionType = "RouteStop";
                    break;
                case 70: // Interrupted
                    actionType = "RouteInterrupted";
                    break;
                default:
                    break;
            }

            if (actionType) {
                let now = new Date();
                var utc = now.getTime() - now.getTimezoneOffset() * 60000;

                let updateRouteAction = { type: actionType, key: routeId, time: utc };

                queries.isActionAllowed(updateRouteAction)
                    .then(async (allowed) => {
                        if (allowed) {
                            if (actionType === "RouteStop") {
                                // The article usages of interrupted drops are not synced yet because they could be reopened.
                                // Now we can sync those because once a route is stopped, they cannot be reopened anymore.
                                // So trigger a sync of those article usages before stopping the route.
                                await db.actions.put({ type: "SYNC_ARTICLE_USAGES_INTERRUPTED_DROPS", key: routeId, time: utc });
                            }

                            db.routes.get(parseInt(routeId))
                                .then(route => {
                                    let time = route.start;
                                    if (actionType === "RouteStop" || actionType === "RouteInterrupted")
                                        time = route.stop;

                                    let updatedRouteData = {
                                        status: newStatus, 
                                        delay: utils.calculateDelay(new Date(time), now) 
                                    };

                                    if (actionType === "RouteStart") {
                                        updatedRouteData.realStart = new Date(now).toISOString()
                                    }

                                    db.routes.update(parseInt(routeId), updatedRouteData);
                                });

                            setRouteState(routeState => ({ ...routeState, status: newStatus }));

                            db.actions.put(updateRouteAction)
                                .finally(() => {
                                    if (actionType === "RouteStop") {
                                        syncActions(auth);
                                    }
                                });

                            if (actionType === "RouteInterrupted") {
                                history.push('/routes');
                            } else if (actionType === "RouteStop") {
                                history.push(`/routes/timeline/${routeState.routeId}`);
                            }
                                
                            if (onUpdateRouteStatus != null && typeof(onUpdateRouteStatus) === 'function') {
                                onUpdateRouteStatus(newStatus);
                            }
                        }
                    });
            }
        } catch (error) {
            queries.addErrorToLogs(error);
            history.push(`/error`);
        }        
    }

    const handleRouteInterruptRequest = () => {
        setDialogInterruptConfirmationIsOpen(true);
    }

    const handleRouteInterrupt = (event) => {
        setDialogInterruptConfirmationIsOpen(false);
        updateRoute(event, 70);
    }

    const handleNavigation = () => {
        switch (navigationApp) {
            case "google":
                window.open("https://www.google.com/maps/dir/?api=1&destination=" + encodeURI(location.address) + "&directionsmode=driving");
                break;
            case "here":
                window.open(`https://share.here.com/r/${location.lat},${location.lon},${encodeURI(location.address)}?m=d`);
                break;
            case "waze":
            default:
                window.open("https://www.waze.com/ul?q=" + encodeURI(location.address) + "&navigate=yes&zoom=17");
                break;
        }
    }

    return (
        <>
            {
                visible ?
                    <div>
                        <FontAwesomeIcon icon={["far", "user-circle"]} className="timeline-icon drop-done" />
                        <div className="timeline-item-content round-small bottom-0">
                            <div className="bottom-0">
                                { /*type === "stop" &&
                                    <div className="drop-header">
                                        <div className="drop-header-original">
                                            <i className="far fa-clock" aria-hidden="true"></i> {eta}
                                            <span className="drop-header-overtime"> {delay !== 0 && utils.getDelayString(delay)}</span>
                                        </div>
                                    </div>*/
                                }
                                <div className="drop-container">
                                    <div className="drop-body">
                                        {
                                            location ? 
                                                <>
                                                    <div className="drop-customer-title">{location.name}</div>
                                                    <div>{location.address}</div>
                                                </> 
                                                : <></>
                                        }
                                    </div>
                                    {
                                        type === "start" && (routeState.status === 10 || routeState.status === 20 || routeState.status === 70) ?
                                            <>
                                                <hr className="divider"></hr>
                                                <div className="drop-buttons">
                                                    <Button onClick={(e) => updateRoute(e, 30)} variant="outlined" className={classes.actionButton} 
                                                        disabled={pause || openRoutesCount > 0}>
                                                        <FontAwesomeIcon icon={["fad", "check"]} />
                                                        <span className="button-text-with-icon">{t("Start")}</span>
                                                    </Button>
                                                </div>
                                            </> :
                                            <></>
                                    }
                                    {
                                        type === "stop" && routeState.status < 40 ?
                                            <>
                                                <hr className="divider"></hr>
                                                <div className="drop-buttons">
                                                    <Button onClick={(e) => updateRoute(e, 40)} variant="outlined" className={classes.actionButton} 
                                                        disabled={pause || routeState.opendropsCount > 0}>
                                                        <FontAwesomeIcon icon={["fad", "check"]} />
                                                        <span className="button-text-with-icon">{t("Stop")}</span>
                                                    </Button>
                                                    <Button onClick={handleRouteInterruptRequest} color="primary" 
                                                        disabled={pause || routeState.status !== 30 || numberOfBusyDrops > 0}>
                                                        <span>{t("Interrupt")}</span>
                                                    </Button>
                                                    {
                                                        location ?
                                                            <Button onClick={handleNavigation} variant="outlined" color="primary">
                                                                <FontAwesomeIcon icon={["fad", "map-marker-alt"]} />
                                                                <span className="button-text-with-icon">{t("Navigation")}</span>
                                                            </Button> : <></>
                                                    }
                                                </div>
                                            </> :
                                            <></>
                                    }
                                </div>
                            </div>
                            <Dialog 
                                open={dialogInterruptConfirmationIsOpen}
                                onClose={() => setDialogInterruptConfirmationIsOpen(false)}
                                aria-labelledby="start-alert-dialog-title"
                                aria-describedby="start-alert-dialog-description">
                                <DialogTitle id="start-alert-dialog-title">{t("Interrupt")}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="start-alert-dialog-description">
                                        {t("ConfirmRouteInterruptRequest")}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setDialogInterruptConfirmationIsOpen(false)} color="primary">{t("Cancel")}</Button>
                                    <Button onClick={handleRouteInterrupt} color="primary" autoFocus>{t("Interrupt")}</Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </div>
                    : <div></div>
            }
        </>
    );
}

const useTimelineItemStyles = makeStyles(theme => ({
    labelsWrapper: {
        display: 'flex',
        '& > *': {
            marginRight: 5,
            marginBottom: 10
        }
    },
    actionButton: {
        color: theme.palette.success.main,
        borderColor: hexToRgba(theme.palette.success.main, .5)
    },
    colleagueScheduleIcon: {
        marginRight: 5
    }
}));

const TimelineItem = ({ drop, dropType, delay, skipAllowed, useTimeRegistrations, activeAtArrivalAndDepartureEnabled, 
    navigationApp, routeState, routeAutoStart, openRoutesCount, numberOfBusyDrops, autoAcceptTimeRegistrations, params }) => {
    const [{ pause },] = useAppContext();
    const [state, setState] = useContext(TimelineContext);
    const [dialogStartIsOpen, setDialogStartIsOpen] = useState(false);
    const [dialogResumeIsOpen, setDialogResumeIsOpen] = useState(false);
    const [projectType, setProjectType] = useState();
    const [t,] = useTranslation();
    const styles = useTimelineItemStyles();
    let history = useHistory();

    /**
     * Item is disabled if one of the following conditions are met:
     * - The app is paused
     * - There is at least 1 OTHER route which is already busy
     * - This route has been finished
     * - This route has been interrupted AND autostart of a route is disabled
     * - This route has not been started AND autostart of a route is disabled
     * - This route has at least 1 OTHER drop which is already busy
     */
    let disabled = pause || (openRoutesCount > 0 && state.status !== 30) || state.status === 40 || (state.status === 70 && !routeAutoStart) || 
                        (state.status < 30 && !routeAutoStart) || (numberOfBusyDrops > 0 && drop.status !== 30);

    useEffect(() => {
        let isMounted = true;

        if (drop.projectType > 0) {
            db.projecttypes.get(parseInt(drop.projectType)).then((type) => {
                if (isMounted) {
                    setProjectType(type);
                }
            });
        }

        return () => { isMounted = false };
    }, [drop.projectType]);

    const inputRequiredBeforeStart = () => {
        // Show the input dialog if the param is set and the projectType is Maintenance (1) or Assistence (2).
        return activeAtArrivalAndDepartureEnabled && drop.status === 20 && projectType && [1, 2].includes(projectType.id)
    }

    const resetInputBeforeStart = () => {
        // Reset the values of the input which is requested before the start of a drop
    }

    const handleBeginRequest = () => {
        if (drop.status === 30) { // Busy
            history.push(`/routes/timeline/job/${drop.id}`);
        } else if (drop.status === 70) { // Interrupted
            autoAcceptTimeRegistrations ? handleResume() : setDialogResumeIsOpen(true);
        } else {
            resetInputBeforeStart();
            autoAcceptTimeRegistrations ? handleStart() : setDialogStartIsOpen(true);
        }
    }

    const handleStart = () => {
        try {
            if (routeAutoStart && (routeState < 30 || routeState === 70) && drop.status < 30) {
                handleAutoStartRoute();
            }

            let now = new Date();
            var utc = now.getTime() - now.getTimezoneOffset() * 60000;

            let dropStartAction = { type: Actions.DROP_START, key: parseInt(drop.id), time: utc };
            
            if (inputRequiredBeforeStart()) {
                // Add the necessary input to the action
            }

            queries.isActionAllowed(dropStartAction)
                .then(allowed => {
                    if (allowed) {
                        db.dropdata.update(parseInt(drop.id), { status: 30 });
                        db.drops.get(parseInt(drop.id))
                            .then((drop) => {
                                db.routes.update(parseInt(drop.routeId), { delay: utils.calculateDelay(new Date(drop.eta), now) });
                            });
                        
                        db.actions.put(dropStartAction);
                        setDialogStartIsOpen(false);
                        history.push(`/routes/timeline/job/${drop.id}`);
                    }
                });
            
        } catch (error) {
            queries.addErrorToLogs(error);
            history.push(`/error`);
        }
    }

    const handleAutoStartRoute = () => {
        try {            
            let newStatus = 30; // Busy
            let routeId = state.routeId;
            let now = new Date();
            var utc = now.getTime() - now.getTimezoneOffset() * 60000;

            let routeStartAction = { type: "RouteStart", key: routeId, time: utc };
            queries.isActionAllowed(routeStartAction)
                .then(allowed => {
                    if (allowed) {
                        db.routes.get(parseInt(routeId))
                            .then((route) => {
                                db.routes.update(parseInt(routeId), { 
                                    status: newStatus, 
                                    delay: utils.calculateDelay(new Date(route.start), now),
                                    realStart: new Date(now).toISOString()
                                });
                            });
                
                        setState(state => ({ ...state, status: newStatus }));
                        db.actions.put(routeStartAction);
                    }
                });
        } catch (error) {
            queries.addErrorToLogs(error);
            history.push(`/error`);
        }
    }

    const handleResume = () => {
        try {
            if (routeAutoStart && routeState === 70 && drop.status === 70) {
                handleAutoStartRoute();
            }

            let now = new Date();
            var utc = now.getTime() - now.getTimezoneOffset() * 60000;

            let dropResumeAction = { type: Actions.DROP_START, key: parseInt(drop.id), time: utc };
            queries.isActionAllowed(dropResumeAction)
                .then(allowed => {
                    if (allowed) {
                        db.dropdata.update(parseInt(drop.id), { status: 30 });
                        db.drops.get(parseInt(drop.id))
                            .then((drop) => {
                                db.routes.update(parseInt(drop.routeId), { delay: utils.calculateDelay(new Date(drop.eta), now) });
                            });

                        db.actions.put(dropResumeAction);
                        setDialogResumeIsOpen(false);
                        history.push(`/routes/timeline/job/${drop.id}`);
                    }
                });
        } catch (error) {
            queries.addErrorToLogs(error);
            history.push(`/error`);
        }
    }

    const handleNavigation = () => {
        switch (navigationApp) {
            case "google":
                window.open("https://www.google.com/maps/dir/?api=1&destination=" + encodeURI(drop.location.address) + "&directionsmode=driving");
                break;
            case "here":
                window.open(`https://share.here.com/r/${drop.location.lat},${drop.location.lon},${encodeURI(drop.location.address)}?m=d`);
                break;
            case "waze":
            default:
                window.open("https://www.waze.com/ul?q=" + encodeURI(drop.location.address) + "&navigate=yes&zoom=17");
                break;
        }
    }

    const handleFinishRequest = (e) => {
        e.preventDefault();
        history.push(`/routes/timeline/arrive/${drop.id}`);
    }

    const handleSkipRequest = (e) => {
        e.preventDefault();
        history.push(`/routes/timeline/skip/${drop.id}`);
    }

    return (
        <div>
            <TimelineIcon status={drop.status} />
            <div className="timeline-item-content round-small bottom-0">
                <div className="bottom-0">
                    <div className="drop-header">
                        <div className="drop-header-original">
                            <FontAwesomeIcon icon={["fad", "clock"]} style={{ ...FontAwesomeDuoToneStyle, marginRight: 5 }} /> {drop.etaString}
                            {/*<span className="drop-header-overtime"> {delay !== 0 && utils.getDelayString(delay)}</span>*/}
                        </div>
                        {
                            drop != null && drop.colleagueSchedules != null && drop.colleagueSchedules.length > 0 ?
                                <>
                                    {
                                        drop.colleagueSchedules?.map(schedule => (
                                            <div key={`colleague-${schedule.deliveryOrderScheduleId}`} className="drop-header-original">
                                                <FontAwesomeIcon icon={["fad", "user-hard-hat"]} style={FontAwesomeDuoToneStyle} className={styles.colleagueScheduleIcon} /> 
                                                <span>{schedule.simulationSolutionRouteDrop?.driverName}</span>
                                            </div>
                                        ))
                                    }
                                </> : <></>
                        }
                    </div>
                    <div className="drop-container">
                        <div className="drop-body">
                            <div className="drop-customer-title">{drop.index}) {drop.location.name}</div>
                            <div>{drop.location.address}</div>
                            <div>{drop.location.remark}</div>
                            <div>{drop.tw}</div>
                        </div>
                        <hr className="divider"></hr>
                        <div className="drop-buttons">
                            <Button onClick={() => history.push(`/routes/timeline/info/${drop.id}`) } variant="outlined" color="primary">
                                <FontAwesomeIcon icon={["fad", "info"]} />
                                <span className="button-text-with-icon">{t("Info")}</span>
                            </Button>
                            <Button onClick={handleNavigation} variant="outlined" color="primary">
                                <FontAwesomeIcon icon={["fad", "map-marker-alt"]} />
                                <span className="button-text-with-icon">{t("Navigation")}</span>
                            </Button>
                            {  
                                drop.status === 20 || drop.status === 30 || drop.status === 70 ?
                                    useTimeRegistrations ?
                                        <>
                                            <Button variant="outlined" className={styles.actionButton} disabled={disabled} onClick={handleBeginRequest}>
                                                <FontAwesomeIcon icon={["fad", "check"]} />
                                                <span className="button-text-with-icon">
                                                    {drop.status === 30 || drop.status === 70 ? <>{t("Resume")}</> : <>{t("Start")}</>}
                                                </span>
                                            </Button>
                                            <Dialog
                                                open={dialogStartIsOpen}
                                                onClose={() => setDialogStartIsOpen(false)}
                                                aria-labelledby="start-alert-dialog-title"
                                                aria-describedby="start-alert-dialog-description">
                                                <DialogTitle id="start-alert-dialog-title">{drop.location.name}</DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText id="start-alert-dialog-description">
                                                        {t("StartTimeRegistrationQuestion")} {drop.location.name}?
                                                    </DialogContentText>
                                                    {
                                                        inputRequiredBeforeStart() ? <></> : <></>
                                                    }
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={() => setDialogStartIsOpen(false)} color="primary">{t("Cancel")}</Button>
                                                    <Button onClick={handleStart} color="primary" autoFocus>{t("Start")}</Button>
                                                </DialogActions>
                                            </Dialog>
                                            <Dialog
                                                open={dialogResumeIsOpen}
                                                onClose={() => setDialogResumeIsOpen(false)}
                                                aria-labelledby="resume-alert-dialog-title"
                                                aria-describedby="resume-alert-dialog-description">
                                                <DialogTitle id="resume-alert-dialog-title">{drop.location.name}</DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText id="resume-alert-dialog-description">
                                                        {t("ResumeTimeregistrationQuestion")} {drop.location.name}?
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={() => setDialogResumeIsOpen(false)} color="primary">{t("Cancel")}</Button>
                                                    <Button onClick={handleResume} color="primary" autoFocus>{t("Resume")}</Button>
                                                </DialogActions>
                                            </Dialog>
                                        </>
                                        :
                                        <Button onClick={handleFinishRequest} variant="outlined" disabled={disabled} className={styles.actionButton}>
                                            <FontAwesomeIcon icon={["fad", "check"]} />
                                            <span className="button-text-with-icon">{t("Finish")}</span>
                                        </Button>
                                    : <></>
                            }
                            {
                                skipAllowed && drop.status === 20 ? 
                                    <Button onClick={handleSkipRequest} variant="outlined" style={{ borderColor: "#e74c3c", color: "#e74c3c" }} disabled={disabled}>
                                        <FontAwesomeIcon icon={["fad", "times"]} />
                                        <span className="button-text-with-icon">{t("Skip")}</span>
                                    </Button> : <></>
                            }
                        </div>
                        { 
                            projectType || dropType || params?.externalStatusManagement ? 
                            <>
                                <hr className="divider" />
                                <div className="drop-body">
                                    <div className={styles.labelsWrapper}>
                                        {
                                            projectType &&
                                                <Chip label={projectType.name} size="small" color="primary" variant="outlined"/>
                                        }
                                        {
                                            dropType &&
                                                <DropType dropType={dropType} />
                                        }
                                        {
                                            params?.externalStatusManagement ? <ExternalStatus drop={drop} /> : null
                                        }
                                    </div>
                                </div>
                            </> :
                            <></>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

function Timeline() {
    let history = useHistory();
    let { routeId } = useParams();
    
    const [, dispatch] = useAppContext();
    const [route, setRoute] = useState({});
    const [drops, setDrops] = useState([]);
    const [filteredDrops, setFilteredDrops] = useState([]);
    const [dropTypes, setDropTypes] = useState([]);
    const [showFinishedDeliveries, setShowFinishedDeliveries] = useState(false);
    const [navigationApp, setNavigationApp] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [busyDropsCount, setBusyDropsCount] = useState(0);
    const [openDropsCount, setOpenDropsCount] = useState(drops.length);
    const [openRoutesCount, setOpenRoutesCount] = useState(0);
    const [t,] = useTranslation();
    const appParams = useApplicationParams();

    useEffect(() => {
        db.transaction('rw', db.routes, db.drops, db.dropdata, db.droptypes, db.settings, db.locations, db.params, async () => {
            await db.settings.where({ name: "NavigationApp" })
                .first(setting => { 
                    if (setting) {
                        setNavigationApp(setting.value);
                    }
                });
                
            // Get the total number of open routes
            await db.routes.where("status").equals(30).toArray().then(async (result) => setOpenRoutesCount(result.length));

            let routes = await db.routes
                .where({ id: parseInt(routeId) })
                .with({ startLocation: 'startLocation', stopLocation: 'stopLocation' });

            if (routes.length > 0) {
                let route = routes[0];

                setRoute(route);

                dispatch({ type: 'changeHeader', header: { title: route.name, child: true, path: '/routes' } });

                let statusses = [20, 30, 70];

                let showFinishedDeliveries = await db.settings.where({ name: "ShowFinishedDeliveries" }).first();
                if (showFinishedDeliveries) {
                    setShowFinishedDeliveries(utils.toBoolean(showFinishedDeliveries.value));
                    if (utils.toBoolean(showFinishedDeliveries.value)) {
                        statusses = [20, 30, 40, 50, 70];
                    }
                }

                queries.joinDropData(await db.drops
                    .where({ routeId: parseInt(route.id) })
                    .with({ location: 'location' })
                )
                .then(drops => drops.filter(drop => statusses.indexOf(drop.status) !== -1))
                .then(async (drops) => {
                    drops = drops.sort((a, b) => { return a.index - b.index });
                    setDrops(drops);
                    setFilteredDrops(drops);

                    let dropTypes = await db.droptypes.toArray();
                    setDropTypes(dropTypes);
                    setBusyDropsCount(drops.filter(d => d.status === 30).length);

                    let openDrops = [];
                    for (let index = 0; index < drops.length; index++) {
                        const element = drops[index];
                        if (element.status <= 30) openDrops.push(element);
                    }
                    setOpenDropsCount(openDrops.length);
                });
                
            } else {
                history.replace('/routes');
            }

        })
        .catch(async (error) => {
            return await queries.addErrorToLogs(error, ['TimelineView']);
        })
        .finally(() => {
            setIsLoading(false);
        });
    }, [dispatch, history, routeId]);

    const filterTimelineItems = (filter) => {
        setFilteredDrops(
            drops.filter(drop => {
                if (filter.text === '')
                    return true;
                let locationFilter = filter.text.toLowerCase();
                return drop.location.name.toLowerCase().includes(locationFilter) || drop.location.address.toLowerCase().includes(locationFilter);
            })
        );
    };

    const getUnproductiveTime = (prevIndex, nextIndex) => {
        if (route == null || (route.unproductiveTimes == null || route.unproductiveTimes.length === 0))
            return null;

        return route.unproductiveTimes.find(t => t.indexOfPrevDrop === prevIndex && t.indexOfNextDrop === nextIndex);
    };

    const getFormattedUnproductiveTime = (startTimeInSeconds, endTimeInSeconds) => {
        var start = new Date(startTimeInSeconds * 1000);
        var end = new Date(endTimeInSeconds * 1000);
        var formatProps = {
            hour: '2-digit', 
            minute:'2-digit',
            timeZone: 'UTC'
        };
        
        return `${start.toLocaleTimeString(navigator.language, formatProps)?.replace(':', 'u')} - ${end.toLocaleTimeString(navigator.language, formatProps)?.replace(':', 'u')}`;
    }

    /**
     * Update the status of the route variable if it has been changed due to the action buttons on depot.
     * @param {*} newStatus 
     */
    const onUpdateRouteStatus = (newStatus) => {
        setRoute(prevRouteState => {
            return {
                ...prevRouteState,
                status: newStatus
            };
        })
    }

    return (
        <div className="global-container">
            {
                isLoading ?
                    <Loader /> :
                    <TimelineProvider value={{
                        routeId: route.id,
                        status: route.status,
                        opendropsCount: openDropsCount,
                        showFinishedDeliveries: showFinishedDeliveries
                    }}>
                        <motion.div className="timeline-item" initial="out" animate="in" exit="out" variants={pageVariants}>
                            <TimelineActions appParams={appParams} onFilterChange={filterTimelineItems} />
                            <div id="timelineActionsWrapper">
                                <div>
                                    <Typography variant="h6" color="primary" style={{ fontWeight: 600 }}>{route.name}</Typography>
                                    <div>
                                        <span>
                                            {route.vehicle}<br />
                                            {route.date}
                                            {
                                                route.realStart ? 
                                                    <>
                                                        <br />
                                                        {`${t("StartedOn")}: ${new Date(route.realStart).toLocaleString()}`}
                                                    </> : <></>
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <>
                                {
                                    route.status === 40 && !showFinishedDeliveries
                                        ?
                                        <div className="center">
                                            <img alt="" className="finishedImage" src={picPostman}></img>
                                            <div>{t("AllDeliveriesAreFinished")}</div>
                                            <div>{t("RoutesChangeInSettings")}</div>
                                        </div>
                                        :
                                        <div>
                                            <div className="timeline-body">
                                                <div className="timeline-deco"></div>
                                                {
                                                    !appParams.routeAutoStart &&
                                                        <TimelineDepot type={"start"} 
                                                            location={route.startLocation} 
                                                            navigationApp={navigationApp} 
                                                            openRoutesCount={openRoutesCount} 
                                                            numberOfBusyDrops={busyDropsCount}
                                                            onUpdateRouteStatus={onUpdateRouteStatus} />
                                                }
                                                {
                                                    filteredDrops.map((drop, i) => {
                                                            var prevIndex = drop.index - 1;
                                                            var unProductiveTimeBefore = getUnproductiveTime(prevIndex, drop.index);

                                                            let unProductiveTimeAfter;
                                                            if (filteredDrops.length === (i + 1) && (([40, 70].indexOf(route.status) === -1 || showFinishedDeliveries))) {
                                                                unProductiveTimeAfter = getUnproductiveTime(drop.index, drop.index + 1);
                                                            }
                                                            
                                                            return (
                                                                <div key={i}>
                                                                    { 
                                                                        ((drop.status <= 20 || showFinishedDeliveries) && unProductiveTimeBefore != null) ? 
                                                                            <Alert severity="info" className="timeline-item-content" style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <span style={{marginRight: '1rem'}}>
                                                                                        {t("SuggestedBreak")}: <b>{getFormattedUnproductiveTime(unProductiveTimeBefore.startTime, unProductiveTimeBefore.endTime)}</b>
                                                                                    </span>
                                                                                    <PauseButton visible={appParams.pauseAllowed && route.status === 30} />
                                                                                </div>
                                                                            </Alert> : 
                                                                            <></> 
                                                                    }
                                                                    <TimelineItem drop={drop} dropType={dropTypes.find(dropType => dropType.id === drop.dropType)} delay={route.delay}
                                                                        skipAllowed={appParams.skipAllowed} 
                                                                        useTimeRegistrations={appParams.timeRegistrations} 
                                                                        activeAtArrivalAndDepartureEnabled={appParams.activeAtArrivalAndDeparture}
                                                                        navigationApp={navigationApp}
                                                                        routeState={route.status}
                                                                        routeAutoStart={appParams.routeAutoStart}
                                                                        openRoutesCount={openRoutesCount}
                                                                        numberOfBusyDrops={busyDropsCount}
                                                                        autoAcceptTimeRegistrations={appParams.autoAcceptTimeRegistrations}
                                                                        params={appParams} />
                                                                    {
                                                                        // Display the suggested break also when it comes after the latest drop of the route
                                                                        unProductiveTimeAfter != null ?
                                                                            <Alert severity="info" className="timeline-item-content" style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <span style={{marginRight: '1rem'}}>
                                                                                        {t("SuggestedBreak")}: <b>{getFormattedUnproductiveTime(unProductiveTimeAfter.startTime, unProductiveTimeAfter.endTime)}</b>
                                                                                    </span>
                                                                                    <PauseButton visible={appParams.pauseAllowed && route.status === 30} />
                                                                                </div>
                                                                            </Alert> : 
                                                                            <></> 
                                                                    }
                                                                </div>
                                                            );
                                                        }
                                                    )
                                                }
                                                <TimelineDepot type={"stop"} 
                                                    location={route.stopLocation} 
                                                    eta={route.stopString} 
                                                    delay={route.delay}
                                                    navigationApp={navigationApp}
                                                    openRoutesCount={openRoutesCount}
                                                    numberOfBusyDrops={busyDropsCount} />
                                            </div>
                                        </div>
                                }
                            </>
                            <DataReloader />
                        </motion.div>
                    </TimelineProvider>
            }
        </div>
    );
}

export default Timeline;