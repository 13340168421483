import { Chip } from "@material-ui/core";

const ProjectType = ({ projectType }) => {

    return (
        projectType != null ?
            <Chip label={projectType.name} size="small" color="primary" variant="outlined" /> 
            : null
    );

};

export default ProjectType;