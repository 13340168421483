import { useState } from "react";
import db from "../../utils/dexie/driverappdb";
import { Avatar, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useApplicationParams } from "../../hooks/app-params";
import queries from "../../utils/dexie/queries";
import { useLiveQuery } from "dexie-react-hooks";
import { ExternalStatusRepository } from "../../utils/dexie/repositories/external-status-repository";

const ExternalStatus = ({ drop, readOnly = false }) => {
    const [t] = useTranslation();
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(drop?.externalStatusId);
    const appParams = useApplicationParams();

    const externalStatuses = useLiveQuery(() => ExternalStatusRepository.getAll());
    const selectedExternalStatus = useLiveQuery(() => selectedId != null ? ExternalStatusRepository.get(selectedId) : null, [selectedId]);

    const isReadOnly = readOnly || !appParams?.externalStatusManagement;

    const onClick = () => {
        setDialogIsOpen(true);
    };

    const handleSelectionChange = (event) => {
        let selectedId = event.target.value;
        setSelectedId(selectedId);
    }

    const handleDialogClose = (event, reason) => {
        setDialogIsOpen(false);
    }

    const handleSave = async () => {
        // Only update if a different value has been selected
        if (selectedExternalStatus == null || selectedId !== drop?.externalStatusId) {
            let now = new Date();
            let utc = now.getTime() - now.getTimezoneOffset() * 60000;
    
            let externalStatusUpdateAction = { 
                type: "DropExternalStatus", 
                key: parseInt(drop.id), 
                time: utc,
                externalStatusId: selectedId
            };
    
            await queries.isActionAllowed(externalStatusUpdateAction)
                .then(async () => await db.dropdata.update(drop.id, { externalStatusId: selectedId }))
                .then(async () => await db.actions.put(externalStatusUpdateAction))
                .finally(() => setDialogIsOpen(false));
        } else {
            setDialogIsOpen(false);
        }
    }

    return (
        (externalStatuses?.length > 0 && (!isReadOnly || selectedExternalStatus != null)) ?
            <>
                <Box style={{display: 'inline-flex', marginBottom: 0}}>
                    {
                        <Chip label={selectedExternalStatus != null ? (selectedExternalStatus.translatedTerm ?? selectedExternalStatus.name) : t('UpdateStatus')} 
                            size="small" variant="outlined" clickable={!isReadOnly} onClick={!isReadOnly ? onClick : null}
                            avatar={
                                !isReadOnly ?
                                    <Avatar style={{backgroundColor: selectedExternalStatus?.foregroundColor ?? '#FFF'}}>
                                        <FontAwesomeIcon color={selectedExternalStatus?.backgroundColor} icon={["fal", "pen"]} />
                                    </Avatar> : null
                            }
                            style={{
                                color: selectedExternalStatus?.foregroundColor,
                                backgroundColor: selectedExternalStatus?.backgroundColor ?? 'transparent'
                            }} 
                        />
                    }
                </Box>
                <Dialog open={dialogIsOpen} onClose={handleDialogClose}>
                    <DialogTitle>{t('UpdateStatus')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{t('UpdateStatusContent')}</DialogContentText>
                        <Select value={selectedId ?? ''} fullWidth onChange={handleSelectionChange}>
                            <MenuItem value=''><em>{t('SelectAValue')}</em></MenuItem>
                            {externalStatuses.map(option => <MenuItem key={option.id} value={option.id}>{option.translatedTerm ?? option.name}</MenuItem>)}
                        </Select>
                    </DialogContent>
                    <DialogActions style={{ textAlign: 'center' }}>
                        <Button color="primary" onClick={handleSave}>{t('Ok')}</Button>
                        <Button onClick={handleDialogClose}>{t('Cancel')}</Button>
                    </DialogActions>
                </Dialog>
            </> : null
    );
}

export default ExternalStatus;