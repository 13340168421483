import React, { useState, useEffect } from 'react';
import Loader from '../../../components/loader';
import DataManagementView from './DataManagementView';
import { useAppContext } from '../../../utils/contexts/appContext';
import { useTranslation } from 'react-i18next';
import db, { DriverAppDbMigrator } from '../../../utils/dexie/driverappdb';
import { useIsMounted } from '../../../hooks/is-mounted';

const DataManagementContainer = () => {

    const [, dispatch] = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [dbExists, setDbExists] = useState(false);
    const [actionsCount, setActionsCount] = useState(0);
    const [t] = useTranslation();
    const isMounted = useIsMounted();

    useEffect(() => {

        async function init() {
            dispatch({ type: 'changeHeader', header: { title: t("DataManagement"), child: true } });

            const dbExists = await DriverAppDbMigrator.exists();
            if (dbExists) {
                const numberOfActions = await db.actions.count();
                if (isMounted()) {
                    setDbExists(dbExists);
                    setActionsCount(numberOfActions);
                }
            }
            
            setIsLoading(false);
        };

        init();
        
    }, [dispatch, t, isMounted]);

    return (
        <div className="global-container">
            {
                isLoading ? 
                    <Loader /> :
                    <DataManagementView dbExists={dbExists} numberOfActionsToSync={actionsCount} />
            }
        </div>
    );

};

export default DataManagementContainer;