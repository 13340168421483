import { Skeleton } from "@material-ui/lab";
import PlanningTimelineItem from "../planning-timeline-item";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SkeletonPlanningItem = () => {
    return (
        <PlanningTimelineItem icon={<FontAwesomeIcon icon={["far", "circle"]} />}>
            <Skeleton variant="text" height={30} width={70} style={{marginBottom: '.5rem' }} />
            <Skeleton variant="rect" height={150} />
        </PlanningTimelineItem>
    );
};

export default SkeletonPlanningItem;