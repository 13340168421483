import { Button, ButtonGroup, Card, CardContent, CardHeader, makeStyles, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const useQuantityChangerStyles = makeStyles(theme => ({
    root: {
        textAlign: 'center',
        maxWidth: 200
    },
    header: {
        backgroundColor: theme.palette.primary.main,
        padding: 3
    },
    subHeader: {
        color: theme.palette.primary.contrastText,
    },
    quantityField: {
        maxWidth: 75
    },
    buttons: {
        marginBottom: 15
    }
}));

const QuantityChanger = ({ 
    label = '',
    value = 0,
    maxValue,
    minValue,
    onChange = (value) => {} 
}) => {
    const classes = useQuantityChangerStyles();
    const [quantity, setQuantity] = useState(0);

    useEffect(() => {
        setQuantity(value);
    }, [value]);

    const isNullOrEmpty = (text) => {
        return text === null || text.match(/^\s*$/) !== null;
    }

    /**
     * Handles a change of quantity.
     * 
     * @param {*} newValue The new value of the quantity. This will be a number if changed by the custom controls, but it will be a string
     *                     if this value has been updated using the HTML arrows.
     */
    const handleQuantityChange = (newValue) => {
        if (!isNaN(newValue)) {
            let updatedValue = parseInt(newValue);
            let minValueValid = typeof(minValue) != 'undefined' ? updatedValue >= minValue : true;
            let maxValueValid = typeof(maxValue) != 'undefined' ? updatedValue <= maxValue : true;

            if (minValueValid && maxValueValid) {
                setQuantity(updatedValue);
                onChange(updatedValue);
            }
        }
    };

    return (
        <Card className={classes.root}>
            {
                !isNullOrEmpty(label) ?
                    <CardHeader 
                        subheader={label.toUpperCase()} 
                        className={classes.header}
                        subheaderTypographyProps={{ 
                            className: classes.subHeader
                        }} /> :
                    <></>
            }
            <CardContent>
                <TextField type="number" value={quantity} onChange={(e) => handleQuantityChange(e.target.value)}
                    className={classes.quantityField} inputProps={{style: {textAlign: 'center'}}} />
            </CardContent>
            <ButtonGroup color="primary" size="small" className={classes.buttons}>
                <Button disabled={typeof(minValue) != 'undefined' ? quantity <= minValue : false} onClick={() => handleQuantityChange(quantity - 1)}>-</Button>
                <Button disabled={typeof(maxValue) != 'undefined' ? quantity >= maxValue : false} onClick={() => handleQuantityChange(quantity + 1)}>+</Button>
            </ButtonGroup>
        </Card>
    );

}

export default QuantityChanger;