import { Box, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useContext } from "react";
import { RoutePlanningContext } from "../../../../utils/contexts/route-planning-context";
import { useEffect } from "react";
import { RouteStatus } from "../../../../utils/enums/route-status";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function PlanningMessages() {
    const { route, openRoutesCount } = useContext(RoutePlanningContext);
    const [t] = useTranslation();

    const [warnings, setWarnings] = useState([]);

    useEffect(() => {
        let generatedWarnings = [];
        if (route != null && route.status !== RouteStatus.BUSY && openRoutesCount > 0) {
            generatedWarnings.push(t('OtherRouteInProgress'));
            setWarnings(generatedWarnings);
        }
    }, [route, openRoutesCount, t]);

    return (
        warnings.length > 0 ?
            <Box mt={2}>
                {warnings.map((w, i) => (
                    <Alert key={`planning-message-${i}`} severity="info">
                        <Typography variant="subtitle1">
                            <strong>{w}</strong>
                        </Typography>
                    </Alert>
                ))}
            </Box>
            : null
    );
}

export default PlanningMessages;