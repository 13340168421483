import { Box, Container, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.light
    },
    contentWrapper: {
        flexGrow: 1
    }
}));

const PageContainer = (props) => {
    const classes = useStyles();

    return (
        <Box flexGrow={1} display='flex' flexDirection='column' alignItems='center' justifyContent='center' className={classes.root}>
            <Container className={classes.contentWrapper}>
                {props.children}
            </Container>
        </Box>
    );
};

export default PageContainer;