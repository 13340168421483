import Dexie from 'dexie';
import relationships from 'dexie-relationships'
import { SettingsGroup } from '../enums/settings-group';
import queries from './queries';

const driverAppDbName = 'DriverAppDb';

const db = new Dexie(driverAppDbName, { addons: [relationships] });

/**
 * REMARK: Always keep the declarations of previous versions as long as there might be users having them running.
 */
db.version(1).stores({
    routes: 'id,index,name,status,date,startLocation->locations.id,start,startString,stopLocation->locations.id' +
                ',stop,stopString,distance,realStart,realStop,lastChangedByPlanner,dispatchedAt,lastSynchronized,dropsOpen,dropsTotal,delay',
    locations: 'id,lon,lat,name,address',
    routestates: 'id,name',
    dropstates: 'id,name',
    drops: 'id,index,realIndex,routeId->routes.id,status,eta,etaString,realETA,realETAString,location->locations.id,remark,driverRemark,tw,signature,lat,lon,signonglass,dropType->droptypes.id,projectId,projectPhaseId,projectType',
    droptypes: 'id,description,translatedTerm',
    projecttypes: 'id,name',
    settings: '++id,name,value',
    actions: '++id,type,key,time',
    logs: '++id,date,text,stacktrace',
    notifications: '++id,routesAdded,routesDeleted,dropsAdded,dropsDeleted',
    images: '++id,dropId->drops.id,blob,name,documentId',
    params: '++id,skipAllowed,timeRegistrations,pauseAllowed,time',
    rests: '++id,start,end',
    breaktypes: 'id,description,translatedTerm',
    syncs: 'id,time'
});

db.version(2).stores({
    datastore: 'key,value'
});

db.version(3).stores({
    drops: 'id,index,realIndex,routeId->routes.id,status,eta,etaString,realETA,realETAString,location->locations.id,remark,tw,signature,lat,lon,signonglass,dropType->droptypes.id,projectId,projectPhaseId,projectType',
    driverremarks: '++id,dropId -> drops.id,enabled,required,label,remark'
});

db.version(4).stores({
    drops: 'id,index,realIndex,routeId->routes.id,status,eta,etaString,realETA,realETAString,location->locations.id,' + 
                'remark,tw,signature,lat,lon,signonglass,dropType->droptypes.id,projectId,projectPhaseId,projectType,mainProjectId'
});

db.version(5).stores({
    locations: 'id,lon,lat,name,address,remark'
});

db.version(6).stores({
    drops: 'id,index,realIndex,routeId->routes.id,eta,etaString,realETA,realETAString,location->locations.id,' + 
                'remark,tw,signonglass,dropType->droptypes.id,projectId,projectPhaseId,projectType,mainProjectId',
    dropdata: 'dropId,status,signature,lat,lon'
}).upgrade((transaction) => {
    transaction.drops.toCollection()
        .each(async (drop) => {
            transaction.dropdata.put({
                dropId: drop.id,
                status: drop.status,
                signature: drop.signature,
                lat: drop.lat,
                lon: drop.lon
            });
        }).then(() => {
            return transaction.drops.toCollection()
                .modify(dropItem => {
                    delete dropItem.status;
                    delete dropItem.signature;
                    delete dropItem.lat;
                    delete dropItem.lon;
                });
        });
});

db.version(7).stores({
    actions: '++id,type,key,time,attempt'
});

db.version(8).upgrade(transaction => {
    transaction.routestates.put({
        id: 70, 
        name: "interrupted"
    });
});

db.version(9).stores({
    skipremarks: '++id,dropId -> drops.id,enabled,required,label,remark'
});

db.version(10).stores({
    breaktypes: 'id', // Scheme: id, description, translatedTerm
    datastore: 'key', // Scheme: key, value
    driverremarks: '++id,dropId->drops.id', // Scheme: ++id, dropId->drops.id, enabled, required, label, remark
    dropdata: 'dropId', // Scheme: dropId, status, signature, lat, lon
    drops: 'id,routeId->routes.id,location->locations.id,dropType->droptypes.id', // Scheme: id, index, realIndex, routeId->routes.id, eta, etaString, realETA, realETAString, location->locations.id, remark, tw, signonglass, dropType->droptypes.id, projectId, projectPhaseId, projectType, mainProjectId
    droptypes: 'id', // Scheme: id, description, translatedTerm
    images: '++id,dropId->drops.id', // Scheme: ++id, dropId->drops.id, blob, name, documentId
    locations: 'id', // Scheme: id, lon, lat, name, address
    logs: '++id', // Scheme: ++id, date, text, stacktrace
    notifications: '++id', // Scheme: ++id, routesAdded, routesDeleted, dropsAddded, dropsDeleted
    params: '++id', // Scheme: ++id, activeAtArrivalAndDeparture, dataManagementAllowed, extraTaskAllowed, pauseAllowed, routeAutoStart, selectModernisationProjectAllowed, skipAllowed, timeRegistrations
    rests: '++id', // Scheme: ++id, start, end
    routes: 'id,status,startLocation->locations.id,stopLocation->locations.id', // Scheme: id, index, name, status, date, startLocation->locations.id, start, startString, stopLocation->locations.id, stop, stopString, distance, realStart, realStop, lastChangedByPlanner, dispatchedAt, lastSynchronized, dropsOpen, dropsTotal, delay 
    routestates: 'id', // Scheme: id, name
    settings: '++id,name', // Scheme: ++id, name, value
    skipremarks: '++id,dropId->drops.id', // Scheme: ++id, dropId->drops.id, enabled, required, label, remark
    syncs: 'id', // Scheme: id, time
});

db.version(11).stores({
    deliveryorderdetails: 'id,dropId->drops.id', // Scheme: id, deliveryOrderId, articleId, articleName
    deliveryorderdetailsdata: 'deliveryOrderDetailId' // Scheme: deliveryOrderDetailId, quantity
});

db.version(12).stores({
    deliveryorderdetailsdata: 'deliveryOrderDetailId,dropId,[deliveryOrderDetailId+dropId]'
}).upgrade(transaction => {
    transaction.deliveryorderdetails.toArray()
        .then(details => {
            if (details != null && typeof(details) != undefined && details.length > 0) {
                transaction.deliveryorderdetailsdata.toArray(dataObjects => {
                    if (dataObjects != null && dataObjects.length > 0) {
                        for(var i = 0; i < dataObjects.length; i++) {
                            transaction.deliveryorderdetailsdata.delete(dataObjects[i].deliveryOrderDetailId)
                                .catch(error => console.error(error));
                        }
                    }
                });
            }
        })
        .catch(error => console.error(error));
});

db.version(13).stores({
    deliveryorderdetails: 'id,dropId->drops.id,[dropId+articleId]'
}).upgrade(transaction => {
    transaction.deliveryorderdetails.toArray()
        .then(details => {
            if (details != null && details.length > 0) {
                for(var i = 0; i < details.length; i++) {
                    transaction.deliveryorderdetails.delete(details[i].id)
                        .catch(error => console.error(error));
                }
            }
        });
});

db.version(14).stores({
    articles: 'id',
    articleimages: '++id,articleId->articles.id',
    articleinventorylevels: 'articleInventoryLevelId,articleId->articles.id'
});

db.version(15).stores({
    dropdetails: 'deliveryOrderDetailId,dropId->drops.id,articleId->articles.id,[dropId+articleId]',
    dropdetailsdata: '++id,dropId,deliveryOrderDetailId->dropdetails.deliveryOrderDetailId,[deliveryOrderDetailId+dropId],articleId->articles.id,createdLocally,[dropId+createdLocally]',
    returnables: 'id,dropId',
    returnablesdata: '[dropId+returnableId],dropId->drops.id,returnableId->returnables.id'
});

db.version(16).stores({
    feedbackitems: 'key',
    feedback: '[dropId+labelId],dropId->drops.id'
});

db.version(17)

db.version(18).stores({
    dropdetailsdata: '++id,dropId,deliveryOrderDetailId->dropdetails.deliveryOrderDetailId,[deliveryOrderDetailId+dropId],articleId->articles.id,createdLocally,[dropId+createdLocally],[dropId+articleId]',
});

db.version(19).stores({
    languages: 'languageId,abbreviation'
});

db.version(20).stores({
    positions: '++id,timestamp'
});

db.version(21).stores({
    externalstatuses: 'id'
});

db.version(22).stores({
    routeplans: 'id,id->routes.id',
    routeplanitemdata: '[routePlanId+type+payloadId],[routePlanId+status],routePlanId->routeplans.id,status',
    bufferslots: 'id,routeId->routes.id,locationId->locations.id',
    unproductivetimes: '[routeId+id],routeId->routes.id',
    rests: '++id,[routeId+payloadId],routeId->routes.id',
    settings: '++id,name,group'
})
.upgrade(transaction => {
    transaction.settings.toArray()
        .then(settings => {
            if (settings != null && settings.length > 0) {
                let oldDisplaySettings = settings.filter(s => s.name === "ShowFinishedDeliveries");
                for(var i = 0; i < oldDisplaySettings.length; i++) {
                    oldDisplaySettings[i].group = SettingsGroup.DISPLAY_SETTINGS;
                    transaction.settings.put(oldDisplaySettings[i])
                        .catch(error => console.error(error));
                }
            }
        });
});

db.version(23).stores({
    // [deliveryOrderDetailId+simulationDeliveryDetailId] should be a better primary key, but one of them is always nullable and that's not valid as indexable primary key, so we need a string-representation of it (= idHash).
    dropdetails2: 'idHash,dropId,deliveryOrderDetailId,simulationDeliveryDetailId,articleId->articles.id,[dropId+articleId]',
    dropdetailsdata2: '++id,dropId,deliveryOrderDetailId->dropdetails2.deliveryOrderDetailId,[deliveryOrderDetailId+dropId],[simulationDeliveryDetailId+dropId],articleId->articles.id,createdLocally,[dropId+createdLocally],[dropId+articleId]',
});

db.version(24).stores({})
    .upgrade(transaction => {
        transaction.projecttypes.clear();
        transaction.routestates.clear();
        transaction.dropstates.clear();
        transaction.droptypes.clear();
    });

/**
 * Hook into the populate event to populate the database with initial data.
 */
db.on('populate', function (transaction) {
    // Settings
    transaction.settings.put({ name: "NavigationApp", value: "google" });
    transaction.settings.put({ name: "ShowFinishedDeliveries", value: 0, group: SettingsGroup.DISPLAY_SETTINGS });
    transaction.settings.put({ name: "ShowFinishedBufferSlots", value: 0, group: SettingsGroup.DISPLAY_SETTINGS });
    transaction.settings.put({ name: "ShowFinishedUnproductiveTimes", value: 0, group: SettingsGroup.DISPLAY_SETTINGS });

    // Params
    transaction.params.put({ 
        skipAllowed: 0, 
        timeRegistrations: 0, 
        pauseAllowed: 0, 
        extraTaskAllowed: 0 
    });

    // Syncs
    transaction.syncs.put({ id: db.params.name, time: 0 });

    queries.addLogMessage("[DRIVERAPP - INIT] The database of the DriverApp has been initialized and the initial import has been finished.");
});

export const DriverAppDbMigrator = {

    exists: async function () {
        return await Dexie.exists(driverAppDbName);
    },

    deleteDatabase: function () {
        return Dexie.delete(driverAppDbName);
    },

    open: async function () {
        if (!db.isOpen()) {
            // Maybe for later: to delete all tables on upgrade, we can take a look at: https://github.com/dexie/Dexie.js/issues/349
            db.open();
        }
    }

};

export default db;
