export const Actions = {
    BUFFERSLOT_START: 'BUFFERSLOT_START',
    BUFFERSLOT_STOP: 'BUFFERSLOT_STOP',
    DROP_START: 'DROP_START',
    DEPRECATED_DROP_START: 'DropStart',
    UNPRODUCTIVETIME_START: 'UNPRODUCTIVETIME_START',
    UNPRODUCTIVETIME_STOP: 'UNPRODUCTIVETIME_STOP',
    PAUSE_START: 'PAUSE_START',
    DEPRECATED_PAUSE_START: 'PauseStart',
    PAUSE_STOP: 'PAUSE_STOP',
    DEPRECATED_PAUSE_STOP: 'PauseEnd'
};