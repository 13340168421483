import { useContext, useEffect } from "react";
import { RoutePlanningContext } from "../../../utils/contexts/route-planning-context";
import PlanningFinished from "./components/planning-finished";
import PageContainer from "../../../components/page-container";
import PlanningDetails from "./components/planning-details";
import PlanningTimeline from "./components/planning-timeline";
import { Box, Grid } from "@material-ui/core";
import { AppContext } from "../../../utils/contexts/appContext";

const PlanningView = () => {
    const [, dispatchApp] = useContext(AppContext);
    const { route, showFinishedItems } = useContext(RoutePlanningContext);

    useEffect(() => {
        dispatchApp({ 
            type: 'changeHeader', 
            header: { 
                title: route?.name, 
                child: true, 
                path: '/routes' 
            } 
        });
    }, [route?.name, dispatchApp]);

    return (
        <PageContainer>
            {
                route?.status === 40 && !showFinishedItems ? 
                    <PlanningFinished /> : 
                    <Box my={5}>
                        <Grid container>
                            <Grid item xs={12} container justify="center">
                                <Grid item xs={10} sm={8} md={4}>
                                    <PlanningDetails />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <PlanningTimeline />
                            </Grid>
                        </Grid>
                    </Box>
            }
        </PageContainer>
    );
}

export default PlanningView;