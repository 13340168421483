import { createMuiTheme } from "@material-ui/core";
import hexToRgba from 'hex-to-rgba';

export const primaryColor = {
    light: '#73C9FF',
    main: '#3498DB',
    dark: '#006AA9',
    contrastText: '#FFF'
};

export const secondaryColor = {
    light: '#f6f9fd',
    main: '#2f4163',
    dark: '#001B39',
    contrastText: '#FFF'
};

export const successColor = {
    main: '#008000' 
};

export const errorColor = {
    main: '#FF0000'
};

export const textColor = {
    primary: '#6C6C6C'
};

const highlightedColor = {
    main: '#2ade8b'
};

const bufferSlotColor = {
    main: '#D8B08C',
    contrastText: 'white'
};

const unproductiveTimeColor = {
    main: '#F2A71B',
    contrastText: 'white'
};

const theme = createMuiTheme({
    palette: {
        primary: primaryColor,
        secondary: secondaryColor,
        success: successColor,
        error: errorColor,
        text: textColor,
        highlighted: highlightedColor,
        bufferSlot: bufferSlotColor,
        unproductiveTime: unproductiveTimeColor
    },
    typography: {
        fontFamily: ['Assistant']
    },
    spacing: factor => `${0.25 * factor}rem`,
    overrides: {
        MuiToolbar: {
            root: {
                color: secondaryColor.contrastText,
                backgroundColor: secondaryColor.dark,
                
                '& *': {
                    fontFamily: "Roboto",
                    fontWeight: 500,
                    letterSpacing: "0.0075em"
                }
            }
        },
        MuiDrawer: {
            paper: {
                backgroundColor: secondaryColor.dark,
                width: '240px',

                '& *': {
                    color: secondaryColor.contrastText
                },

                '& a': {
                    display: 'inline-flex',
                    width: '100%'
                },

                '& a > *': {
                    alignSelf: 'center'
                }
            }
        },
        MuiDivider: {
            root: {
                margin: '10px 0'
            },
            light: {
                backgroundColor: hexToRgba(secondaryColor.contrastText, .12)
            }
        },
        MuiTypography: {
            body1: {
                '& > svg': {
                    marginTop: 5,
                    marginRight: 10
                }
            },
            h6: {
                fontWeight: 700
            }
        },
        MuiAccordionSummary: {
            root: {
                backgroundColor: 'rgb(234, 238, 243)',
            }
        },
        MuiAccordionDetails: {
            root: {
                backgroundColor: 'rgba(234, 238, 243, .4)'
            }
        },
        MuiAccordionActions: {
            root: {
                backgroundColor: 'rgba(234, 238, 243, .4)'
            }
        },
        MuiInputBase: {
            root: {
                backgroundColor: '#FFF',
                zIndex: 0
            }
        },
        MuiInputLabel: {
            root: {
                backgroundColor: 'transparent',
                zIndex: 1
            }
        },
        MuiSnackbar: {
            root: {
                '&.notification-changes-detected, &.notification-flex': {
                    '& .MuiSnackbarContent-action': {
                        order: 1
                    },
                    '& .MuiSnackbarContent-message': {
                        order: 2,
                        width: '100%',
                        paddingTop: 0
                    }
                }
            }
        }
    }
});

export default theme;