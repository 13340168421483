import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import db from '../../utils/dexie/driverappdb';
import FeedbackDropdown from './FeedbackDropdown';
import queries from '../../utils/dexie/queries';

/**
 * Wrapper-component to make it in the future possible to use different field-types for feedback.
 */
const FeedbackItem = forwardRef((props, ref) => {

    return (
        <FeedbackDropdown ref={ref} {...props} />
    );

});

const FeedbackView = forwardRef(({ dropId, items, feedback, parentId, onRequiredChange }, ref) => {

    const [filteredItems, setFilteredItems] = useState([]);
    const [feedbackItemRefs, setFeedbackItemRefs] = useState([]);

    useEffect(() => {
        setFilteredItems(items?.filter(item => item.feedbackOptions != null && item.feedbackOptions.length > 0));

        return () => {
            setFeedbackItemRefs([]);
        };
    }, [items, feedback]);

    const getSelectedValues = (parentIds) => {
        let value = [];
            
        feedbackItemRefs.forEach((feedbackItemRef, index) => {
            value[index] = feedbackItemRef.getValue(parentIds);
        });

        return value.flat();
    };

    useImperativeHandle(ref, () => ({

        validate: (silenceMode) => {
            let validationResult = true;
            feedbackItemRefs.forEach(feedbackItemRef => {
                if (feedbackItemRef != null) {
                    let result = feedbackItemRef.validate(silenceMode);
                    if (!result)
                        validationResult = result;
                }
            });

            return validationResult;
        },
        getValue: (parentIds) =>  getSelectedValues(parentIds),
        save: async () => {
            let values = getSelectedValues();
            await db.feedback.where({dropId: dropId}).delete()
                .then(() => db.feedback.bulkPut(
                        values.map(v => ({ 
                            dropId: dropId, 
                            ...v 
                            }
                        ))
                    )
                )
                .catch(e => {
                    queries.addErrorToLogs(e, ['depannage', 'feedback']);
                    throw e;
                });
        }

    }));

    return (
        <>
        {
            filteredItems?.map((item, index) => {
                const label = item.feedbackLabel;
                // The key needs to be unique across all the feedback items on different levels
                const key = `feedback-level_${label.level}-parent_${parentId}-${label.feedbackLabelId}`;
                return (<FeedbackItem key={key} uid={key} label={item.feedbackLabel} options={item.feedbackOptions} feedback={feedback} onRequiredChange={onRequiredChange} ref={(ref) => {
                    setFeedbackItemRefs(currentRefs => {
                        if (ref != null && currentRefs != null) {
                            currentRefs[index] = ref;
                        }
                        return currentRefs;
                    });
                }} />);
            })
        }
        </>
    );

});

export default FeedbackView;