import { Typography, useTheme } from "@material-ui/core";
import PlanningTimelineItem from "../planning-timeline-item"
import PlanningItemContent from "../planning-item-content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PlanningItemBody from "../planning-item-body";

const Depot = ({ children, location, header }) => {
    const theme = useTheme();

    return (
        <PlanningTimelineItem icon={<FontAwesomeIcon icon={["far", "user-circle"]} />} iconColor={theme.palette.success.main}>
            {header}
            <PlanningItemBody>
                <PlanningItemContent>
                    <Typography variant="h6">{location?.name}</Typography>
                    <Typography variant="body1">{location?.address}</Typography>
                </PlanningItemContent>
                {children}
            </PlanningItemBody>
        </PlanningTimelineItem>
    );
}

export default Depot;