import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../../utils/contexts/appContext';
import { useParams } from "react-router-dom";
import db from '../../../utils/dexie/driverappdb';
import { motion } from "framer-motion";
import './Navigation.css';

import { FormControl, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';

const pageVariants = {
    initial: {
        opacity: 0,
        x: "100vh"
    },
    in: {
        opacity: 1,
        x: 0
    },
    out: {
        opacity: 0,
        x: "100vh"
    }
};

const pageTransition = {
    duration: 0.2
};

function Navigation() {
    const [, dispatch] = useAppContext();
    let { id, value } = useParams();
    const [radioValue, setRadioValue] = useState(value);

    const handleChange = (event) => {
        db.settings.update(parseInt(id), { value: event.target.value });
        setRadioValue(event.target.value);
    }

    useEffect(() => {
        dispatch({ type: 'changeHeader', header: { title: 'Navigatie', child: true } });
    }, [dispatch]);

    return (
        <motion.div className="settings-container" initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
            <div className="global-container">
                <FormControl component="fieldset">
                    <RadioGroup aria-label="navigation" name="navigation" value={radioValue} onChange={handleChange}>
                        <FormControlLabel value="google" control={<Radio color="primary" />} label="Google" />
                        <FormControlLabel value="here" control={<Radio color="primary" />} label="Here WeGo" />
                        <FormControlLabel value="waze" control={<Radio color="primary" />} label="Waze" />
                    </RadioGroup>
                </FormControl>
            </div>
        </motion.div>
    );
}

export default Navigation;