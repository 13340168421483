import { CardContent } from "@material-ui/core";

const PlanningItemContent = ({children, translucent, ...otherProps}) => {

    return (
        <CardContent style={{opacity: translucent ? .6 : 1}} {...otherProps}>
            {children}
        </CardContent>
    );
}

export default PlanningItemContent;