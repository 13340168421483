import React, { useState, useEffect, useCallback, useContext } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, CardActionArea, CardActions, Button, Typography, Switch, FormControlLabel, FormControl } from '@material-ui/core';
import { fetchOfflineData } from '../../../utils/dexie/sync';
import { AuthContext } from '../../../providers/AuthProvider';
import { useSnackbar } from 'notistack';
import queries from '../../../utils/dexie/queries';
import { DriverAppDbMigrator } from '../../../utils/dexie/driverappdb';

const DataManagementView = ({ dbExists, numberOfActionsToSync }) => {

    const [dbStyle, setDbStyle] = useState({});
    const [dbInitialized, setDbInitialized] = useState(dbExists);
    const [agreeWithDataLossEnabled, setAgreeWithDataLossEnabled] = useState(numberOfActionsToSync > 0);
    const [agreeWithDataLoss, setAgreeWithDataLoss] = useState(false);
    const [t] = useTranslation();
    const auth = useContext(AuthContext);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const setDbIcon = useCallback((initialized) => {
        setDbStyle({
            '--fa-primary-color': initialized ? '#27ae60' : '#c0392b',
            '--fa-secondary-color': initialized ? '#27ae60' : '#c0392b'
        });
    }, []);

    useEffect(() => setDbIcon(dbExists), [dbExists, setDbIcon]);

    const handleDelete = () => {
        return DriverAppDbMigrator.deleteDatabase()
            .then(async () => {
                // The database has been successfully deleted
                setDbInitialized(false);
                setDbIcon(false);
                setAgreeWithDataLossEnabled(false)
            })
            .catch(err => console.error("Could not delete the database: ", err));
    };

    const closeNotificationAction = useCallback((key) => {
        return <FontAwesomeIcon icon={["fal", "times"]} 
                    onClick={() => { closeSnackbar(key); }} style={{ marginRight: 10 }} />;
    }, [closeSnackbar]);

    const resetData = async () => {
        return await handleDelete()
            .then(() => DriverAppDbMigrator.open()) // Create a new instance of the database
            .then(async () => {
                const initialized = await DriverAppDbMigrator.exists();
                if (initialized) {
                    // Database has been successfully deleted and recreated, so we can add log mesages again from now on
                    queries.addLogMessage("[DRIVERAPP - DATA] The database of the DriverApp has been deleted on behalf of a manual request.");
                    
                    // Fetch the offline data
                    await fetchOfflineData(auth);

                    setDbInitialized(initialized);
                    setDbIcon(initialized);

                    // Show notification
                    enqueueSnackbar(t('LocalDataRemoved'), {
                        variant: 'success',
                        action: (key) => closeNotificationAction(key),
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        },
                        autoHideDuration: 5000
                    });
                }
            });
    }

    const handleAgreement = (event) => {
        setAgreeWithDataLoss(event.target.checked);
    }

    return (
        <motion.div className="center" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div className="global-container">
                <Card>
                    <CardActionArea>
                        <CardContent>
                            <div className="padding-bottom-10">
                                <FontAwesomeIcon icon={["fad", "database"]} style={dbStyle} size="4x" />
                            </div>
                            <Typography variant="h5" component="h5" gutterBottom>{t("LocalData")}</Typography>
                            {
                                agreeWithDataLossEnabled ?
                                    <div style={{ marginTop: 20 }}>
                                        <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} size="2x" style={{ color: 'orange' }} />
                                        <p>{t("LocalDataLossWarning")}</p>
                                        <FormControl>
                                            <FormControlLabel 
                                                control={<Switch color="secondary" checked={agreeWithDataLoss} onChange={handleAgreement} />}
                                                label={t("IAgree")}
                                                labelPlacement="start" />
                                        </FormControl>
                                    </div>: <></>
                            }
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <Button variant="contained" style={{ backgroundColor: "#e74c3c", color: "#fff" }} fullWidth 
                            disabled={!dbInitialized || (agreeWithDataLossEnabled && !agreeWithDataLoss)} onClick={resetData}>
                            {t("Delete")}
                        </Button>
                    </CardActions>
                </Card>
            </div>
        </motion.div>
    );

};

export default DataManagementView;