import i18next from 'i18next';
import React, { createContext, useContext, useReducer } from 'react';

export const AppContext = createContext();

export const initialState = {
    language: i18next.language,
    header: { title: 'Movetex', child: false, path: undefined, onNavigation: undefined },
    pause: false,
    pauseId: null, // Optional identifier of the planning item which has introcuded the pause
    drawerOpen: false,
    changesDetected: false,
    executingPlanningItem: null
};

export const reducer = (state, action) => {
    switch (action.type) {
        case 'loaded':
            return {
                ...state,
                executingPlanningItem: action.executingPlanningItem
            };
        case 'changeLanguage':
            return {
                ...state,
                language: action.language
            };
        case 'changeHeader':
            return {
                ...state,
                header: {
                    title: action.header.title,
                    child: action.header.child,
                    path: action.header.path,
                    onNavigation: action.header.onNavigation
                }
            };
        case 'toggleDrawer':
            return {
                ...state,
                drawerOpen: !state.drawerOpen
            };
        case 'togglePause':
            return {
                ...state,
                pause: action.pause
            };
        case 'togglePauseByPlanning':
            return {
                ...state,
                pause: action.pause,
                pauseId: action.pause ? action.pauseId : null
            };
        case 'changesDetection':
            return {
                ...state,
                changesDetected: action.changesDetected
            };
        case 'planningitem.execution':
            return {
                ...state,
                executingPlanningItem: action.executingPlanningItem
            };
        default:
            return state;
    }
};

export const AppProvider = ({ children }) => (
    <AppContext.Provider value={useReducer(reducer, initialState)}>
        {children}
    </AppContext.Provider>
);

export const useAppContext = () => useContext(AppContext); 