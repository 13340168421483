import { Typography, Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Wrapper-component to make it in the future possible to use different field-types for feedback.
 */
 const ReadOnlyFeedbackItem = ({ label, options }) => {
    const [value, setValue] = useState('');
    const [underlyingFeedbackItems, setUnderlyingFeedbackItems] = useState([]);

    useEffect(() => {
        if (options != null ) {
            // This component is currently only used to display the feedback of historical data. 
            // Because of the server-logic behind the network-call, we can assume that the first item is always the item we need to show.
            var selectedOption = options.length > 0 ? options[0] : null;
            if (selectedOption != null) {
                setValue(selectedOption);
                setUnderlyingFeedbackItems(selectedOption.underlyingFeedbackLabelsAndOptions);
            }
        }

        return () => {
            setUnderlyingFeedbackItems([]);
        };
    }, [label, options]);

    return (
        <>
            <Box style={{marginBottom: 15}}>
                <Typography variant="subtitle1">{label.description}:</Typography>
                <Typography>{value.description}</Typography>
            </Box>
            { 
                underlyingFeedbackItems && underlyingFeedbackItems.length > 0 ? 
                    <FeedbackReadOnlyView parentId={value.feedbackOptionId} items={underlyingFeedbackItems} /> : <></>
            }
        </>
    );

};

const FeedbackReadOnlyView = ({ items, parentId }) => {

    const [t] = useTranslation();
    const [filteredItems, setFilteredItems] = useState([]);

    useEffect(() => {
        setFilteredItems(items?.filter(item => item.feedbackOptions != null && item.feedbackOptions.length > 0));
    }, [items]);

    return (
        <>
        {
            (!filteredItems || filteredItems.length === 0) ?
                <Typography gutterBottom>
                    {t("NoFeedbackAvailable")}
                </Typography> :
                <>
                    {
                        filteredItems?.map((item, index) => {
                            const label = item.feedbackLabel;
                            // The key needs to be unique across all the feedback items on different levels
                            const key = `feedback-level_${label.level}-parent_${parentId}-${label.feedbackLabelId}`;
                            return (<ReadOnlyFeedbackItem key={key} uid={key} label={item.feedbackLabel} options={item.feedbackOptions} />);
                        })
                    }
                </>
        }
        </>
    );

};

export default FeedbackReadOnlyView;