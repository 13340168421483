import { useCallback, useContext, useEffect, useState } from "react";
import { RoutePlanningContext } from "../../../../utils/contexts/route-planning-context";
import DropPlanningItem from "./planning-items/drop-item";
import { Timeline } from "@material-ui/lab";
import StartDepot from "./depots/start-depot";
import StopDepot from "./depots/stop-depot";
import { PlanningItemType } from "../../../../utils/enums/planning-item-type";
import { RouteStatus } from "../../../../utils/enums/route-status";
import BufferSlotItem from "./planning-items/bufferslot-item";
import { AppContext } from "../../../../utils/contexts/appContext";
import UnproductiveTimeItem from "./planning-items/unproductive-time-item";
import SkeletonPlanningItem from "./planning-items/skeleton-item";
import { useIsMounted } from "../../../../hooks/is-mounted";
import { PlanningItemStatus } from "../../../../utils/enums/planning-item-status";

const PlanningTimeline = () => {
    const [{pause, pauseId}] = useContext(AppContext);
    const {
        routeId, route, routeAutoStart, filteredPlanningItems, showFinishedItems, 
        startLocation, stopLocation, openRoutesCount, planningItems
    } = useContext(RoutePlanningContext);
    const isMounted = useIsMounted();

    const [skeletonEnabled, setSkeletonEnabled] = useState(true);
    const [planningItemComponents, setPlanningItemComponents] = useState([]);

    /**
     * Item is disabled if one of the following conditions are met:
     * - The app is paused
     * - There is at least 1 OTHER route which is already busy
     * - This route has been finished
     * - This route has been interrupted AND autostart of a route is disabled
     * - This route has not been started AND autostart of a route is disabled
     * - This route has at least 1 OTHER plan-item which is already busy
     */
    const detectReadOnlyModus = useCallback(async (planningItem) => {
        // Checks whether one of the other plan-items of the same route-plan is busy.
        let hasDifferentBusyPlanItem = planningItems
            .filter(item => item.status === PlanningItemStatus.IN_PROGRESS && 
                !(item.type === planningItem.type && item.payloadId === planningItem.payloadId)
            ).length > 0;

        let pausedByMe = planningItem.type === PlanningItemType.UNPRODUCTIVE_TIME && pauseId === planningItem.payloadId;
        let readOnlyModus = (pause && !pausedByMe) || (openRoutesCount > 0 && route.status !== RouteStatus.BUSY) || route.status === RouteStatus.FINISHED || (route.status === RouteStatus.INTERRUPTED && !routeAutoStart) || 
            (route.status < RouteStatus.BUSY && !routeAutoStart) || hasDifferentBusyPlanItem;
        return {planningItem, readOnlyModus};
    }, [openRoutesCount, pause, pauseId, planningItems, route?.status, routeAutoStart]);

    useEffect(() => {
        async function init() {
            let sortedPlanItems = (filteredPlanningItems ?? [])
                .sort((a, b) => a.planIndex - b.planIndex);

            let data = await Promise.all(sortedPlanItems.map(item => detectReadOnlyModus(item)));
            let components = data.map(result => {
                const { planningItem, readOnlyModus } = result;
                switch (planningItem.type) {
                    case PlanningItemType.DROP:
                        return <DropPlanningItem key={planningItem.hashCode} planningItem={planningItem} readOnly={readOnlyModus} />;
                    case PlanningItemType.BUFFERSLOT:
                        return <BufferSlotItem key={planningItem.hashCode} planningItem={planningItem} readOnly={readOnlyModus} />;
                    case PlanningItemType.UNPRODUCTIVE_TIME:
                        return <UnproductiveTimeItem key={planningItem.hashCode} planningItem={planningItem} readOnly={readOnlyModus} />;
                    default:
                        return null;
                }
            });

            if (isMounted()) {
                setPlanningItemComponents(components);
                setSkeletonEnabled(false);
            }
        };

        init();
    }, [routeId, filteredPlanningItems, pause, pauseId, openRoutesCount, route?.status, routeAutoStart, isMounted, detectReadOnlyModus]);

    return (
        <Timeline align="left">
            {
                (!routeAutoStart && (route?.status < RouteStatus.BUSY || route?.status === RouteStatus.INTERRUPTED || showFinishedItems)) ? 
                    <StartDepot location={startLocation} />
                    : null
            }
            {
                skeletonEnabled ? 
                    Array.from({ length: 5 }).map((val, index) => <SkeletonPlanningItem key={index} />) : 
                    planningItemComponents
            }
            {
                (route?.status !== RouteStatus.FINISHED || showFinishedItems) ? 
                    <StopDepot location={stopLocation} /> 
                    : null 
            }
        </Timeline>
    );
}

export default PlanningTimeline;