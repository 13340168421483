import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, Select, MenuItem, MobileStepper, Button, useTheme, Card, CardContent, Typography, makeStyles, CardActions, Tabs, Tab, Box, List, ListItem, ListItemText } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FontAwesomeDuoToneStyle } from '../../fontawesome';
import PropTypes from 'prop-types';
import RemarksOverview from '../remarksOverview/RemarksOverview';
import { ReadOnlyFeedback } from '../feedback';

const useStyles = makeStyles(theme => ({
    historyItemWrapper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5)
    },
    cardHeader: {
        marginBottom: theme.spacing(2)
    }
}));

const HistoryItemCard = ({ historyItem }) => {
    const [t] = useTranslation();
    const [item, setItem] = useState();
    const [activeTab, setActiveTab] = useState(0);
    const classes = useStyles();

    useEffect(() => {
        if (historyItem) {
            setItem(historyItem);
        }
    }, [historyItem]);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
    
        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box p={3}>{children}</Box>}
            </Typography>
        );
    }
    
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    return (
        <div>
            {
                item &&
                    <Card variant="outlined">
                        <CardContent>
                            <Box className={classes.cardHeader}>
                                <Typography gutterBottom>
                                    {item.status.toUpperCase()}
                                </Typography>
                                <Typography variant="h5" component="h2" gutterBottom>
                                    {item.description}
                                </Typography>
                            </Box>
                            <TabPanel value={activeTab} index={0}>
                                {
                                    (item.projectExecutorsInformation && item.projectExecutorsInformation.length > 0) &&
                                        <List>
                                            {
                                                item.projectExecutorsInformation.map((info, index) => (
                                                    <ListItem key={index}>
                                                        <ListItemText primary={info.driverName} secondary={new Date(info.date).toLocaleDateString()} />
                                                    </ListItem>
                                                ))
                                            }
                                        </List> 
                                }
                            </TabPanel>
                            <TabPanel value={activeTab} index={1}>
                                <RemarksOverview remarks={item.driverRemarks} enabledOnly={false} />
                            </TabPanel>
                            <TabPanel value={activeTab} index={2}>
                                <Typography variant='h6'>
                                    {t('Feedback')}
                                </Typography>
                                <Box style={{marginTop: 15, marginLeft: 15}}>
                                    <ReadOnlyFeedback items={item.feedback} feedback={item.feedback != null && item.feedback.length > 0 ? item.feedback[0] : null} />
                                </Box>
                            </TabPanel>
                        </CardContent>
                        <CardActions>
                            <Tabs 
                                value={activeTab} 
                                indicatorColor="primary"
                                onChange={(event, newTab) => setActiveTab(newTab)}>
                                <Tab icon={<FontAwesomeIcon icon={["fad", "clock"]} size="lg" style={FontAwesomeDuoToneStyle} />}></Tab>
                                <Tab icon={<FontAwesomeIcon icon={["fad", "comment-alt-lines"]} size="lg" style={FontAwesomeDuoToneStyle} />}></Tab>
                                <Tab icon={<FontAwesomeIcon icon={["fad", "sitemap"]} size="lg" style={FontAwesomeDuoToneStyle} />}></Tab>
                            </Tabs>
                        </CardActions>
                    </Card>
            }
        </div>
    );
}

const ProjectTypeHistoryList = ({ projectTypeHistory }) => {
    const [items, setItems] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const theme = useTheme();
    const [t,] = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        setCurrentIndex(0); // Make sure we always reset the index
        if (projectTypeHistory && projectTypeHistory.projectExecutionInformation) {
            setItems(projectTypeHistory.projectExecutionInformation);
        }
    }, [projectTypeHistory]);

    const handleNext = () => {
        setCurrentIndex(previousIndex => previousIndex + 1);
    }

    const handlePrevious = () => {
        setCurrentIndex(previousIndex => previousIndex - 1);
    }

    return (
        <div>
            {
                items.length > 0 && 
                    <div className={classes.historyItemWrapper}>
                        <HistoryItemCard historyItem={items[currentIndex]} />
                        <MobileStepper
                            steps={items.length}
                            position="bottom"
                            variant="text"
                            activeStep={currentIndex}
                            nextButton={
                                <Button size="small" disabled={currentIndex === items.length - 1} onClick={handleNext}>
                                    {t("Next")}
                                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                </Button>
                            }
                            backButton={
                                <Button size="small" disabled={currentIndex === 0} onClick={handlePrevious}>
                                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                    {t("Previous")}
                                </Button>
                            }>
                            
                        </MobileStepper>
                    </div>
            }
        </div>
    );
}

const HistoryView = ({ projectHistory }) => {
    const [history, setHistory] = useState([]);
    const [t,] = useTranslation();
    const [projectTypeIndex, setProjectTypeIndex] = useState(0);

    useEffect(() => {
        if (projectHistory) {
            let sortedHistoryData = projectHistory.sort((a, b) => a.projectType.localeCompare(b.projectType));
            setHistory(sortedHistoryData);
        }
    }, [projectHistory]);

    return (
        <div>
            {
                history.length <= 0 ? 
                    t("NoHistoryAvailable") :
                    <>
                        <FormControl variant="outlined" style={{ width: '100%' }}>
                            <InputLabel>{t("ProjectType")}</InputLabel>
                            <Select 
                                label={t("ProjectType")} 
                                value={projectTypeIndex} 
                                onChange={event => setProjectTypeIndex(event.target.value)}>
                                {
                                    history.map((historyItem, index) => (
                                        <MenuItem key={historyItem.projectType} value={index}>
                                            {historyItem.projectType}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <ProjectTypeHistoryList projectTypeHistory={history[projectTypeIndex]} />
                    </>
            }
        </div>
    );

};

export default HistoryView;