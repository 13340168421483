import React from 'react';
import { motion } from 'framer-motion';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { UserAgentContext } from '../../../providers/UserAgentProvider';

const SystemView = ({ quota, usage, usageDetails }) => {
    
    const userAgent = useContext(UserAgentContext);
    const [t] = useTranslation();

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0)
            return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes)/Math.log(k));
        return parseFloat((bytes/Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const getOperatingSystemVersion = () => {
        let os = userAgent.getOperatingSystem();
        
        if (os === 'Android OS') {
            let ua = navigator.userAgent.toLowerCase();
            var androidMatch = ua.match(/android\s([0-9.]*)/i);
            return `${os}: ${androidMatch[1]}`;
        }

        return os;
    }

    return (
        <motion.div className="center" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div className="global-container">
                <List>
                    <ListItem>
                        <ListItemText primary={t("OperatingSystem")} primaryTypographyProps={{ variant: 'subtitle1' }} secondary={`${getOperatingSystemVersion()}`} />
                    </ListItem>
                    {
                        userAgent.isFeatureSupported('settings_system_memory_usage') ?
                            <>
                                <ListItem>
                                    <ListItemText primary={t("MemoryUsage")} primaryTypographyProps={{ variant: 'subtitle1' }} secondary={`${formatBytes(usage)} used out of ${formatBytes(quota)} storage quota.`} />
                                </ListItem>
                                {
                                    usageDetails && Object.keys(usageDetails).length > 0 ?
                                        Object.keys(usageDetails).map(key => (
                                            <ListItem key={key}>
                                                <ListItemText primary={key} primaryTypographyProps={{ variant: 'caption' }} 
                                                    secondary={formatBytes(usageDetails[key])} style={{ paddingLeft: 20 }} />
                                            </ListItem>)) : <></>
                                }
                            </> : <></>
                    }
                </List>
            </div>
        </motion.div>
    );

};

export default SystemView;