import { library } from '@fortawesome/fontawesome-svg-core';
import { errorColor, primaryColor, secondaryColor } from './themes/litefleet';
import hexToRgba from 'hex-to-rgba';

import { 
    faRoute as faRouteSolid,
    faCog,
    faUserCog,
    faSignOutAlt,
    faCheck as faCheckSolid,
    faQuestionCircle,
    faExclamationTriangle ,
    faAngleRight as faAngleRightSolid,
    faInfoCircle as faInfoCircleSolid,
    faImages as faImagesSolid,
    faSignature,
    faFolderOpen,
    faCaretRight,
    faArrowUp,
    faHistory,
    faShoppingCart,
    faTools,
    faExpandAlt
} from '@fortawesome/free-solid-svg-icons';

import {
    faCalendarAlt,
    faCircle,
    faCheckCircle,
    faTimesCircle,
    faUserCircle
} from '@fortawesome/free-regular-svg-icons';

import {
    faBuffer
} from '@fortawesome/free-brands-svg-icons'; 

import {
    faAngleRight as faAngleRightRegular,
    faLocationCircle,
    faBan as faBanRegular,
    faExchange,
    faSync,
    faCameraAlt,
    faCameraHome
} from '@fortawesome/pro-regular-svg-icons';

import {
    faDatabase as faDatabaseLight,
    faMapMarkerAlt as faMapMarkerAltLight,
    faArrowCircleLeft,
    faClock as faClockLight,
    faRoad,
    faTimes as faTimesLight,
    faCar,
    faImagePolaroid,
    faPen,
    faHandPointRight
} from '@fortawesome/pro-light-svg-icons';

import {
    faDatabase as faDatabaseDuoTone,
    faRoute as faRouteDuoTone,
    faListAlt,
    faPlusCircle,
    faPauseCircle,
    faSlidersVSquare,
    faCheck as faCheckDuoTone,
    faInfo,
    faMapMarkerAlt as faMapMarkerAltDuoTone,
    faTimes as faTimesDuoTone,
    faImages as faImagesDuoTone,
    faBan as faBanDuoTone,
    faTrashAlt,
    faExpand,
    faHome,
    faClock as faClockDuoTone,
    faInfoCircle as faInfoCircleDuoTone,
    faCommentAltLines,
    faFileAlt,
    faHourglass,
    faHourglassStart,
    faHourglassHalf,
    faCloudDownloadAlt,
    faPlayCircle,
    faStopCircle,
    faPhone,
    faAt,
    faSitemap,
    faUserHardHat,
    faChevronDoubleRight,
    faRepeatAlt,
    faClipboard,
    faHouseLeave,
    faHouseReturn,
    faFlagCheckered,
    faLink
} from '@fortawesome/pro-duotone-svg-icons';

export const FontAwesomeDuoToneStyle = {
    '--fa-primary-color': primaryColor.main,
    '--fa-secondary-color': hexToRgba(secondaryColor.main, .4)
}

export const FontAwesomeDuoToneStyleDanger = {
    '--fa-primary-color': errorColor.main,
    '--fa-secondary-color': hexToRgba(errorColor.main, .4)
}

// Building our library
library.add(
    // Adding the FREE SOLID icons to our library
    faRouteSolid,
    faCog,
    faUserCog,
    faSignOutAlt,
    faCheckSolid,
    faQuestionCircle,
    faExclamationTriangle,
    faAngleRightSolid,
    faInfoCircleSolid,
    faImagesSolid,
    faSignature,
    faFolderOpen,
    faCaretRight,
    faArrowUp,
    faHistory,
    faShoppingCart,
    faTools,
    faExpandAlt,
    // Adding the FREE REGULAR icons to our library
    faCalendarAlt,
    faCircle,
    faCheckCircle,
    faTimesCircle,
    faUserCircle,
    // Adding the FREE BRANDS icons to our library
    faBuffer,
    // Adding the PRO REGULAR icons to our library
    faAngleRightRegular,
    faLocationCircle,
    faBanRegular,
    faExchange,
    faSync,
    faCameraAlt,
    faCameraHome,
    // Adding the PRO LIGHT icons to our library
    faDatabaseLight,
    faMapMarkerAltLight,
    faArrowCircleLeft,
    faClockLight,
    faRoad,
    faTimesLight,
    faCar,
    faImagePolaroid,
    faPen,
    faHandPointRight,
    // Adding the PRO DUOTONE icons to our library
    faDatabaseDuoTone,
    faRouteDuoTone,
    faListAlt,
    faPlusCircle,
    faPauseCircle,
    faSlidersVSquare,
    faCheckDuoTone,
    faInfo,
    faMapMarkerAltDuoTone,
    faTimesDuoTone,
    faImagesDuoTone,
    faBanDuoTone,
    faTrashAlt,
    faExpand,
    faHome,
    faClockDuoTone,
    faInfoCircleDuoTone,
    faCommentAltLines,
    faFileAlt,
    faHourglass,
    faHourglassStart,
    faHourglassHalf,
    faCloudDownloadAlt,
    faPlayCircle,
    faStopCircle,
    faPhone,
    faAt,
    faSitemap,
    faUserHardHat,
    faChevronDoubleRight,
    faRepeatAlt,
    faClipboard,
    faHouseLeave,
    faHouseReturn,
    faFlagCheckered,
    faLink
);
