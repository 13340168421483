import db from './driverappdb';
import Dexie from 'dexie';
import utils from '..';
import { Actions } from '../enums/actions';

// notifications
const _getNotificationCount = async () => {
    let notifications = await db.notifications.toArray();
    return notifications.length;
}

// logs
const _addErrorToLogs = async (error, tags = []) => {
    let tagsToUse = ['DriverApp', ...(tags ?? [])];
    let combinedTags = tagsToUse.map(tag => `[${tag.toUpperCase()}] `).join('');
    let fullError = JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error)));
    let {message, stack, ...data} = fullError;

    let errorObject = {
        isError: true,
        date: utils.toLocalTimeZone(new Date()).toJSON(),
        message: `${combinedTags}${message}`,
        stackTrace: stack,
        data: data
    };
    
    return db.logs.add(errorObject);
};

const _addLogMessage = async (message, tags = [], data = null) => {
    let tagsToUse = ['DriverApp', ...(tags ?? [])];
    let combinedTags = tagsToUse.map(tag => `[${tag.toUpperCase()}] `).join('');
    let logObject = {
        isError: false,
        date: utils.toLocalTimeZone(new Date()).toJSON(),
        message: `${combinedTags}${message}`,
        data: data
    };
    
    return db.logs.add(logObject);
}

const _joinDropData = (drops) => {
    let all = Dexie.Promise.all;

    let dropsArray = Array.isArray(drops) ? drops : drops.toArray();

    // Query related dropdata
    let dropsDataPromises = dropsArray.map(drop => db.dropdata.get(drop.id || 0));

    // Await dropdata query
    return all([all(dropsDataPromises)])
        .then((dropsWithData) => {
            // All foreign keys are resolved so we can put the results onto the drops array before returning it.
            return dropsArray.map((drop, i) => (mergeDeep(drop, dropsWithData[0][i])));
        });
}

const _joinRoutePlanItemData = (routePlanId, routePlanItems) => {
    let all = Dexie.Promise.all;

    let routePlanItemsArray = Array.isArray(routePlanItems) ? routePlanItems : routePlanItems.toArray();

    // Query related routeplanitemdata
    let routePlanItemDataPromises = routePlanItemsArray.map(routePlanItem => db.routeplanitemdata.get({ routePlanId: routePlanId, type: routePlanItem.type, payloadId: routePlanItem.payloadId }));

    // Await routeplanitemdata query
    return all([all(routePlanItemDataPromises)])
        .then(routePlanItemsWithData => {
            // All foreign keys are resolved so we can put the results onto the routeplanitems array before returning it.
            return routePlanItemsArray.map((item, i) => (mergeDeep(item, routePlanItemsWithData[0][i])));
        });
}

const isObject = (item) => {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

const mergeDeep = (target, ...sources) => {
    if (!sources.length) return target;
    const source = sources.shift();
  
    if (isObject(target) && isObject(source)) {
      for (const key in source) {
        if (isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} });
          mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
  
    return mergeDeep(target, ...sources);
}

const _isActionAllowed = async (action) => {
    return Promise.resolve(await db.actions.toArray().then(async (result) => {
        var actions = result.sort((a, b) => { return a.time - b.time });
        if (actions.length > 0) {
            let lastAction = actions[actions.length - 1];

            switch (action.type) {
                case Actions.PAUSE_START:
                case Actions.PAUSE_STOP:
                    return !(lastAction.type === action.type);
                default:
                    return !(lastAction.type === action.type && lastAction.key === action.key);
            }
            
        } else {
            return true;
        }
    }));
}

// export
const queries = {
    getNotificationCount: _getNotificationCount,
    addErrorToLogs: _addErrorToLogs,
    addLogMessage: _addLogMessage,
    joinDropData: _joinDropData,
    joinRoutePlanItemData: _joinRoutePlanItemData,
    isActionAllowed: _isActionAllowed
}

export default queries;