import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        height: "calc(100vh - 30vh)",
        display: "flex",        
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center"
    },
    loader: {
        width: "40px",
        height: "40px"
    }
}));

function Loader({ variant, loadingText, progress }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12}>
                    <CircularProgress className={classes.loader} variant={variant} value={progress} />
                </Grid>
                <Grid item xs={12}>
                    <div>{loadingText}</div>
                </Grid>
            </Grid>
        </div>
    );
}

export default Loader;