import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FontAwesomeDuoToneStyle } from '../../fontawesome';
import { Typography } from '@material-ui/core';
import { RemarkFieldType } from '../remarks/RemarkFieldType';
import { ConditionalRemarkGroupWrapper } from '../remarks/Remarks';
import { buildGroupedRemarks, buildRemarkGroupsDictionary } from '../remarks/utils';

const RemarksOverview = ({ remarks, enabledOnly = true, nonEmptyOnly = false }) => {
    const [filteredRemarks, setFilteredRemarks] = useState([]);
    const [groupDictionary, setGroupDictionary] = useState({});
    const [groupedRemarks, setGroupedRemarks] = useState([]);
    const [t, i18n] = useTranslation();

    useEffect(() => {
        if (remarks) {
            var remarksToHandle = remarks.filter(r => {
                if (enabledOnly && !r.enabled)
                    return false;

                if (nonEmptyOnly && (r.remark == null || typeof(r.remark) == undefined || r.remark.trim() === ""))
                    return false;

                return true;
            });

            setFilteredRemarks(remarksToHandle);

            // Because we'll sort on seqNr we need to use all remarks (also those who are empty) and filter them out after sorting.
            // Otherwise the resulting order would be different with the screens which shows all the remarks
            var enabledRemarks = enabledOnly ? remarks.filter(r => r.enabled) : remarks;
            setGroupDictionary(buildRemarkGroupsDictionary(enabledRemarks));

            var handledRemarkIds = remarksToHandle.map(r => r.remarkId);
            setGroupedRemarks(buildGroupedRemarks(enabledRemarks, handledRemarkIds));
        }
    }, [remarks, enabledOnly, nonEmptyOnly]);

    const getDisplayOutput = (remarkData) => {
        switch (remarkData.fieldType) {
            case RemarkFieldType.CHECKBOX:
                return remarkData.remark?.toLowerCase() === "true" ? t("Yes") : t("No");
            case RemarkFieldType.DROPDOWN:
                var dropDownValue = remarkData.remark;
                if (remarkData.configuration != null && remarkData.configuration.labelAndText != null && remarkData.configuration.labelAndText.remarkConfigTexts != null) {
                    var textModels = remarkData.configuration.labelAndText.remarkConfigTexts.flatMap(c => c.textModels)
                        .filter(m => m.id === remarkData.remark);

                    var textModel = textModels.find(m => m.languageAbbr.toLowerCase() === i18n.language.toLowerCase());
                    
                    // Fallback for a language which doesn't exists inside the TextModels.
                    if (textModel == null && textModels.length > 0)
                        textModel = textModels[0];
                    
                    dropDownValue = textModel?.description;
                }
                return dropDownValue;
            default:
                return remarkData.remark;
        }
    }

    /**
     * Returns the (translated) label of the remark by checking the configuration if necessary.
     */
     const lookUpLabel = (remark) => {
        var label = remark.label;
        var configuration = remark.configuration;

        if (configuration != null && configuration.labelAndText != null &&
            configuration.labelAndText.labels != null && configuration.labelAndText.labels.length > 0) {
            var translatedLabel = configuration.labelAndText.labels.find(l => l.languageAbbr.toLowerCase() === i18n.language.toLowerCase());
            
            // Fallback for a language which doesn't exists inside the labels.
            if (translatedLabel == null)
                translatedLabel = configuration.labelAndText.labels[0];

            label = translatedLabel?.description;
        }
        else if (remark.translations != null && remark.translations.length > 0) {
            const translation = remark.translations.find(x => x.language?.abbreviation?.toLowerCase() === i18n.language.toLowerCase());
            if (translation)
                label = translation.translatedTerm;
        }

        return label;
    }

    return (
        <>
            {
                groupedRemarks.map(groupedRemarks => {
                    var groupId = groupedRemarks.groupId;
                    var remarkGroup = groupDictionary[groupId];
                    var remarkIdsInGroup = groupedRemarks.remarks;
                    var isTopLevelGroup = groupId == null || isNaN(parseInt(groupId));

                    // Still keep using the elements of the filteredRemarks to make sure all change-events and rerendering will still work for remarks in or outside a remarkgroup
                    // This way the remarkGroups are only used to combine the elements in the UI.
                    var content = filteredRemarks.filter(r => remarkIdsInGroup.indexOf(r.remarkId) >= 0)
                        .map(d => {
                            var label = lookUpLabel(d);
                            
                            return (
                                <div key={d.id || label} className="info-general-item">
                                    <div className="info-general-icon">
                                        <div>
                                            <FontAwesomeIcon icon={["fad", "comment-alt-lines"]} size="lg" style={FontAwesomeDuoToneStyle} />
                                        </div>
                                    </div>
                                    <div>
                                        {
                                            d !== null ? 
                                                <>
                                                    <Typography variant="subtitle2">{label}</Typography>
                                                    <div dangerouslySetInnerHTML={{ __html: getDisplayOutput(d) }} style={{wordBreak: 'break-all'}}></div>
                                                </> : 
                                                <div>{t("NoDriverRemarkAvailable")}</div>
                                        }
                                    </div>
                                </div>
                            );
                        });

                    return (
                        <div key={`grouped-remarks-wrapper-${groupId}`}>
                            {
                                // We'll only check if it's neccessary to show the gorup if it contains content which can be optionally shown.
                                content != null && content.length > 0 ?
                                    <ConditionalRemarkGroupWrapper isTopLevelGroup={isTopLevelGroup} group={remarkGroup} contentStyles={{ marginTop: -15, paddingBottom: 0 }}
                                        key={isTopLevelGroup ? 'top-level-remarks' : `remark-group-${groupId}`} style={{marginLeft: 15}}>
                                        {content}
                                    </ConditionalRemarkGroupWrapper> : 
                                    <></>
                            }
                        </div>
                    );
                })
            }
        </>
    );
}

export default RemarksOverview;