import { useHistory } from "react-router-dom";
import queries from "../utils/dexie/queries";
import db from "../utils/dexie/driverappdb";
import { DateUtils } from "../utils/date-utils";
import { RouteRepository } from "../utils/dexie/repositories/route-repository";
import utils from "../utils";
import { syncActions } from "../utils/dexie/sync";
import { useContext } from "react";
import { AuthContext } from "../providers/AuthProvider";
import { RouteStatus } from "../utils/enums/route-status";

export function useRouteStatusManagement(routeId) {
    const history = useHistory();
    const auth = useContext(AuthContext);

    const updateRouteStatus = async (newStatus) => {
        try {
            let actionType;
            switch (newStatus) {
                case RouteStatus.BUSY:
                    actionType = "RouteStart";
                    break;
                case RouteStatus.FINISHED:
                    actionType = "RouteStop";
                    break;
                case RouteStatus.INTERRUPTED:
                    actionType = "RouteInterrupted";
                    break;
                default:
                    break;
            }

            if (actionType) {
                let now = new Date();
                let nowUTC = DateUtils.convertLocalToUTC(now);

                let updateRouteAction = { 
                    type: actionType, 
                    key: routeId, 
                    time: nowUTC 
                };

                let isAllowed = await queries.isActionAllowed(updateRouteAction);
                if (isAllowed) {

                    if (actionType === "RouteStop") {
                        // The article usages of interrupted drops are not synced yet because the drops could be reopened.
                        // Now we can sync those because once a route is stopped, they cannot be reopened anymore.
                        // So trigger a sync of those article usages before stopping the route.
                        await db.actions.put({ 
                            type: "SYNC_ARTICLE_USAGES_INTERRUPTED_DROPS", 
                            key: routeId, 
                            time: nowUTC 
                        });
                    }

                    let route = await RouteRepository.getRouteById(routeId);
                    if (route != null) {
                        let plannedTime = route.start;
                        if (actionType === "RouteStop" || actionType === "RouteInterrupted") 
                            plannedTime = route.stop;

                        let updatedRouteData = {
                            status: newStatus,
                            delay: utils.calculateDelay(new Date(plannedTime), now)
                        };

                        if (actionType === "RouteStart") {
                            updatedRouteData.realStart = new Date(now).toISOString()
                        }

                        await db.routes.update(routeId, updatedRouteData);

                        db.actions.put(updateRouteAction)
                            .finally(() => {
                                if (actionType === "RouteStop")
                                    syncActions(auth);
                            });
                    }
                }
            }

        } catch (error) {
            queries.addErrorToLogs(error);
            history.push('/error');
        }
    };

    return updateRouteStatus;
};