import { Container } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Loader from '../../../../components/loader';
import db from '../../../../utils/dexie/driverappdb';
import ArticlesCatalogView from './ArticlesCatalogView';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

const ArticlesCatalogContainer = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [articles, setArticles] = useState([]);

    const location = useLocation();

    useEffect(() => {
        if (location.state != null) {
            db.transaction('r', db.params, db.articles, db.articleinventorylevels, db.articleimages, async () => {
                let params = await db.params.where('id').notEqual(0).first();

                // 1) Detect the ids of the articles which are candidates to be shown.
                let articleIds = [];
                if (params.articleInventoryNoLimits) { // No limited article inventory, so all non-returnable articles are candidates
                    articleIds = await (await db.articles.orderBy('id').filter(article => !article.isReturnable))
                        .uniqueKeys();
                } else { // Limited article inventory, so all articles which have an inventoryLevel are candidates
                    articleIds = await (await db.articleinventorylevels.orderBy('articleId'))
                        .uniqueKeys();
                }

                // 2) Filter out the excluded articles
                let visibleArticleIds = articleIds.filter(el => !(location.state.excludedArticleIds || []).includes(el));

                // 3) Construct a mapping of the referenced articles (key: articleId, value: array of the id's of articles which are first-level aliases for this article)
                //    Remark: if an article has no alias-articles, its id will not be populated in the mapping.
                let referencedByMapping = {};
                await db.articles.each(article => {
                    if (article.aliasArticleId != null)
                        referencedByMapping[article.aliasArticleId] = [...referencedByMapping[article.aliasArticleId] ?? [], article.id]
                });

                // 4) Only keep the active articles which may be visible (non-excluded articles which are aliases to another article or which are not referenced by an alias article)
                return await db.articles.filter(article => {
                    let isInActive = article.inActive;
                    let isVisibleArticle = visibleArticleIds.indexOf(article.id) > -1;
                    let referencesOtherArticle = article.aliasArticleId !== null;
                    let referencedByAnotherArticle = article.id in referencedByMapping;

                    return !isInActive && isVisibleArticle && (referencesOtherArticle || !referencedByAnotherArticle);
                })
                    .with({ images: 'articleimages', inventoryLevels: 'articleinventorylevels' })
                    .then(articles => setArticles(articles));
            })
            .finally(() => setIsLoading(false));
        }
    }, [location]);

    // When you directly navigate to the catalog page, the location state is empty and we cannot handle this request. 
    // So we redirect them to the home-page.
    if (location.state == null)
        return <Redirect to="/" />;
    
    return (
        <Container>
            { isLoading ? <Loader /> : <ArticlesCatalogView articles={articles} /> }
        </Container>
    );

};

export default ArticlesCatalogContainer;