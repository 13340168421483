import { useHistory } from "react-router-dom";
import queries from "../../../../utils/dexie/queries";
import { Actions } from "../../../../utils/enums/actions";
import { DateUtils } from "../../../../utils/date-utils";
import db from "../../../../utils/dexie/driverappdb";
import { usePlanItemStatusManagement } from "../../../../hooks/plan-item-status-management";
import { PlanningItemType } from "../../../../utils/enums/planning-item-type";
import { PlanningItemStatus } from "../../../../utils/enums/planning-item-status";

export function useUnproductiveTimeStatusManagement(unproductiveTimeId, routeId, hashCode) {
    const history = useHistory();
    
    const updateRoutePlanningItemStatus = usePlanItemStatusManagement(routeId, PlanningItemType.UNPRODUCTIVE_TIME, unproductiveTimeId, hashCode);

    const updateUnproductiveTimeStatus = async (planningItemStatus, breakTypeId = null, remark = null) => {
        try {
            let actionType;
            switch (planningItemStatus) {
                case PlanningItemStatus.IN_PROGRESS:
                    actionType = Actions.UNPRODUCTIVETIME_START;
                    break;
                case PlanningItemStatus.FINISHED:
                    actionType = Actions.UNPRODUCTIVETIME_STOP;
                    break;
                default:
                    break;
            }

            if (actionType) {
                let now = new Date();
                let nowUTC = DateUtils.convertLocalToUTC(now);

                let updateUnproductiveTimeStatusAction = {
                    type: actionType,
                    key: unproductiveTimeId,
                    time: nowUTC,
                    routeId: routeId
                };

                if (actionType === Actions.UNPRODUCTIVETIME_START) {
                    updateUnproductiveTimeStatusAction.breakTypeId = breakTypeId;
                    updateUnproductiveTimeStatusAction.remark = remark;
                }

                let isAllowed = await queries.isActionAllowed(updateUnproductiveTimeStatusAction);
                if (isAllowed) {
                    await updateRoutePlanningItemStatus(planningItemStatus);
                    
                    if (actionType === Actions.UNPRODUCTIVETIME_START) {
                        await db.rests.add({ start: nowUTC, end: undefined, routeId: routeId, payloadId: unproductiveTimeId});
                    } else {
                        let currentRest = (await db.rests.where({ routeId: routeId, payloadId: unproductiveTimeId }).sortBy('id')).pop();
                        if (currentRest != null) {
                            await db.rests.update(currentRest.id, { end: nowUTC });
                        }
                    }
                    
                    await db.actions.put(updateUnproductiveTimeStatusAction);
                }
            }
        } catch (error) {
            queries.addErrorToLogs(error);
            history.push('/error');
        }
    };

    return updateUnproductiveTimeStatus;
};