import React, { useState, useEffect, useContext } from 'react';
import Loader from '../loader';
import HistoryView from './HistoryView';
import { AuthContext } from '../../providers/AuthProvider';

const HistoryContainer = ({ mainProjectId }) => {

    const [isLoading, setIsLoading] = useState(true);
    const auth = useContext(AuthContext);
    const [projectHistory, setProjectHistory] = useState();

    useEffect(() => {
        auth.fetch('get', `mainprojects/history/${mainProjectId}`)
            .then(result => {
                if (result && result.status === 200 && result.data) {
                    setProjectHistory(result.data.projectsHistory);
                }
            })
            .finally(() => setIsLoading(false));
    }, [mainProjectId, auth]);

    return (
        <div>
            { isLoading ? <Loader /> : <HistoryView projectHistory={projectHistory} /> }
        </div>
    );

};

export default HistoryContainer;