import React from "react";
import { AuthConsumer } from "../../providers/AuthProvider";
import Loader from "../loader";

export const LogoutCallback = () => (
    <AuthConsumer>
        {({ signoutRedirectCallback }) => {
            signoutRedirectCallback();
            return <Loader />;
        }}
    </AuthConsumer>
);