import React from 'react';
import Error from '../../pages/error';
import queries from '../../utils/dexie/queries';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }
    
    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        
        // You can also log error messages to an error reporting service here
        queries.addErrorToLogs(error);
    }

    render() {
        if (this.state.errorInfo) {
            return <Error></Error>;
        }        
        return this.props.children;
    }
}