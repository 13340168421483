import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AppBar, Toolbar, IconButton, makeStyles, Typography, Menu, MenuItem, Chip } from '@material-ui/core';
import { useAppContext } from '../../utils/contexts/appContext';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuIcon from '@material-ui/icons/Menu';
import { useState } from 'react';
import db from '../../utils/dexie/driverappdb';
import { RoutePlanRepository } from "../../utils/dexie/repositories/route-plan-repository";
import clsx from 'clsx';
import { useLiveQuery } from 'dexie-react-hooks';

const useStyles = makeStyles(theme => ({
    toggler: {
        marginRight: theme.spacing(2)
    },
    childPageToggler: {
        paddingLeft: 0,
        marginLeft: 'calc(12px - .5rem)' // Necessary to prevent opening the drawer when the user has the intention to navigate to the parent page
    },
    pauseLabel: {
        backgroundColor: theme.palette.warning.main,
        position: 'absolute',
        zIndex: 1,
        top: '75%',
        left: '25%',
        right: '25%',
        margin: '0 auto',
        maxWidth: 180
    }
}));

const Header = () => {
    const classes = useStyles();
    const [t, i18n] = useTranslation();
    const [{ header, pause, pauseId, changesDetected }, dispatch] = useAppContext();
    const [languageMenu, setLanguageMenu] = useState();
    const [, setLanguageMenuNotifications] = useState();

    const queryResult = useLiveQuery(() => Promise.all([
        db.languages.toArray(),
        RoutePlanRepository.hasBusyPlanItemAcrossRoutes(),
        db.rests.orderBy('id').last()
    ]));
    const [languages, hasExecutingPlanningItem, rest] = queryResult ?? [[], undefined, undefined];

    useEffect(() => {
        if (queryResult) {
            if (!hasExecutingPlanningItem) {
                dispatch({
                    type: 'loaded',
                    executingPlanningItem: hasExecutingPlanningItem
                });
            }
    
            if (rest != null && rest.end == null) {
                // Dispatch the initial pause state
                let isGlobalPause = rest.payloadId == null;
                if (isGlobalPause)
                    dispatch({ type: 'togglePause', pause: true });
                else
                    dispatch({ type: 'togglePauseByPlanning', pause: true, pauseId: rest.payloadId });
            }
        }
    }, [queryResult, hasExecutingPlanningItem, rest, dispatch])

    const languageMenuIsOpen = Boolean(languageMenu);
    let history = useHistory();

    const refreshData = () => {
        db.notifications.clear()
            .then(result => {
                dispatch({
                    type: 'changesDetection',
                    changesDetected: false
                });
                
                window.location.reload();
            });
    };

    const showLanguageMenu = event => {
        setLanguageMenu(event.currentTarget);
    };

    const closeLanguageMenu = () => {
        setLanguageMenu(null);
        setLanguageMenuNotifications(null);
    };

    const handleLanguageSelect = async (lang) => {
        localStorage.setItem('i18nextLng', lang);
        i18n.changeLanguage(lang);
        dispatch({ type: 'changeLanguage', language: lang });
        setLanguageMenu(null);

        // Currently we'll not sync the language update with the backend to prevent convert-issues which are not using InvariantCulture
        /*let language = await db.languages.get({abbreviation: lang.toUpperCase()});
        if (language != null) {
            let now = new Date();
            var utc = now.getTime() - now.getTimezoneOffset() * 60000;

            let languageChangedAction = { type: "LanguageChanged", key: language.languageId, time: utc };
            queries.isActionAllowed(languageChangedAction)
                .then(allowed => {
                    if (allowed) {
                        db.actions.put(languageChangedAction);
                    }
                });
        }*/
    }

    const handleToggler = () => {
        dispatch({ type: 'toggleDrawer' });
    }

    return (
        <AppBar position="fixed">
            <Toolbar>
                {
                    (header.child) ?
                        <IconButton color="inherit" aria-label="open drawer" edge="start"
                            className={clsx(classes.toggler, classes.childPageToggler)}
                            onClick={(e) => {
                                e.preventDefault();
                                if (header.onNavigation !== undefined) {
                                    header.onNavigation();
                                }
                                    
                                if (header.path === undefined) {
                                    window.history.go(-1);
                                } else {
                                    history.push(header.path);
                                }
                            }}>
                            <FontAwesomeIcon icon={["fal", "arrow-circle-left"]} size="2x" />
                        </IconButton> :
                        <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleToggler} className={classes.toggler}>
                            <MenuIcon />
                        </IconButton>
                }
                <Typography variant="h6" noWrap>{header.title}</Typography>
                {
                    pause ? 
                        <Chip label={`${t(pauseId != null ? "SuggestedBreak" : "Pause")} ...`} color="primary" className={classes.pauseLabel} /> :
                        <></>
                }
                <div style={{ marginLeft: "auto" }}>
                    <div style={{ float: "right", textAlign: 'right', width: changesDetected ? 90 : 45, display: 'flex' }}>
                        {
                            changesDetected ? 
                                <IconButton color="inherit" onClick={refreshData}>
                                    <FontAwesomeIcon icon={["far", "sync"]} size="lg" />
                                </IconButton> :
                                <></>
                        }
                        <IconButton aria-controls="menu-language" aria-haspopup="true" onClick={showLanguageMenu} color="inherit" style={{ fontWeight: "600" }}>
                            <span style={{ fontSize: "1.25rem" }}>{i18n.language.toUpperCase()}</span>
                        </IconButton>
                        <Menu id="menu-language" 
                            open={languageMenuIsOpen}
                            onClose={closeLanguageMenu}
                            keepMounted
                            anchorEl={languageMenu}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}>
                            {
                                languages
                                    .map(lg => 
                                        <MenuItem key={lg.languageId} onClick={() => { handleLanguageSelect(lg.abbreviation.toLowerCase()) }}>
                                            {lg.description}
                                        </MenuItem>
                                    )
                            }
                        </Menu>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    );

}

export default Header;