import { Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    responsiveIconButton: {
        '@media (max-width: 500px)': {
            '&> .MuiButton-label .label-content': {
                position: 'absolute',
                width: 1,
                height: 1,
                padding: 0,
                overflow: 'hidden',
                clip: 'rectangle(0, 0, 0, 0)',
                whiteSpace: 'nowrap',
                border: 0
            },
            '&> .MuiButton-label .MuiButton-startIcon': {
                marginRight: 0
            }
        }
    }
}));

/**
 * A component to draw a button which will hide the label on small screens.
 * @param {*} props 
 * @returns 
 */
function ResponsiveButton(props) {
    const classes = useStyles();
    const {children, className, ...otherProps} = props;

    return (
        <Button {...otherProps} className={clsx(className, classes.responsiveIconButton)}>
            <span className="label-content">{children}</span>
        </Button>
    );

};

export default ResponsiveButton;