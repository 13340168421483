import React, { useEffect, useMemo } from 'react';
import RoutesView from './RoutesView';
import Loader from '../../components/loader/Loader';
import {RoutePlanRepository} from '../../utils/dexie/repositories/route-plan-repository';
import { useLiveQuery } from 'dexie-react-hooks';
import { RouteStatus } from '../../utils/enums/route-status';
import { RouteRepository } from '../../utils/dexie/repositories/route-repository';
import { useAppContext } from '../../utils/contexts/appContext';
import { useTranslation } from 'react-i18next';

function RoutesContainer() {
    const [, dispatch] = useAppContext();
    const [t] = useTranslation();

    const routeQueryResult = useLiveQuery(() => Promise.all([
        RouteRepository.getRoutesByStatus(RouteStatus.BUSY), 
        RouteRepository.getRoutesByStatus(RouteStatus.INTERRUPTED), 
        RouteRepository.getRoutesByStatus(RouteStatus.NEW, RouteStatus.DISPATCHED),
        RouteRepository.getRoutesByStatus(RouteStatus.FINISHED)
    ]));

    const [openRoutes, interruptedRoutes, newRoutes, finishedRoutes] = routeQueryResult ?? [[], [], [], []];
    const routeIds = useMemo(() => [ 
            ...openRoutes, ...interruptedRoutes, ...newRoutes, ...finishedRoutes
        ].map(route => route.id), 
        [openRoutes, interruptedRoutes, newRoutes, finishedRoutes]
    );

    const progressStatistics = useLiveQuery(() => RoutePlanRepository.getProgressStatistics(routeIds), [routeIds]);
    const isLoading = !routeQueryResult || !progressStatistics;

    useEffect(() => {
        dispatch({ 
            type: 'changeHeader', 
            header: { 
                title: t("Routes"), 
                child: false 
            } 
        });
    }, [dispatch, t]);

    return (
        <div className="global-container">
            {
                isLoading ?
                    <Loader /> : 
                    <RoutesView openRoutes={openRoutes} interruptedRoutes={interruptedRoutes} newRoutes={newRoutes} 
                        finishedRoutes={finishedRoutes} progressStatistics={progressStatistics} />
            }
        </div>
    );
}

export default RoutesContainer;