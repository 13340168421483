import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../../utils/contexts/appContext';
import Loader from '../../../components/loader';
import LogsView from './LogsView';
import db from '../../../utils/dexie/driverappdb';

const LogsContainer = () => {
    const [, dispatch] = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [t] = useTranslation();
    const [logs, setLogs] = useState([]);
    const [actions, setActions] = useState([]);

    useEffect(() => {
        dispatch({ type: 'changeHeader', header: { title: t("Logs"), child: true } });

        db.transaction('r', db.logs, db.actions, async () => {
            await db.logs.toArray()
                .then(logs => setLogs(logs));
            await db.actions.toArray()
                .then(actions => setActions(actions));
        })
        .finally(() => setIsLoading(false));
    }, [dispatch, t]);

    return (
        <div className="global-container">
            {
                isLoading ?
                    <Loader /> :
                    <LogsView logs={logs} actions={actions} />
            }
        </div>
    );
};

export default LogsContainer;
