import db from "../driverappdb";

export const RouteRepository = {
    getRouteById: async (routeId) => {
        let routes = await db.routes.where('id').equals(routeId)
            .with({ 
                startLocation: 'startLocation', 
                stopLocation: 'stopLocation' 
            });

        return routes.length > 0 ? routes[0] : null;
    },
    getRoutesByStatus: (...routeStatuses) => {
        return db.routes.where("status").anyOf([...routeStatuses]).toArray();
    },
    countOpenRoutes: async () => {
        let openRoutes = await db.routes.where("status").equals(30).toArray();
        return openRoutes.length;
    }
};