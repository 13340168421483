import { Box, Button, CardActionArea, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography, makeStyles, useTheme } from "@material-ui/core";
import PlanningTimelineItem from "../planning-timeline-item";
import PlanningItemContent from "../planning-item-content";
import PlanningItemBody from "../planning-item-body";
import PlanningItemActions from "../planning-item-actions";
import NavigateButton from "../action-buttons/navigate-button";
import { useContext } from "react";
import { RoutePlanningContext } from "../../../../../utils/contexts/route-planning-context";
import InformationButton from "../action-buttons/information-button";
import PlanningItemHeader from "../planning-item-header";
import PlanningItemHeaderBadge from "../planning-item-header-badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FontAwesomeDuoToneStyle } from "../../../../../fontawesome";
import { useState } from "react";
import { ExpandMore } from "@material-ui/icons";
import clsx from 'clsx';
import { ExternalStatus } from "../../../../../components/external-status";
import DropType from "../../../../../components/dropType/DropType";
import { useEffect } from "react";
import ProjectType from "../../../../../components/projectType";
import UpdateStatusButton from "../action-buttons/update-status-button";
import { useDropStatusManagement } from "../../hooks/drop-status-management";
import { DropStatus } from "../../../../../utils/enums/drop-status";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRouteAutoStart } from "../../../../../hooks/route-auto-start";
import PlanningItemDelay from "../planning-item-delay";
import { PlanningItemStatus } from "../../../../../utils/enums/planning-item-status";
import { useSinglePress } from "../../../../../hooks/single-press";

const useStyles = makeStyles(theme => ({
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto !important',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: 'rotate(180deg)'
    },
    collapsedContentWrapper: {
        padding: `${theme.spacing(3)} !important`,
        paddingTop: '0 !important'
    },
    badgesWrapper: {
        display: 'flex',
        gap: theme.spacing(2)
    }
}));

const DropPlanningItem = ({ 
    planningItem, 
    readOnly = true
}) => {
    const { payload: drop, hashCode } = planningItem;
    const {
        routeId, dropTypes, projectTypes, navigationApp, skipAllowed, 
        externalStatusManagement, autoAcceptTimeRegistrations, useTimeRegistrations, displayDepartureTime
    } = useContext(RoutePlanningContext);

    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const [t] = useTranslation();
    const [routeAutoStartEnabled, autoStartRoute] = useRouteAutoStart(planningItem);
    const updateDropStatus = useDropStatusManagement(routeId, drop.id, hashCode);

    const [expanded, setExpanded] = useState(true);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

    const dropType = dropTypes.find(dt => dt.id === drop.dropType);
    const projectType = projectTypes.find(pt => pt.id === drop.projectType);
    const [collapsedContentEnabled, setCollapsedContentEnabled] = useState(false);

    useEffect(() => {
        setCollapsedContentEnabled(projectType != null || dropType != null || (externalStatusManagement || drop.externalStatusId));
    }, [projectType, dropType, externalStatusManagement, drop.externalStatusId, drop.status]);

    const onClickExecutionButton = useSinglePress(async () => {
        if (drop.status === DropStatus.BUSY) {
            goToDrop();
        } else if (autoAcceptTimeRegistrations) {
            await onClickStartOrResumeButton();
        } else {
            setConfirmationDialogOpen(true);
        }
    });

    const onClickStartOrResumeButton = useSinglePress(async () => {
        if (routeAutoStartEnabled)
            await autoStartRoute();

        await updateDropStatus(DropStatus.BUSY)
            .then(goToDrop);
    });

    const goToDrop = () => history.push(`/routes/timeline/job/${drop.id}`);

    const onClickFinish = () => history.push(`/routes/timeline/arrive/${drop.id}`);

    const onClickSkip = () => history.push(`/routes/timeline/skip/${drop.id}`);

    const getTimelineItemProps = () => {
        const alternativeOpacity = .3;

        let icon = <FontAwesomeIcon icon={["far", "circle"]} />;
        let iconColor;
        let iconOpacity;

        switch (drop.status) {
            case DropStatus.DONE:
                iconOpacity = .5;
                break;
            case DropStatus.SKIPPED:
                icon = <FontAwesomeIcon icon={["far", "times-circle"]} />;
                iconColor = theme.palette.error.main;
                iconOpacity = alternativeOpacity;
                break;
            case DropStatus.INTERRUPTED:
                icon = <FontAwesomeIcon icon={["far", "ban"]} />;
                iconColor = theme.palette.error.main;
                iconOpacity = alternativeOpacity;
                break;
            default:
                break;
        }

        return { icon, iconColor, iconOpacity };
    }

    return (
        <PlanningTimelineItem {...getTimelineItemProps()}>
            <PlanningItemHeader>
                <PlanningItemHeaderBadge>
                    <Typography component="span" style={{paddingLeft: 0}}>{planningItem.executionIndex}</Typography>
                </PlanningItemHeaderBadge>
                <PlanningItemHeaderBadge>
                    <FontAwesomeIcon icon={["fad", "clock"]} style={FontAwesomeDuoToneStyle} />
                    <Typography component="span">
                        {drop.etaString} <PlanningItemDelay />
                    </Typography>
                </PlanningItemHeaderBadge>
                {
                    displayDepartureTime ?
                        <PlanningItemHeaderBadge>
                            <FontAwesomeIcon icon={["fad", "flag-checkered"]} style={FontAwesomeDuoToneStyle} />
                            <Typography component="span">
                                {drop.etfString} <PlanningItemDelay />
                            </Typography>
                        </PlanningItemHeaderBadge> : null
                }
                {
                    (drop != null && drop.colleagueSchedules != null && drop.colleagueSchedules.length > 0) ? 
                        drop.colleagueSchedules.map(schedule => (
                            <PlanningItemHeaderBadge key={`colleague-${schedule.deliveryOrderScheduleId}`}>
                                <FontAwesomeIcon icon={["fad", "user-hard-hat"]} style={FontAwesomeDuoToneStyle} /> 
                                <Typography component="span">{schedule.simulationSolutionRouteDrop?.driverName}</Typography>
                            </PlanningItemHeaderBadge>
                        )) : null
                }
            </PlanningItemHeader>
            <PlanningItemBody highlighted={planningItem.status === PlanningItemStatus.IN_PROGRESS}>
                <CardActionArea component={Link} to={`/routes/timeline/info/${drop.id}`}>
                    <PlanningItemContent translucent={planningItem.status === PlanningItemStatus.FINISHED}>
                        <Typography variant="h6">{drop.location.name}</Typography>
                        <Typography variant="body1">{drop.location.address}</Typography>
                        <Typography variant="body1">{drop.location.remark}</Typography>
                        <Typography variant="body2">{drop.tw}</Typography>
                    </PlanningItemContent>
                </CardActionArea>
                <PlanningItemActions>
                    <InformationButton dropId={drop.id} />
                    <NavigateButton location={drop.location} navigationApp={navigationApp} />
                    {
                        [DropStatus.DISPATCHED, DropStatus.BUSY, DropStatus.INTERRUPTED].indexOf(drop.status) !== -1 ? 
                            (
                                useTimeRegistrations ? 
                                    <>
                                        <UpdateStatusButton iconColor="success" icon={<FontAwesomeIcon size="lg" icon={["fad", drop.status === DropStatus.BUSY ? "chevron-double-right" : (drop.status === DropStatus.INTERRUPTED ? "repeat-alt" : "check")]} />} 
                                            label={t(drop.status === DropStatus.BUSY ? 'Resume' : 'Start')} disabled={readOnly} onClick={onClickExecutionButton} />
                                        <Dialog open={confirmationDialogOpen}>
                                            <DialogTitle>{drop.location.name}</DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>{`${t("StartTimeRegistrationQuestion")} ${drop.location.name}?`}</DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={() => setConfirmationDialogOpen(false)}>
                                                    {t('Cancel')}
                                                </Button>
                                                <Button color="primary" onClick={onClickStartOrResumeButton} autoFocus>
                                                    {t(drop.status === DropStatus.BUSY ? 'Resume' : 'Start')}
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </>
                                    : <UpdateStatusButton iconColor="success" icon={<FontAwesomeIcon size="lg" icon={["fad", drop.status === DropStatus.BUSY ? "chevron-double-right" : (drop.status === DropStatus.INTERRUPTED ? "repeat-alt" : "check")]} />} 
                                        label={t('Finish')} disabled={readOnly} onClick={onClickFinish} />
                            )
                        : null
                    }
                    {
                        skipAllowed && drop.status === DropStatus.DISPATCHED ?
                            <UpdateStatusButton iconColor="error"  icon={<FontAwesomeIcon size="lg" icon={["fad", "times"]} />}
                                label={t('Skip')} disabled={readOnly} onClick={onClickSkip} /> 
                            : null
                    }
                    {
                        collapsedContentEnabled ?
                            <IconButton 
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: expanded
                                })} 
                                onClick={() => setExpanded(prev => !prev)}
                            >
                                <ExpandMore />
                            </IconButton>: null
                    }
                </PlanningItemActions>
                {
                    collapsedContentEnabled ?
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <PlanningItemContent className={classes.collapsedContentWrapper}>
                                <Box className={classes.badgesWrapper}>
                                    <ProjectType projectType={projectType} />
                                    <DropType dropType={dropType} />
                                    <ExternalStatus drop={drop} />
                                </Box>
                            </PlanningItemContent>
                        </Collapse> : null
                }
            </PlanningItemBody>
        </PlanningTimelineItem>
    );
};

export default DropPlanningItem;