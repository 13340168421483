import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../../utils/contexts/appContext';
import Loader from '../../../components/loader';
import SystemView from './SystemView';
import { useTranslation } from 'react-i18next';

const SystemContainer = () => {

    const [, dispatch] = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [t] = useTranslation();
    const [quota, setQuota] = useState(0);
    const [usage, setUsage] = useState(0);
    const [usageDetails, setUsageDetails] = useState({});

    useEffect(() => {
        dispatch({ type: 'changeHeader', header: { title: t("SystemInfo"), child: true } });

        if (navigator && navigator.storage) {
            navigator.storage.estimate()
                .then(estimate => {
                    if (estimate) {
                        setQuota(estimate.quota);
                        setUsage(estimate.usage);
                        setUsageDetails(estimate.usageDetails);
                    }
                })
                .finally(() => setIsLoading(false));
        } else {
            setIsLoading(false);
        }
    }, [dispatch, t]);

    return (
        <div className="global-container">
            {
                isLoading ? 
                    <Loader /> :
                    <SystemView quota={quota} usage={usage} usageDetails={usageDetails} />
            }
        </div>
    );

};

export default SystemContainer;