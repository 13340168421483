import { createContext } from "react";

/**
 * Exports the initial state so we can use it as default value also inside our components.
 */
export const initialRoutePlanningContextState = {
    routeId: null,
    route: null,
    startLocation: null,
    stopLocation: null,
    planningItems: [],
    filteredPlanningItems: [],
    dropTypes: [],
    projectTypes: [],
    openRoutesCount: 0,
    openPlanningItemsCount: 0,
    navigationApp: null,
    showFinishedItems: false,
    routeAutoStart: false,
    pauseAllowed: false,
    skipAllowed: false,
    externalStatusManagement: false,
    autoAcceptTimeRegistrations: false,
    useTimeRegistrations: false,
    displayDepartureTime: false
};

/**
 * Create a context which will use the initial state only when there is no matching provider found above.
 * This context will provide the current state.
 */
export const RoutePlanningContext = createContext(initialRoutePlanningContextState);

/**
 * Creates a context which will provide the function that lets components dispatch actions.
 */
export const RoutePlanningDispatchContext = createContext(null);

export const RoutePlanningProvider = ({value, dispatch, children}) => {
    return (
        <RoutePlanningContext.Provider value={value}>
            <RoutePlanningDispatchContext.Provider value={dispatch}>
                {children}
            </RoutePlanningDispatchContext.Provider>
        </RoutePlanningContext.Provider>
    );
}