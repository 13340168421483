import i18n from "../../i18n";
import { RemarkFieldType } from "./RemarkFieldType";

/**
 * Utility function to group the data by the specified key.
 * @param {*} data 
 * @param {*} key 
 * @returns 
 */
export function groupBy(data, key) {
    return data.reduce((storage, item) => {
        var group = item[key];
        storage[group] = storage[group] || [];
        storage[group].push(item);
        return storage;
    }, {});
};

/**
 * Creates tuples of each of the remarks with their corresponding group for use in generator functions.
 * @param {*} remarks 
 */
function buildRemarkGroupTuples(remarks = []) {
    return remarks.map(r => {
        var group = r.remarkGroup ?? r.configuration?.remarkGroup;

        return {
            remark: r,
            group: group
        };
    });
}

/**
 * Creates a dictionary (groupId => group) to be able to lookup the group based on the groupId.
 * @param {*} remarks 
 * @returns 
 */
export function buildRemarkGroupsDictionary(remarks = []) {
    var remarkGroupTuples = buildRemarkGroupTuples(remarks);

    // Create a dictionary (groupId => group) to be able to lookup the group based on the groupId.
    var groupTupleList = remarkGroupTuples.map(t => ({ groupId: t.group?.remarkGroupId, group: t.group }))
        .filter(t => t.group != null)
        .filter((value, index, self) => self.map(s => s.groupId).indexOf(value.groupId) === index);

    return Object.assign({}, ...groupTupleList.map(d => ({[d.groupId]: d.group})));
}

/**
 * Creates a dictionary (groupId => remarksIds) to be able to lookup the remarkIds corresponding to the groupId.
 * @param {*} remarks 
 */
export function buildGroupedRemarks(remarks = [], remarkIdsAfterSorting) {
    var remarkGroupTuples = buildRemarkGroupTuples(remarks);
    var groupRemarkTupleList = remarkGroupTuples.map(t => ({ groupId: t.group?.remarkGroupId, remarkId: t.remark.remarkId, seqNr: t.remark.seqNr }));
    var groupedGroupRemarkTupleList = groupBy(groupRemarkTupleList, 'groupId');

    // Generate a sorted list of groupId's based on the seqNr of their corresponding remarks
    var sortedKeysBasedOnSeqNr = Object.keys(groupedGroupRemarkTupleList).sort((a, b) => {
        return Math.min(...groupedGroupRemarkTupleList[a].map(x => x.seqNr)) - Math.min(...groupedGroupRemarkTupleList[b].map(x => x.seqNr));
    });

    // Group the remarks according to the calculated order
    return sortedKeysBasedOnSeqNr.map(key => ({ 
        groupId: parseInt(key), 
        remarks: groupedGroupRemarkTupleList[key].map(r => r.remarkId)
            .filter(remarkId => remarkIdsAfterSorting != null ? remarkIdsAfterSorting.indexOf(remarkId) >= 0 : true)
    }));
}

/**
 * Returns the (translated) label of the remark by checking the configuration if necessary.
 */
export function lookUpLabel(remark) {
    let label = remark.label;
    let configuration = remark.configuration;

    if (configuration != null && configuration.labelAndText != null &&
        configuration.labelAndText.labels != null && configuration.labelAndText.labels.length > 0) {
            let translatedLabel = configuration.labelAndText.labels.find(l => l.languageAbbr.toLowerCase() === i18n.language.toLowerCase());
        
        // Fallback for a language which doesn't exists inside the labels.
        if (translatedLabel == null)
            translatedLabel = configuration.labelAndText.labels[0];

        label = translatedLabel?.description;
    }
    else if (remark.translations != null && remark.translations.length > 0) {
        const translation = remark.translations.find(x => x.language?.abbreviation?.toLowerCase() === i18n.language.toLowerCase());
        if (translation)
            label = translation.translatedTerm;
    }

    return label;
}

/**
 * Returns the (translated) value of the remark by checking the configuration if necessary.
 */
export function lookUpValue(remark, onFallbackToDefaultConfigValueDetected = (defaultValue) => {}) {
    let result = remark.remark;
    let configuration = remark.configuration;

    if (result == null && configuration != null && configuration.labelAndText != null && 
        configuration.labelAndText.remarkConfigTexts != null && configuration.labelAndText.remarkConfigTexts.length > 0) {
        
        let configText = null;
        switch (remark.fieldType) {
            case RemarkFieldType.DROPDOWN:
                configText = configuration.labelAndText.remarkConfigTexts.find(c => c.default === true);
                break;
            default:
                configText = configuration.labelAndText.remarkConfigTexts[0];
                break;
        }

        if (configText != null) {
            let textModel = configText.textModels.find(m => m.languageAbbr.toLowerCase() === i18n.language.toLowerCase());

            // Fallback for a language which doesn't exists inside the TextModels.
            if (textModel == null && configText.textModels.length > 0)
                textModel = configText.textModels[0];
    
            switch (remark.fieldType) {
                case RemarkFieldType.DROPDOWN:
                    result = textModel?.id;
                    break;
                default:
                    result = textModel?.description;
                    break;
            }

            /**
             * Hook which can be used to copy the default values of the configuration into the value of the remark-field.
             * This way, we ensure that the default values can be taken into account during validation of the remark-field.
             */
            if (onFallbackToDefaultConfigValueDetected != null && typeof(onFallbackToDefaultConfigValueDetected) === 'function')
                onFallbackToDefaultConfigValueDetected(result);
            }
        
    }

    return result;
}