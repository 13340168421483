import { Container } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Loader from '../../../components/loader';
import { AuthContext } from '../../../providers/AuthProvider';
import { useAppContext } from '../../../utils/contexts/appContext';
import db from '../../../utils/dexie/driverappdb';
import RouteOverviewView from './RouteOverviewView';
import queries from '../../../utils/dexie/queries';

const RouteOverviewContainer = () => {
    let { routeId } = useParams();
    const auth = useContext(AuthContext);
    const [t,] = useTranslation();
    const [, dispatch] = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [route, setRoute] = useState();
    const [drops, setDrops] = useState([]);
    const [appParams, setAppParams] = useState([]);

    useEffect(() => {
        
        db.transaction('r', db.params, db.routes, db.locations, db.drops, db.dropdata, db.droptypes, db.driverremarks, db.deliveryorderdetails, async () => {
            setAppParams(await db.params.where('id').notEqual(0).first());

            let routes = await db.routes
                .where({ id: parseInt(routeId) })
                .with({ startLocation: 'startLocation', stopLocation: 'stopLocation' });

            if (routes.length > 0) {
                let route = routes[0];
                setRoute(route);

                dispatch({ type: 'changeHeader', header: { title: `${route.name}`, child: true } });

                let drops = await queries.joinDropData(
                    await db.drops.where({routeId: parseInt(routeId)})
                        .with({ 
                            location: 'location', 
                            dropType: 'dropType', 
                            driverremarks: 'driverremarks',
                            deliveryorderdetails: 'deliveryorderdetails'
                        })
                );

                setDrops(drops.sort((a, b) => { return a.index - b.index }));
                setIsLoading(false);
            }
        });
        
        
    }, [auth, dispatch, t, routeId]);

    return (
        <Container>
            {
                isLoading ?
                    <Loader /> :
                    <RouteOverviewView route={route} drops={drops} appParams={appParams} />
            }
        </Container>
    );
}

export default RouteOverviewContainer;