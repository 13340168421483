import React, { useEffect, useContext, useState } from 'react';
import { useAppContext } from '../../utils/contexts/appContext';
import { AuthContext } from '../../providers/AuthProvider';
import Loader from '../../components/loader/Loader';
import ModernisationsView from './ModernisationsView';
import { useTranslation } from 'react-i18next';
import { Container } from '@material-ui/core';

const ModernisationsContainer = () => {
    const auth = useContext(AuthContext);
    const [t,] = useTranslation();
    const [, dispatch] = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [modernisationProjects, setModernisationProjects] = useState([]);

    useEffect(() => {
        dispatch({ type: 'changeHeader', header: { title: t("ModernisationProjects"), child: false } });
        
        if (navigator.onLine) {
            auth.getUser()
                .then((user) => {
                    auth.fetch('get', 'projects/modernisation')
                        .then(async (result) => {
                            if (result && result.status === 200) {
                                setModernisationProjects(result.data.sort((a, b) => a.customerName.localeCompare(b.customerName)));
                                setIsLoading(false);
                            }
                        })
                });
        }
    }, [auth, dispatch, t]);

    return (
        <Container>
            {
                isLoading ?
                    <Loader /> :
                    <ModernisationsView projects={modernisationProjects} />
            }
        </Container>
    );
}

export default ModernisationsContainer;