import db from "./dexie/driverappdb";

export function detectTargetArticleForAlias(aliasArticleId) {
    return new Promise(async (resolve, reject) => {
        await db.transaction('r', db.articles, async () => {
            let aliasArticle = await db.articles.get(aliasArticleId);
            while(aliasArticle != null && aliasArticle.aliasArticleId != null) {
                aliasArticle = await db.articles.get(aliasArticle.aliasArticleId);
            }
    
            resolve(aliasArticle);
        });
    });
}

export function lookUpTranslatedDescription(article, abbreviation) {
    var translation;

    if (article != null && article.descriptionTranslations != null && article.descriptionTranslations.length > 0) {
        translation = article.descriptionTranslations.find(t => t.language?.abbreviation.toLowerCase() === abbreviation.toLowerCase());
        
        // Fallback for a language which doesn't exists inside the labels.
        if (translation == null)
            translation = article.descriptionTranslations[0];
    }

    return translation?.translatedTerm;
}