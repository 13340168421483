import { groupBy } from "../../../components/remarks/utils";
import { DropStatus } from "../../enums/drop-status";
import { PlanningItemStatus } from "../../enums/planning-item-status";
import { PlanningItemType } from "../../enums/planning-item-type";
import db from "../driverappdb";
import queries from "../queries";
import { BufferSlotRepository } from "./bufferslot-repository";
import { DropRepository } from "./drop-repository";
import { UnproductiveTimeRepository } from "./unproductive-time-repository";

export const RoutePlanRepository = {
    getItemsWithPayload: async (routeId, showFinishedDeliveries = false, showFinishedBufferSlots = false, showFinishedUnproductiveTimes = false) => {
        let routePlan = await db.routeplans.get(routeId);
        if (routePlan == null)
            return [];

        // Attach the local data of the routeplanitems so we can filter on status
        routePlan.items = (await queries.joinRoutePlanItemData(routePlan.id, (routePlan.items ?? [])))
            .filter(item => 
                (
                    (showFinishedDeliveries && item.type === PlanningItemType.DROP) || 
                    (showFinishedBufferSlots && item.type === PlanningItemType.BUFFERSLOT) ||
                    (showFinishedUnproductiveTimes && item.type === PlanningItemType.UNPRODUCTIVE_TIME)
                ) || 
                item.status !== PlanningItemStatus.FINISHED
            );
            
        // Filter out the planning items which are not visible (according to the requested states: ex. ShowFinishedDeliveries)
        let drops = await DropRepository.getDropsForRoute(routeId);
        let dropIds = drops.map(drop => drop.id);
        routePlan.items = (routePlan.items ?? []).filter(item => item.type !== PlanningItemType.DROP || dropIds.indexOf(item.payloadId) !== -1);

        let bufferSlots = await BufferSlotRepository.getBufferSlots(routeId);
        let bufferSlotIds = bufferSlots.map(bufferSlot => bufferSlot.id);
        routePlan.items = (routePlan.items ?? []).filter(item => item.type !== PlanningItemType.BUFFERSLOT || bufferSlotIds.indexOf(item.payloadId) !== -1);

        let unproductiveTimes = await UnproductiveTimeRepository.getUnproductiveTimes(routeId);
        let unproductiveTimeIds = unproductiveTimes.map(unproductiveTime => unproductiveTime.id);
        routePlan.items = (routePlan.items ?? []).filter(item => item.type !== PlanningItemType.UNPRODUCTIVE_TIME || unproductiveTimeIds.indexOf(item.payloadId) !== -1);

        let routePlanItemsByType = groupBy(routePlan.items ?? [], 'type');
        let types = Object.keys(routePlanItemsByType).map(t => parseInt(t));

        // Because the payload of the planning items is saved accross multiple tables, we need to reattach the payload to the planning item
        for(var type of types) {
            switch (type) {
                case PlanningItemType.DROP:
                    for (let drop of drops) {
                        let dropPlanItem = routePlanItemsByType[type]?.find(item => item.payloadId === drop.id);
                        if (dropPlanItem != null)
                            dropPlanItem.payload = drop;
                    }
                    break;
                case PlanningItemType.BUFFERSLOT:
                    for (let bufferSlot of bufferSlots) {
                        let bufferSlotPlanItem = routePlanItemsByType[type]?.find(item => item.payloadId === bufferSlot.id);
                        if (bufferSlotPlanItem != null)
                            bufferSlotPlanItem.payload = bufferSlot;
                    }
                    break;
                case PlanningItemType.UNPRODUCTIVE_TIME:
                    for (let unproductiveTime of unproductiveTimes) {
                        let unproductiveTimePlanItem = routePlanItemsByType[type]?.find(item => item.payloadId === unproductiveTime.id);
                        if (unproductiveTimePlanItem != null)
                            unproductiveTimePlanItem.payload = unproductiveTime;
                    }
                    break;
                default:
                    break;
            }
        }

        return routePlan.items;
    },
    hasBusyPlanItem: async (routePlanId) => {
        let routePlan = await db.routeplans.get(routePlanId);
        if (routePlan == null)
            return false;

        // Attach the local data of the routeplanitems so we can filter on status
        return (await queries.joinRoutePlanItemData(routePlan.id, (routePlan.items ?? [])))
            .filter(item => item.status === PlanningItemStatus.IN_PROGRESS)
            .length > 0;
    },
    hasBusyPlanItemAcrossRoutes: async () => {
        return await db.routeplanitemdata.where({ status: PlanningItemStatus.IN_PROGRESS }).count() > 0;
    },
    /**
     * Counts the number of planning items which still needs to be executed before the route can be finished. 
     * @param {*} routePlanId 
     * @returns 
     */
    countOpenPlanningItems: async (routePlanId) => {
        let openPlanningItems = await db.routeplanitemdata.where({routePlanId: routePlanId, status: PlanningItemStatus.TO_DO}).toArray();

        const [otherPlanningItems, dropPlanningItems, ] = openPlanningItems.reduce(([otherPlanningItems, dropPlanningItems, unproductiveTimeItems], item) => {
            if (item.type === PlanningItemType.DROP) {
                dropPlanningItems.push(item);
            } else if (item.type === PlanningItemType.UNPRODUCTIVE_TIME) {
                unproductiveTimeItems.push(item);
            } else {
                otherPlanningItems.push(item);
            }

            return [otherPlanningItems, dropPlanningItems, unproductiveTimeItems];
        }, [[], [], []]);

        let dropIds = (dropPlanningItems ?? []).map(item => item.payloadId);
        let drops = await DropRepository.getDrops(dropIds);

        let resultCount = otherPlanningItems.length; // Do not count the unproductive times!

        // Do not count the drops which are interrupted, because their planning item has also the status 'TO DO' because they can be reopened.
        resultCount += drops.filter(d => d.status !== DropStatus.INTERRUPTED).length;

        return resultCount;
    },
    getProgressStatistics: async (routeIds) => {
        let routePlans = await db.routeplans.where('id').anyOf(routeIds).toArray();
        let routes = await Promise.all(routePlans.map(async (routePlan) => ({ routeId: routePlan.id, routePlanItems: await queries.joinRoutePlanItemData(routePlan.id, routePlan.items ?? [])})));
        
        let statistics = {};
        for (let { routeId, routePlanItems} of routes) {
            let drops = routePlanItems.filter(item => item.type === PlanningItemType.DROP);
            let bufferSlots = routePlanItems.filter(item => item.type === PlanningItemType.BUFFERSLOT);
            let unproductiveTimes = routePlanItems.filter(item => item.type === PlanningItemType.UNPRODUCTIVE_TIME);
            statistics[routeId] = {
                totalDrops: drops.length,
                finishedDrops: drops.filter(item => item.status === PlanningItemStatus.FINISHED).length,
                totalBufferSlots: bufferSlots.length,
                finishedBufferSlots: bufferSlots.filter(item => item.status === PlanningItemStatus.FINISHED).length,
                totalUnproductiveTimes: unproductiveTimes.length,
                finishedUnproductiveTimes: unproductiveTimes.filter(item => item.status === PlanningItemStatus.FINISHED).length
            }
        }

        return statistics;
    }
};