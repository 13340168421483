import { useEffect } from "react";
import Loader from "../../../components/loader/Loader";
import PlanningView from "./planning-view";
import { RoutePlanningProvider, initialRoutePlanningContextState } from "../../../utils/contexts/route-planning-context";
import { useParams } from "react-router-dom";
import { useApplicationSettings } from "../../../hooks/app-settings";
import { useApplicationParams } from "../../../hooks/app-params";
import { useReducer } from "react";
import { RouteRepository } from "../../../utils/dexie/repositories/route-repository";
import { PlanningItemType } from "../../../utils/enums/planning-item-type";
import { RoutePlanRepository } from "../../../utils/dexie/repositories/route-plan-repository";
import { DropTypeRepository } from "../../../utils/dexie/repositories/drop-type-repository";
import { ProjectTypeRepository } from "../../../utils/dexie/repositories/project-type-repository";
import utils from "../../../utils";
import { useLiveQuery } from "dexie-react-hooks";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

const stateHandler = (state, action) => {
    switch (action.type) {
        case 'loaded':
            const { planningItems, ...otherPayload } = action.payload;
            return {
                ...state,
                ...otherPayload,
                planningItems: planningItems ?? [],
                filteredPlanningItems: planningItems ?? []
            };
        case 'filter':
            let filter = action.payload;
            let filteredPlanningItems = state.planningItems
                .filter(planningItem => {
                    if (filter.text === '')
                        return true;

                    let filterText = filter.text.toLowerCase();
                    switch (planningItem.type) {
                        case PlanningItemType.DROP:
                            let drop = planningItem.payload;
                            return drop.location.name.toLowerCase().includes(filterText) || drop.location.address.toLowerCase().includes(filterText);
                        case PlanningItemType.BUFFERSLOT:
                            let bufferSlot = planningItem.payload;
                            let bufferSlotResult = bufferSlot.title?.toLowerCase().includes(filterText) || bufferSlot.description?.toLowerCase().includes(filterText);

                            if (bufferSlot.locationId != null)
                                bufferSlotResult = bufferSlotResult || (bufferSlot.location != null && bufferSlot.location.address.toLowerCase().includes(filterText));

                            return bufferSlotResult;
                        default:
                            break;
                    }
                    
                    return false;
                });
            return {
                ...state,
                filteredPlanningItems: filteredPlanningItems ?? []
            };
        case 'route.status':
            return {
                ...state,
                route: {
                    ...state.route,
                    status: action.payload.newStatus
                },
                openRoutesCount: action.payload.openRoutesCount
            };
        case 'planningitem.status':
            const { 
                updatedPlanningItems, 
                updatedFilteredPlanningItems, 
                openPlanningItemsCount 
            } = action.payload;

            return {
                ...state,
                openPlanningItemsCount: openPlanningItemsCount,
                planningItems : [...updatedPlanningItems],
                filteredPlanningItems: [...updatedFilteredPlanningItems]
            };
        default:
            break;
    }

    return state;
};

const PlanningContainer = () => {
    const { id } = useParams();
    const appParams = useApplicationParams();
    const [getSetting] = useApplicationSettings();
    
    const routeId = parseInt(id);
    const route = useLiveQuery(() => RouteRepository.getRouteById(routeId), [routeId]);

    const [routePlanningState, dispatch] = useReducer(stateHandler, {
        ...initialRoutePlanningContextState,
        routeId: routeId
    });

    const showFinishedDeliveries = utils.toBoolean(getSetting('ShowFinishedDeliveries'));
    const showFinishedBufferSlots = utils.toBoolean(getSetting('ShowFinishedBufferSlots'));
    const showFinishedUnproductiveTimes = utils.toBoolean(getSetting('ShowFinishedUnproductiveTimes'));
    const showFinishedItems = showFinishedDeliveries || showFinishedBufferSlots || showFinishedUnproductiveTimes;

    const queryResult = useLiveQuery(async () => {
        const planningItemsQueryResult = await RoutePlanRepository.getItemsWithPayload(routeId, showFinishedDeliveries, showFinishedBufferSlots, showFinishedUnproductiveTimes);
        
        // Filter out the drops
        const drops = planningItemsQueryResult.filter(item => item.type === PlanningItemType.DROP);

        // Helper function to get only unique values
        const distinct = (value, index, array) => array.indexOf(value) === index;

        // Get all dropTypes which are related to at least one of the drops
        const dropTypeIds = drops.filter(d => d.payload.dropType != null).map(d => d.payload.dropType).filter(distinct);
        const determineDropTypes = DropTypeRepository.getDropTypes(dropTypeIds).then(dropTypes => dropTypes.filter(dt => dt != null));

        // Get all projectTypes which are related to at least one of the drops
        const projectTypeIds = drops.filter(d => d.payload.projectType != null).map(d => d.payload.projectType).filter(distinct);
        const determineProjectTypes = ProjectTypeRepository.getProjectTypes(projectTypeIds).then(projectTypes => projectTypes.filter(pt => pt != null));

        return Promise.all([
            planningItemsQueryResult,
            determineDropTypes,
            determineProjectTypes,
            RouteRepository.countOpenRoutes(),
            RoutePlanRepository.countOpenPlanningItems(routeId)
        ]);
    }, [routeId, showFinishedDeliveries, showFinishedBufferSlots, showFinishedUnproductiveTimes]);

    const [planningItems, dropTypes, projectTypes, openRoutesCount, openPlanningItemsCount] = queryResult ?? [[], [], [], 0, 0];

    const isLoading = route === undefined || !queryResult;

    useEffect(() => {
        if (!isLoading && route != null) {
            dispatch({
                type: 'loaded',
                payload: {
                    route: route,
                    startLocation: route.startLocation,
                    stopLocation: route.stopLocation,
                    planningItems: planningItems,
                    dropTypes: dropTypes ?? [],
                    projectTypes: projectTypes ?? [],
                    openRoutesCount,
                    openPlanningItemsCount,
                    navigationApp: getSetting('NavigationApp'),
                    showFinishedItems: showFinishedItems,
                    routeAutoStart: appParams?.routeAutoStart,
                    pauseAllowed: appParams?.pauseAllowed,
                    skipAllowed: appParams?.skipAllowed,
                    externalStatusManagement: appParams?.externalStatusManagement,
                    autoAcceptTimeRegistrations: appParams?.autoAcceptTimeRegistrations,
                    useTimeRegistrations: appParams?.timeRegistrations,
                    displayDepartureTime: appParams?.displayDepartureTime
                }
            });
        }
    }, [isLoading, dispatch, route, planningItems, dropTypes, projectTypes, appParams, showFinishedItems, getSetting, openPlanningItemsCount, openRoutesCount]);

    if (route !== undefined && route == null) {
        // The route query has been finished but the route has not been found
        return <Redirect to="/routes" />;
    }

    return (
        isLoading ?
            <Loader /> : 
            <RoutePlanningProvider value={routePlanningState} dispatch={dispatch}>
                <PlanningView />
            </RoutePlanningProvider>
    );
};

export default PlanningContainer;