import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, CardHeader, CardContent, Grid, makeStyles, Typography } from "@material-ui/core";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import QuantityChanger from "../../../../components/quantityChanger";
import { FontAwesomeDuoToneStyle } from "../../../../fontawesome";
import { primaryColor } from "../../../../themes/litefleet";
import db from "../../../../utils/dexie/driverappdb";
import { lookUpTranslatedDescription } from "../../../../utils/article";

const useReturnableViewStyles = makeStyles(theme => ({
    returnableRoot: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        marginBottom: 15
    },
    returnableHeader: {
        flexGrow: 1
    },
    returnableManagerWrapper: {
        display: 'flex',
        flexDirection: 'column'
    },
    readOnlyItemWrapper: {
        display: 'flex',
        marginBottom: 15
    },
    readOnlyIconWrapper: {
        paddingRight: '15px !important'
    },
    readOnlyContentWrapper: {
        flexGrow: 1
    }
}));

const ReturnableView = ({ children, readonly, returnable }) => {
    const [, i18n] = useTranslation();
    const classes = useReturnableViewStyles();

    return (
        <>
            {
                !readonly ?
                    <Card className={classes.returnableRoot}>
                        <CardHeader title={returnable.name} subheader={lookUpTranslatedDescription(returnable, i18n.language)} className={classes.returnableHeader} />
                        <CardContent>
                            { children }
                        </CardContent>
                    </Card> :
                    <Box className={classes.readOnlyItemWrapper}>
                        <Box className={classes.readOnlyIconWrapper}>
                            <FontAwesomeIcon icon={["fas", "caret-right"]} size="lg" style={FontAwesomeDuoToneStyle} color={primaryColor.main} />
                        </Box>
                        <Box className={classes.readOnlyContentWrapper}>
                            <Box fontWeight={700}>
                                <Typography variant="inherit">{returnable.name}</Typography>
                            </Box>
                            <Typography variant="body1" color="textSecondary">{lookUpTranslatedDescription(returnable, i18n.language)}</Typography>
                            { children }
                        </Box>
                    </Box>
            }
        </>
    );

}

const ReturnableManager = forwardRef(({ readonly, returnable }, ref) => {
    const [t,] = useTranslation();
    const [deliveredQuantityIn, setDeliveredQuantityIn] = useState(returnable.data.deliveredQuantityIn || 0);
    const [deliveredQuantityOut, setDeliveredQuantityOut] = useState(returnable.data.deliveredQuantityOut || 0);

    useImperativeHandle(ref, () => ({

        getUpdatedValues: () => ({ 
            returnableId: returnable.id, 
            deliveredQuantityIn: deliveredQuantityIn,
            deliveredQuantityOut: deliveredQuantityOut
        })

    }));

    return (
        <ReturnableView readonly={readonly} returnable={returnable}>
            <Grid container spacing={3}>
                {
                    !readonly ?
                        <>
                            {
                                returnable.displayOut ?
                                    <Grid item xs={6}>
                                        <QuantityChanger label={t("QuantityOut")} value={deliveredQuantityOut} minValue={0} 
                                            onChange={(newDeliveredQuantityOut) => setDeliveredQuantityOut(newDeliveredQuantityOut)} />
                                    </Grid> : <></>
                            }
                            {
                                returnable.displayIn ?
                                    <Grid item xs={6}>
                                        <QuantityChanger label={t("QuantityIn")} value={deliveredQuantityIn} minValue={0} 
                                            onChange={(newDeliveredQuantityIn) => setDeliveredQuantityIn(newDeliveredQuantityIn)} />
                                    </Grid> : <></>
                            }
                        </> :
                        <Grid item xs={12}>
                            {
                                returnable.displayOut ?
                                    <Typography>{t("QuantityOut")}: {deliveredQuantityOut ?? 0}</Typography> : <></>
                            }
                            {
                                returnable.displayIn ?
                                    <Typography>{t("QuantityIn")}: {deliveredQuantityIn ?? 0}</Typography> : <></>
                            }
                        </Grid>
                }
            </Grid>
        </ReturnableView>
    );

});

const ReturnablesManagerView = forwardRef(({ dropId, returnables, readonly }, ref) => {
    const [t,] = useTranslation();
    const classes = useReturnableViewStyles();
    const [returnableRefs, setReturnableRefs] = useState([]);

    useImperativeHandle(ref, () => ({
        save: () => {
            // Save the returnables
            if (returnableRefs != null && returnableRefs.length > 0) {
                let updatedValues = [];
                returnables.forEach((returnable, i) => {
                    if (returnableRefs[i] != null) {
                        updatedValues.push(returnableRefs[i].getUpdatedValues());
                    }
                });

                updatedValues.forEach(r => {
                    db.returnablesdata.where({ dropId: dropId, returnableId: r.returnableId })
                        .modify({ 
                            deliveredQuantityIn: r.deliveredQuantityIn,
                            deliveredQuantityOut: r.deliveredQuantityOut
                        });
                });
            }
        }
    }));

    return (
        <Grid container>
            <Grid item xs={12}>
                {
                    (!returnables || returnables.length === 0) ?
                        <Typography gutterBottom align='center'>
                            {t("NoArticlesAvailable")}
                        </Typography> :
                        <Grid container spacing={3}>
                            {
                                returnables.map((returnable, i) => 
                                    <Grid item xs={12} md={6} key={returnable.id} className={classes.returnableManagerWrapper}>
                                        <ReturnableManager readonly={readonly} returnable={returnable} ref={(ref) => {
                                            setReturnableRefs(currentReturnableRefs => {
                                                if (ref != null && currentReturnableRefs != null)
                                                    currentReturnableRefs[i] = ref;
                                                return currentReturnableRefs;
                                            });
                                        }} />
                                    </Grid>
                                )
                            }
                        </Grid>
                }
            </Grid>
        </Grid>
    );

});

export default ReturnablesManagerView;