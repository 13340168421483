import { useHistory } from "react-router-dom";
import queries from "../../../../utils/dexie/queries";
import { DateUtils } from "../../../../utils/date-utils";
import db from "../../../../utils/dexie/driverappdb";
import utils from "../../../../utils";
import { DropStatus } from "../../../../utils/enums/drop-status";
import { usePlanItemStatusManagement } from "../../../../hooks/plan-item-status-management";
import { PlanningItemType } from "../../../../utils/enums/planning-item-type";
import { PlanningItemStatus } from "../../../../utils/enums/planning-item-status";
import { Actions } from "../../../../utils/enums/actions";

export function useDropStatusManagement(routeId, dropId, hashCode) {
    const history = useHistory();
    const updateRoutePlanningItemStatus = usePlanItemStatusManagement(routeId, PlanningItemType.DROP, dropId, hashCode);

    const updateDropStatus = async (newStatus) => {
        try {
            let actionType;
            let planningItemStatus;
            switch (newStatus) {
                case DropStatus.BUSY:
                    actionType = Actions.DROP_START;
                    planningItemStatus = PlanningItemStatus.IN_PROGRESS;
                    break;
                default:
                    break;
            }

            if (actionType) {
                let now = new Date();
                let nowUTC = DateUtils.convertLocalToUTC(now);

                let updateDropStatusAction = {
                    type: actionType, 
                    key: dropId, 
                    time: nowUTC
                };

                let isAllowed = await queries.isActionAllowed(updateDropStatusAction);
                if (isAllowed) {
                    let drop = await db.drops.get(dropId);
                    if (drop != null) {
                        let updatedDropData = {
                            status: newStatus
                        };
                        await db.dropdata.update(dropId, updatedDropData);
    
                        let updatedRouteData = {
                            delay: utils.calculateDelay(new Date(drop.eta), now)
                        };
                        await db.routes.update(routeId, updatedRouteData);

                        await updateRoutePlanningItemStatus(planningItemStatus);
                        await db.actions.put(updateDropStatusAction);
                    }
                }
            }
        } catch(error) {
            queries.addErrorToLogs(error);
            history.push('/error');
        }
    };

    return updateDropStatus;
};