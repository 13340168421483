import React, { useEffect } from 'react';
import { useAppContext } from '../../utils/contexts/appContext';
import db from '../../utils/dexie/driverappdb';

const DataReloader = () => {
    const [{ changesDetected }, dispatch] = useAppContext();

    useEffect(() => {

        if (changesDetected) {
            db.notifications.clear()
                .then(result => {
                    dispatch({
                        type: 'changesDetection',
                        changesDetected: false
                    });
                    
                    window.location.reload();
                });
        }

    }, [changesDetected, dispatch]);

    return <></>;
};

export default DataReloader;