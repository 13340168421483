import React, { createContext, Component } from 'react';
import AuthService from '../services/AuthService';

export const AuthContext = createContext({
    signinRedirectCallback: () => ({}),
    logout: () => ({}),
    signoutRedirectCallback: () => ({}),
    isAuthenticated: () => ({}),
    signinRedirect: () => ({}),
    signinSilentCallback: () => ({}),
    createSigninRequest: () => ({}),
    getUser: () => ({}),
    fetch: () => ({})
});

export const AuthConsumer = AuthContext.Consumer;

export class AuthProvider extends Component {

    authService;
    
    constructor(props) {
        super(props);
        this.authService = new AuthService();
    }

    render() {        
        return (
            <AuthContext.Provider value={this.authService}>
                { this.props.children }
            </AuthContext.Provider>
        );
    }

}