import { useEffect } from "react";
import { useState } from "react";
import db from "../utils/dexie/driverappdb";
import { useCallback } from "react";

export function useApplicationSettings() {
    const [appSettings, setAppSettings] = useState(null);

    const getSetting = useCallback(name => {
        return appSettings != null ? appSettings[name] : null;
    }, [appSettings]);

    useEffect(() => {

        async function querySettings() {
            let settingsMap = {};
            let settings = await db.settings.toArray();

            for (let setting of settings) {
                settingsMap[setting.name] = setting.value;
            }

            setAppSettings(settingsMap);
        };

        querySettings();

    }, []);

    return [getSetting, appSettings];
}