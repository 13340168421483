import React from "react";
import { AuthConsumer } from "../../providers/AuthProvider";
import Loader from "../loader";

export const SilentRenew = () => (
    <AuthConsumer>
        {({ signinSilentCallback }) => {
            signinSilentCallback();
            return <Loader />;
        }}
    </AuthConsumer>
);