import React, { useEffect } from 'react';
import { Waiter } from 'react-wait';
import { AuthProvider, AuthConsumer } from "../../providers/AuthProvider";
import { UserAgentProvider, UserAgentConsumer } from "../../providers/UserAgentProvider";
import { AppProvider } from '../../utils/contexts/appContext';
import ConnectionChecker from '../connectionChecker';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import FirebaseManager from '../../cloud-messaging/firebase-manager';
import { CssBaseline, AppBar, Typography, Box } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import Header from '../header/Header';
import Drawer from '../drawer/Drawer';
import ErrorBoundary from '../errorBoundary';
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute } from '../../utils/routes/privateRoute';
import { Callback } from "../auth/callback";
import { Logout } from "../auth/logout";
import { LogoutCallback } from "../auth/logoutCallback";
import { SilentRenew } from "../auth/silentRenew";
import Home from '../../pages/home';
import Admin from '../../pages/admin';
import Routes from '../../pages/routes';
import Modernisations from '../../pages/modernisations';
import Timeline from '../../pages/routes/timeline';
import Arrive from '../../pages/routes/arrive';
import Job from '../../pages/routes/job';
import Skip from '../../pages/routes/skip';
import Info from '../../pages/routes/info';
import RouteOverview from '../../pages/routes/overview';
import Settings from '../../pages/settings';
import Navigation from '../../pages/settings/navigation';
import Permissions from '../../pages/settings/permissions';
import Logs from '../../pages/settings/logs';
import Error from '../../pages/error';
import DataManagement from '../../pages/settings/data-management';
import System from '../../pages/settings/system';
import ArticlesCatalog from '../../pages/routes/job/articlesCatalog';
import UsageChecker from '../usageChecker/UsageChecker';
import RoutePlanning from '../../pages/routes/planning';
import VisibilitySettings from '../../pages/settings/visibility';
import { DriverAppDbMigrator } from '../../utils/dexie/driverappdb';

const useStyles = makeStyles(theme => ({
  info: { 
    backgroundColor: '#001b39' 
  },
  contentOffset: theme.mixins.toolbar,
  bottomBar : {
    top: 'auto',
    bottom: 0,
    backgroundColor: theme.palette.warning.main,
    padding: '10px 20px'
  }
}));

const LocationPermissionTrigger = () => {

  useEffect(() => {
    // Check if the browser supports location
    if (navigator.geolocation) {
      // Triggers the request to access the location on initial page load.
      navigator.geolocation.getCurrentPosition(() => {});
    }
  }, []);

  return <></>;
}

const App = () => {
  const classes = useStyles();
  const [t] = useTranslation();

  useEffect(() => DriverAppDbMigrator.open(), []);

  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      maxSnack={3}
      classes={{ variantInfo: classes.info }}>
      <Waiter>
        <AuthProvider>
          <UserAgentProvider>
            <AppProvider>
              <FirebaseManager>
                <ConnectionChecker></ConnectionChecker>
                <UsageChecker></UsageChecker>
                <CssBaseline />
                <Box display='flex'>
                  <AuthConsumer>
                    { 
                      auth => auth.isAuthenticated() ? 
                        <>
                          <LocationPermissionTrigger />
                          <Header />
                          <Drawer />
                        </> : <></> 
                    }
                  </AuthConsumer>
                  <Box component='main' style={{minHeight: '100vh', width: '100%'}} display='flex' flexDirection='column'>
                    <div className={classes.contentOffset}></div>
                    <ErrorBoundary>
                      <Switch>
                        <Route path="/signin-oidc" exact component={Callback} />
                        <Route path="/logout" exact component={Logout} />
                        <Route path="/logout/callback" exact component={LogoutCallback} />
                        <Route path="/silentrenew" exact component={SilentRenew} />
                        <PrivateRoute path="/error" exact component={Error} />
                        <PrivateRoute path="/routes" exact component={Routes} />
                        <PrivateRoute path="/routes/:id" exact component={RoutePlanning} />
                        <PrivateRoute path="/routes/:routeId/overview" exact component={RouteOverview} />
                        <PrivateRoute path="/routes/timeline/:routeId" exact component={Timeline} />
                        <PrivateRoute path="/routes/timeline/arrive/:dropId" exact component={Arrive} />
                        <PrivateRoute path="/routes/timeline/arrive/:dropId/catalog" exact component={ArticlesCatalog} />
                        <PrivateRoute path="/routes/timeline/job/:dropId" exact component={Job} />
                        <PrivateRoute path="/routes/timeline/job/:dropId/catalog" exact component={ArticlesCatalog} />
                        <PrivateRoute path="/routes/timeline/skip/:dropId" exact component={Skip} />
                        <PrivateRoute path="/routes/timeline/skip/:dropId/catalog" exact component={ArticlesCatalog} />
                        <PrivateRoute path="/routes/timeline/info/:dropId" exact component={Info} />
                        <PrivateRoute path="/modernisations" exact component={Modernisations}/>
                        <PrivateRoute path="/settings" exact component={Settings} />
                        <PrivateRoute path="/settings/navigation/:id/:value" exact component={Navigation} />
                        <PrivateRoute path="/settings/visibility" exact component={VisibilitySettings} />
                        <PrivateRoute path="/settings/permissions" exact component={Permissions} />
                        <PrivateRoute path="/settings/logs" exact component={Logs} />
                        <PrivateRoute path="/settings/data-management" exact component={DataManagement} />
                        <PrivateRoute path="/settings/system" exact component={System} />
                        <PrivateRoute path="/admin" exact component={Admin} />
                        <PrivateRoute path="/" component={Home} />
                      </Switch>
                    </ErrorBoundary>
                    <UserAgentConsumer>
                        {
                          userAgent => {
                            let browserSupport = userAgent.checkBrowserSupport();
                            return (!browserSupport.supported ? 
                              <>
                                <AppBar position="fixed" className={classes.bottomBar}>
                                  <Typography variant="h6">{t("BrowserNotSupported")}</Typography>
                                  <Typography variant="body1" dangerouslySetInnerHTML={{ __html: t("BrowserRecommendation").replace("{0}", browserSupport.recommendedBrowser) }}></Typography>
                                </AppBar>
                              </> : <></>)
                          }
                        }
                    </UserAgentConsumer>
                  </Box>
                </Box>
              </FirebaseManager>
            </AppProvider>
          </UserAgentProvider>
        </AuthProvider>
      </Waiter>
    </SnackbarProvider>
  );
}

export default App;