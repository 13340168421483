import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@material-ui/core";
import { forwardRef } from "react";
import { useHistory } from "react-router-dom";

const InformationButton = forwardRef(({ dropId }, ref) => {
    let history = useHistory();

    return (
        <Button ref={ref} color="primary" onClick={() => history.push(`/routes/timeline/info/${dropId}`)}>
            <FontAwesomeIcon size="lg" icon={["fad", "info"]} />
        </Button>
    );
});

export default InformationButton;