import React, { useState, useEffect } from 'react';
import PermissionsView from './PermissionsView';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../../utils/contexts/appContext';
import Loader from '../../../components/loader/Loader';
import queries from '../../../utils/dexie/queries';

const PermissionsContainer = () => {
    const [t,] = useTranslation();
    const [, dispatch] = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [permissions,setPermissions] = useState([]);

    useEffect(() => {
        dispatch({ type: 'changeHeader', header: { title: t("AppPermissions"), child: true } });
        loadPermissionStates([ 'geolocation', 'notifications' ])
            .finally(() => setIsLoading(false));
    }, [dispatch, t]);

    const loadPermissionStates = async (permissions) => {
        const permissionsApiSupported = navigator.permissions && navigator.permissions.query;
        var result = [];

        for (const permissionName of permissions) {
            try {

                if (permissionsApiSupported) {
                    await navigator.permissions.query({ name: permissionName })
                        .then(permissionResult => result.push({ name: permissionName, state: permissionResult.state }))
                        .catch(error => {
                            queries.addErrorToLogs(error);
                            result.push({ name: permissionName, state: 'denied' });
                        });
                } else {
                    // Fallback for browsers who doesn't support the Permissions API yet
                    switch(permissionName) {
                        case 'geolocation':
                            // Checks whether Geolocation is supported by this browser 
                            if (navigator.geolocation) { 
                                await new Promise((resolve, reject) => {
                                    navigator.geolocation.getCurrentPosition(
                                        location => {
                                            result.push({ name: permissionName, state: 'granted' });
                                            resolve();
                                        },
                                        error => reject(error),
                                        { 
                                            enableHighAccuracy: true,
                                            timeout: 1000
                                        }
                                    );
                                });
                            } else {
                                queries.addErrorToLogs(new Error("Geolocation is not supported by this browser."));
                                result.push({ name: permissionName, state: 'denied' });
                            }
                            break;
                        case 'notifications':
                            if (typeof Notification !== 'undefined') {
                                await Notification.requestPermission()
                                    .then(state => result.push({ name: permissionName, state: state === 'default' ? 'prompt' : state }))
                                    .catch(error => {
                                        queries.addErrorToLogs(error);
                                        result.push({ name: permissionName, state: 'denied' });
                                    });
                            } else {
                                result.push({ name: permissionName, state: 'denied' });
                            }
                            break;
                        default:
                            break;
                    }
                }

            } catch (error) {
                queries.addErrorToLogs(error);
                result.push({ name: permissionName, state: 'denied' });
            }
        }

        setPermissions(result);
    }

    return (
        <div className="global-container">
            {
                isLoading ?
                    <Loader /> :
                    <PermissionsView permissions={permissions} />
            }
        </div>
    );
};

export default PermissionsContainer;