const _toBoolean = (integerValue) => {
    return integerValue === 1 ? true : false;
};

const _toInteger = (booleanValue) => {
    return booleanValue === true ? 1 : 0;
};

const _toLocalTimeZone = (date) => {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
};

const _toLocaleString = (date) => {
    let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        fractionalSecondDigits: 3
    };

    return date.toLocaleString(navigator.language, options);
};

const _capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
};

const _calculateDelay = (originalDateTime, currentDateTime) => {
    let originalTimeInMinutes = originalDateTime.getHours() * 60 + originalDateTime.getMinutes();
    let currentTimeInMinutes = currentDateTime.getHours() * 60 + currentDateTime.getMinutes();
    return currentTimeInMinutes - originalTimeInMinutes;
};

const _getDelayString = (minutes) => {
    var prefix = "+ ";
    if (minutes < 0)
        prefix= "- ";
    var absMinutes = Math.abs(minutes);
    var h = Math.floor(absMinutes / 60);
    var m = absMinutes % 60;
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    return prefix + h + ':' + m;
}

const _isLiveEnvironment = () => {
    return process.env.NODE_ENV === 'production'; // Checks if we're running on a live environment (test, staging, production)
}

const _addStatusRelatedTags = (statusCode, tags = []) => {
    if (statusCode == null)
        return tags;

    let unprocessableEntity = statusCode === 422;
    return [...tags, ...(unprocessableEntity ? ['UNPROCESSIBLE ENTITY'] : [])];
};

const utils = {
    toBoolean: _toBoolean,
    toInteger: _toInteger,
    toLocalTimeZone: _toLocalTimeZone,
    toLocaleString: _toLocaleString,
    capitalize: _capitalize,
    calculateDelay: _calculateDelay,
    getDelayString: _getDelayString,
    isLiveEnvironment: _isLiveEnvironment,
    addStatusRelatedTags: _addStatusRelatedTags
}

export default utils;