import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PlanningItemBody from "../planning-item-body";
import PlanningItemContent from "../planning-item-content";
import PlanningTimelineItem from "../planning-timeline-item";
import { useTranslation } from "react-i18next";
import PlanningItemHeader from "../planning-item-header";
import PlanningItemHeaderBadge from "../planning-item-header-badge";
import { FontAwesomeDuoToneStyle } from "../../../../../fontawesome";
import { Typography, useTheme } from "@material-ui/core";
import { DateUtils } from "../../../../../utils/date-utils";
import PlanningItemActions from "../planning-item-actions";
import UpdateStatusButton from "../action-buttons/update-status-button";
import { useRouteAutoStart } from "../../../../../hooks/route-auto-start";
import { useUnproductiveTimeStatusManagement } from "../../hooks/unproductive-time-status-management";
import { PlanningItemStatus } from "../../../../../utils/enums/planning-item-status";
import { useContext, useState } from "react";
import { AppContext } from "../../../../../utils/contexts/appContext";
import { useEffect } from "react";
import PlanningItemDelay from "../planning-item-delay";
import PauseDialog from "../../../../../components/pause-dialog";
import { useRef } from "react";
import { useSinglePress } from "../../../../../hooks/single-press";

const UnproductiveTimeItem = ({ 
    planningItem, 
    readOnly = true
}) => {
    const theme = useTheme();
    const [routeAutoStartEnabled, autoStartRoute] = useRouteAutoStart(planningItem);
    const { status: planningItemStatus, payload: unproductiveTime, hashCode } = planningItem;

    const [t] = useTranslation();
    const [, dispatchApp] = useContext(AppContext);
    const updateUnproductiveTimeStatus = useUnproductiveTimeStatusManagement(unproductiveTime.id, unproductiveTime.routeId, hashCode);
    const [executing, setExecuting] = useState(false);
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const dialogRef = useRef(null);

    useEffect(() => {
        setExecuting(planningItemStatus === PlanningItemStatus.IN_PROGRESS);
    }, [planningItemStatus]);

    const handleClickOnPauseButton = useSinglePress(async () => {
        if (executing) {
            await onClickStop();
        } else {
            await dialogRef.current.resetDialog()
                .finally(() => setDialogIsOpen(true));
        }
    });

    const onClickStart = useSinglePress(async (breakType, remark) => {
        if (routeAutoStartEnabled)
            await autoStartRoute();
        
        await updateUnproductiveTimeStatus(PlanningItemStatus.IN_PROGRESS, breakType, remark?.value)
            .then(() => dispatchApp({ 
                type: 'togglePauseByPlanning', 
                pause: true, 
                pauseId: unproductiveTime.id 
            }))
            .finally(() => setDialogIsOpen(false));
    });

    const onClickStop = async () => {
        await updateUnproductiveTimeStatus(PlanningItemStatus.FINISHED)
            .then(() => dispatchApp({ 
                type: 'togglePauseByPlanning', 
                pause: false, 
                pauseId: unproductiveTime.id 
            }));
    };

    const getTimelineItemIconProps = () => {
        const alternativeOpacity = .5;

        let icon = <FontAwesomeIcon icon={["fad", "hourglass-half"]} />;
        let iconColor = theme.palette.unproductiveTime.main;
        let iconOpacity;

        switch (planningItemStatus) {
            case PlanningItemStatus.FINISHED:
                iconOpacity = alternativeOpacity;
                break;
            default:
                break;
        }

        return { icon, iconColor, iconOpacity };
    }

    return (
        <PlanningTimelineItem {...getTimelineItemIconProps()}>
            <PlanningItemHeader>
                <PlanningItemHeaderBadge>
                    <FontAwesomeIcon icon={["fad", "clock"]} style={FontAwesomeDuoToneStyle} />
                    <Typography component="span">
                        {DateUtils.getFormattedTimeString(new Date(unproductiveTime.startTime))} - {DateUtils.getFormattedTimeString(new Date(unproductiveTime.endTime))}
                        <PlanningItemDelay />
                    </Typography>
                </PlanningItemHeaderBadge>
            </PlanningItemHeader>
            <PlanningItemBody highlighted={planningItem.status === PlanningItemStatus.IN_PROGRESS}>
                <PlanningItemContent  translucent={planningItem.status === PlanningItemStatus.FINISHED}>
                    <Typography variant="subtitle1">{t("SuggestedBreak")}</Typography>
                </PlanningItemContent>
                <PlanningItemActions>
                    <PauseDialog 
                        ref={dialogRef}
                        isOpen={dialogIsOpen}
                        onConfirm={onClickStart}
                        onCancel={() => setDialogIsOpen(false)}>
                        <UpdateStatusButton icon={<FontAwesomeIcon size="lg" icon={["fad", executing ? "play-circle" : "pause-circle"]} /> }
                            disabled={readOnly} onClick={handleClickOnPauseButton} />
                    </PauseDialog>
                </PlanningItemActions>
            </PlanningItemBody>
        </PlanningTimelineItem>
    );
};

export default UnproductiveTimeItem;