import React, { useState, useRef } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FontAwesomeDuoToneStyle } from '../../fontawesome';

const FilterButton = ({ 
    onChange = (filter) => {},
    useIconStyle = true
}) => {

    const [t,] = useTranslation();
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [text, setText] = useState('');

    const filter = useRef({
        text: text
    });

    const updateFilter = () => {
        filter.current = { 
            text: text
        };
        onChange(filter.current);
        setDialogIsOpen(false);
    };

    const cancelFilter = async () => {
        setText('');
        filter.current = { 
            text: ''
        };
        onChange(filter.current);
        setDialogIsOpen(false);
    };

    return (
        <>
            <button onClick={() => setDialogIsOpen(true)} className="default-link">
                <FontAwesomeIcon icon={["fad", "sliders-v-square"]} size="2x" style={useIconStyle ? FontAwesomeDuoToneStyle : null} />
            </button>
            <Dialog
                open={dialogIsOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{t("Filter")}</DialogTitle>
                <DialogContent id="alert-dialog-description">
                    <TextField value={text} 
                        placeholder={t("FilterValue")}
                        onChange={(e) => setText(e.target.value)} />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={cancelFilter}>{t("Cancel")}</Button>
                    <Button color="primary" onClick={updateFilter} autoFocus>{t("Ok")}</Button>
                </DialogActions>
            </Dialog>
        </>
    );

};

export default FilterButton;