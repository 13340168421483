import { useContext } from "react";
import { RoutePlanningContext, RoutePlanningDispatchContext } from "../../../../utils/contexts/route-planning-context";
import { Card, CardActions, CardContent, Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PauseButton from "../../../../components/pauseButton/PauseButton";
import FilterButton from "../../../../components/filterButton/FilterButton";
import { RouteStatus } from "../../../../utils/enums/route-status";
import bgBanner from '../../../../assets/images/general/bg-banner.jpg';
import { DateUtils } from "../../../../utils/date-utils";
import { AppContext } from "../../../../utils/contexts/appContext";
import clsx from "clsx";
import PlanningMessages from "./planning-messages";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.dark,
        background: `url(${bgBanner}) center center`,
        backgroundSize: 'cover',
        color: theme.palette.primary.contrastText
    },
    routeActionsWrapper: {
        justifyContent: 'end'
    },
    warningBadge: {
        backgroundColor: theme.palette.warning.main,
        color: 'white',
        marginRight: 'auto'
    }
}));

const PlanningDetails = () => {
    const classes = useStyles();
    const [{ executingPlanningItem }] = useContext(AppContext);
    const dispatch = useContext(RoutePlanningDispatchContext);
    const { route, pauseAllowed } = useContext(RoutePlanningContext);

    const [t] = useTranslation();

    const onFilter = (filter) => {
        dispatch({
            type: 'filter',
            payload: filter
        });
    }

    return (
        route != null ?
            <>
                <Card elevation={2} className={clsx('planning-details', classes.root)}>
                    <CardContent>
                        <Typography variant="h6" color="primary">{route.name}</Typography>
                        <Typography variant="subtitle1">{route.vehicle}</Typography>
                        <Typography variant="subtitle1">{route.date}</Typography>
                        {
                            route.realStart ? 
                                <Typography variant="caption">
                                    {t('StartedOn')}: {DateUtils.getFormattedDateTimeString(new Date(route.realStart))}
                                </Typography> : 
                                null
                        }
                    </CardContent>
                    <CardActions className={classes.routeActionsWrapper}>
                        <PauseButton visible={pauseAllowed && route?.status === RouteStatus.BUSY && !executingPlanningItem} useIconStyle={false} />
                        <FilterButton onChange={onFilter} useIconStyle={false} />
                    </CardActions>
                </Card>
                <PlanningMessages />
            </> 
            : null
    );

};

export default PlanningDetails;