import db from '../../utils/dexie/driverappdb';
import queries from './queries';
import { version } from '../../../package.json';
import utils from '..';
import i18n, { SupportedLanguagesByDriverApp } from '../../i18n';
import { 
   convertDeliveryOrderDetailToDropDetail, convertDeliveryOrderDetailToLocalData, convertDropToLocalData, convertPlanningItemToLocalData, convertRemarkToLocalRemark, 
   convertReturnableToLocalData, convertSimulationDeliveryDetailToDropDetail, convertSimulationDeliveryDetailToLocalData, convertToLocalArticle, convertToLocalArticleInventoryLevel, convertToLocalBufferSlot, convertToLocalDrop, convertToLocalDropType, 
   convertToLocalLocation, convertToLocalRoute, convertToLocalRoutePlan, convertToLocalUnproductiveTime 
} from './converters';
import { PlanningItemType } from '../enums/planning-item-type';
import { Actions } from '../enums/actions';
import hashIt from 'hash-it';

/**********************/
/** Helper functions **/
/**********************/
const createHashValue = (str) => hashIt(str);

const hasUpdatedRoutePlans = (oldList, newList) => {
   const oldHashValues = oldList?.map(r => r.id);
   const newHashValues = newList?.map(r => r.id);

   const oldListHash = createHashValue(oldHashValues);
   const newListHash = createHashValue(newHashValues);

   return oldListHash !== newListHash;
}

const hasUpdatedRoutePlanItems = (oldList, newList) => {
   const oldHashValues = oldList?.map(route => ({
      id: route.id,
      items: (route.items ?? []).map(routePlanItem => routePlanItem.hashCode)
   }));

   const newHashValues = newList?.map(route => ({
      id: route.id,
      items: (route.items ?? []).map(routePlanItem => routePlanItem.hashCode)
   }));

   const oldListHash = createHashValue(oldHashValues);
   const newListHash = createHashValue(newHashValues);

   return oldListHash !== newListHash;
}

/************************/
/** Exported functions **/
/************************/

/**
 * Fetches all necessary data from the api's to use the app.
 * @param {*} auth 
 */
export const fetchOfflineData = async (auth) => {
   return await Promise.all([fetchDriverAppParams(auth), fetchLanguages(auth), fetchExternalStatuses(auth), fetchDropTypes(auth)])
      .then(async () => await Promise.all([fetchBreakTypes(auth), fetchProjectTypes(auth), fetchRouteStates(auth), fetchDropStates(auth)]))
      .then(() => Promise.resolve(fetchArticles(auth)))
      .then(fetchFeedbackItems(auth));
}

/**
 * Sends the offline data to the server.
 * @param {*} auth 
 */
export const syncOfflineData = async (auth) => {
   return await syncLogs(auth)
      .then(async () => await syncVersion(auth));
};

/**
 * Synchronizes the current position.
 * @param {*} auth 
 */
export const syncGeolocation = async (auth) => {
   if (navigator.onLine && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async function (location) {
         let currentPositionItem = {
            timestamp: location.timestamp,
            lat: location.coords.latitude,
            lon: location.coords.longitude,
            accuracy: location.coords.accuracy
         };

         let entryLimit = 1; // Currently only keep 1 entry at a time
         let positions = await db.positions.toArray();
         if (positions != null && positions.length >= entryLimit) {
            let deleteCandidates = await db.positions.orderBy('timestamp').reverse().offset(entryLimit).toArray();
            if (deleteCandidates != null && deleteCandidates.length > 0)
               await db.positions.bulkDelete(deleteCandidates.map(dc => dc.id));
         }

         let minAccuracy = process.env.REACT_APP_GEOLOCATION_MIN_ACCURACY != null && !isNaN(process.env.REACT_APP_GEOLOCATION_MIN_ACCURACY) ? 
            parseInt(process.env.REACT_APP_GEOLOCATION_MIN_ACCURACY) : null;

         // Fallback to the positions which are requested within the previous max lifetime.
         let maxLifeTime = process.env.REACT_APP_GEOLOCATION_MAX_LIFETIME != null && !isNaN(process.env.REACT_APP_GEOLOCATION_MAX_LIFETIME) ? 
         parseInt(process.env.REACT_APP_GEOLOCATION_MAX_LIFETIME) : 0;
         let now = new Date();
         now.setMinutes(now.getMinutes() - maxLifeTime);
         let timeStampLimit = now.getTime();

         let previousPosition = await db.positions.orderBy('timestamp').reverse().first();
         let propsAreDifferent = previousPosition != null && previousPosition.lat !== currentPositionItem.lat && 
            previousPosition.lon !== currentPositionItem.lon && previousPosition.accuracy !== currentPositionItem.accuracy;
         
         let savePosition = previousPosition == null || // There is no previously saved position OR ...
            (
               (
                  (
                     // The properties of the current position are different from the previously saved position
                     propsAreDifferent &&
                     // AND: The current accuracy is better then the accuracy of the previously saved position
                     (currentPositionItem.accuracy < previousPosition.accuracy)
                  ) || (previousPosition.timestamp <= timeStampLimit) // OR: The previously saved position is expired
               ) && (minAccuracy == null || currentPositionItem.accuracy <= minAccuracy) // AND: There is no minimum limit for the accuracy OR the accuracy of the current position is better then the minimum limit.
            );
         
         if (savePosition) {
            await db.positions.add(currentPositionItem);
         } else if (previousPosition != null && !propsAreDifferent) {
            // Update the timestamp if the properties of the current position are equal to the previously saved position
            previousPosition.timestamp = currentPositionItem.timestamp;
            await db.positions.put(previousPosition);
         }

         auth.getUser().then((user) => {
            auth.fetch('put', `drivers/setgeolocation?userId=${user.profile.sub}&lon=${currentPositionItem.lon}&lat=${currentPositionItem.lat}`);
         });
      },
      error => console.info(error),
      { 
          enableHighAccuracy: true,
          timeout: 1000
      });
   }
}

/**
 * Removes the local decoupled data.
 */
export const cleanLocalData = () => {
   return db.transaction('rw', db.drops, db.actions, db.driverremarks, db.skipremarks, db.dropdata, db.deliveryorderdetailsdata, db.deliveryorderdetails, async () => {
      let driverRemarksToRemove = [];
      let skipRemarksToRemove = [];
      let dropDataToRemove = [];
      let dropDetailsDataToRemove = [];
      let deliveryOrderDetailsDataToRemove = [];

      // Handle DriverRemarks
      await db.driverremarks.toCollection()
         .each(async (driverRemark) => {
            return await db.drops.get(driverRemark.dropId, async (drop) => {
               if (!drop) {
                  // There was no drop found for this driverRemark, so check if there is an action that is still waiting to be synced.
                  return await db.actions.get({ key: driverRemark.dropId }, (action) => {
                     if (!action) {
                        // There was no action found
                        driverRemarksToRemove.push(driverRemark.id);
                     }
                  });
               }
            });
         })
         .then(async () => await db.driverremarks.where("id").anyOf(driverRemarksToRemove).delete());

      // Handle SkipRemarks
      await db.skipremarks.toCollection()
         .each(async (skipRemark) => {
            return await db.drops.get(skipRemark.dropId, async (drop) => {
               if (!drop) {
                  // There was no drop found for this skipRemark, so check if there is an action that is still waiting to be synced.
                  return await db.actions.get({ key: skipRemark.dropId }, (action) => {
                     if (!action) {
                        // There was no action found
                        skipRemarksToRemove.push(skipRemark.id);
                     }
                  });
               }
            });
         })
         .then(async () => await db.skipremarks.where("id").anyOf(skipRemarksToRemove).delete());

      // Handle DropData
      await db.dropdata.toCollection()
         .each(async (dropData) => {
            return await db.drops.get(dropData.dropId, async (drop) => {
               if (!drop) {
                  // There was no drop found for this dropData, so check if there is an action that is still waiting to be synced.
                  return await db.actions.get({ key: dropData.dropId }, (action) => {
                     if (!action) {
                        // There was no action found
                        dropDataToRemove.push(dropData.dropId);
                     }
                  });
               }
            });
         })
         .then(async () => await db.dropdata.where("dropId").anyOf(dropDataToRemove).delete());

      // Handle DropDetailsData
      await db.dropdetailsdata2.toCollection()
         .each(async (dropDetailsData) => {
            return await db.drops.get(dropDetailsData.dropId, async (drop) => {
               if (!drop) {
                  // There was no drop found for this dropDetailsData, so check if there is an action that is still waiting to be synced.
                  return await db.actions.get({ key: dropDetailsData.dropId }, (action) => {
                     if (!action) {
                        // There was no action found
                        dropDetailsDataToRemove.push(dropDetailsData.id);
                     }
                  });
               }
            });
         })
         .then(async () => await db.dropdetailsdata2.bulkDelete(dropDetailsDataToRemove));

      // Handle DeliveryOrderDetailsData
      return await db.deliveryorderdetailsdata.toCollection()
         .each(async (deliveryOrderDetailsData) => {
            return await db.deliveryorderdetails.get({id: deliveryOrderDetailsData.deliveryOrderDetailId, dropId: deliveryOrderDetailsData.dropId}, async (deliveryOrderDetail) => {
               if (deliveryOrderDetail) {
                   return await db.drops.get(deliveryOrderDetail.dropId, async (drop) => {
                     if (!drop) {
                        // There was no drop found for this deliveryOrderDetailData, so check if there is an action that is still waiting to be synced.
                        return await db.actions.get({ key: deliveryOrderDetail.dropId }, (action) => {
                           if (!action) {
                              // There was no action found
                              deliveryOrderDetailsDataToRemove.push([deliveryOrderDetailsData.deliveryOrderDetailId, deliveryOrderDetailsData.dropId]);
                           }
                        });
                     }
                   });
               } else {
                  // There was no deliveryOrderDetail found for this deliveryOrderDetailData, so we can remove it
                  deliveryOrderDetailsDataToRemove.push([deliveryOrderDetailsData.deliveryOrderDetailId, deliveryOrderDetailsData.dropId]);
               }
            });
         })
         .then(async () => await db.deliveryorderdetailsdata.where("[deliveryOrderDetailId+dropId]").anyOf(deliveryOrderDetailsDataToRemove).delete());
   });
}

/***********************/
/** Private functions **/
/***********************/

/**
 * Checks if it's the initial sync or the refreshTime has been elapsed.
 * If it's the case, the given table will be synchronised.
 * @param {*} auth 
 * @param {*} table 
 * @param {*} fetchType 
 * @param {*} fetchUrl 
 * @param {*} optionalArgs 
 */ 
const syncTablePeriodic = async (auth, table, fetchType, fetchUrl, {
   fetchParams,
   callback = async () => {}, 
   refreshTime = 3600000,
   relatedTables = []
} = {}) => {
if (navigator.onLine) {
   // Check if a refresh is needed
   let now = new Date();
   let nowUtc = now.getTime() - now.getTimezoneOffset() * 60000;
   let syncNeeded = false;

   let transactionTables = [table, db.syncs].concat(relatedTables);

   await db.transaction('r', transactionTables, async () => {
      let refreshTimeElapsed = false;
      let initialLoad = await table.count() === 0;
      if (!initialLoad) {
         refreshTimeElapsed = await db.syncs.where('id').equals(table.name).first()
            .then((result) => typeof result !== 'undefined' && result.time < (nowUtc - refreshTime));
      }

      // Refresh the data if there is no data loaded yet or if the refresh time has elapsed
      syncNeeded = initialLoad || refreshTimeElapsed;
   });

   // Execute the sync if necessary
   if (syncNeeded) {
      await auth.getUser()
         .then(async (user) => {
            await auth.fetch(fetchType, fetchUrl, fetchParams)
               .then(async (result) => {
                  if (result && result.status === 200) {
                     await db.transaction('rw', transactionTables, async () => {
                        table.clear();

                        // Store the last synctime in db + execute the optional callback function
                        await db.syncs.put({
                           id: table.name,
                           time: nowUtc
                        }).then(async () => await callback(result));
                     });
                  }
               })
               .catch(error => console.error(`Unable to sync table "${table?.name}": `, error.toJSON()));
         });
   }
}
};

/**
 * Fetches the params for this app.
 * @param {*} auth 
 */
const fetchDriverAppParams = async (auth) => {
   await syncTablePeriodic(auth, db.params, 'get', 'params/driverappparams', { 
      refreshTime: 0,
      callback: async (result) => {
         await db.params.put({
            skipAllowed: result.data.skipAllowed,
            timeRegistrations: result.data.timeRegistrations,
            pauseAllowed: result.data.pauseAllowed,
            extraTaskAllowed: result.data.extraTaskAllowed,
            selectModernisationProjectAllowed: result.data.selectModernisationProjectAllowed,
            activeAtArrivalAndDeparture: result.data.activeAtArrivalAndDeparture,
            routeAutoStart: result.data.routeAutoStart,
            alwaysSignOnGlass: result.data.alwaysSignOnGlass,
            signOnGlassRequired: result.data.signOnGlassRequired,
            autoAcceptTimeRegistrations: result.data.autoAcceptTimeRegistrations,
            imagesMaxSizeMb: result.data.imagesMaxSizeMb,
            displayModeDetection: result.data.displayModeDetection,
            articleInventory: result.data.articleInventory,
            articleInventoryNoLimits: result.data.articleInventoryNoLimits,
            externalStatusManagement: result.data.externalStatusManagement,
            displayDelay: result.data.displayDelay,
            displayDepartureTime: result.data.displayDepartureTime,
            onlyInternalUsageImages: result.data.onlyInternalUsageImages
         });
      }
   });
};

/**
 * Fetches the languages of the application.
 * @param {*} auth 
 */
const fetchLanguages = async (auth) => {
   // Get all languages (not application specific) to make sure all driverapp languages will be visible at the moment.
   await syncTablePeriodic(auth, db.languages, 'get', /*'applicationlanguages'*/ 'languages', {
      refreshTime: 86400000,
      callback: async (result) => {
         // Filter out all languages which are not supported by the DriverApp
         db.languages.bulkPut(result.data.filter(l => SupportedLanguagesByDriverApp.indexOf(l.abbreviation.toLowerCase()) > -1));
      }
   });
};

/**
 * Fetches the breaktypes.
 * @param {*} auth 
 */
const fetchBreakTypes = async (auth) => {
   let language = await db.languages.get({abbreviation: i18n.language.toUpperCase()});

   await syncTablePeriodic(auth, db.breaktypes, 'get', `breaktypes?langId=${language?.languageId}`, {
      refreshTime: 0,
      callback: async (result) => {
         result.data.forEach(breakType => { 
            db.breaktypes.put({ 
               id: breakType.breakTypeId, 
               isDefault: breakType.isDefault,
               description: breakType.description,
               translatedTerm: breakType.translatedTerm,
               remarkTranslatedTerm: breakType.remarkTranslatedTerm,
               remarkTranslations: breakType.remarkTranslations
            });
         });
      }
   });
};

/**
 * Fetches the external statuses.
 * @param {*} auth 
 */
const fetchExternalStatuses = async (auth) => {
   await syncTablePeriodic(auth, db.externalstatuses, 'get', 'externalstatuses', {
      refreshTime: 0,
      callback: async (result) => {
         db.externalstatuses.clear();
   
         result.data.forEach(externalStatus => {
            db.externalstatuses.put({
               id: externalStatus.externalStatusId,
               name: externalStatus.name,
               seqNr: externalStatus.seqNr,
               backgroundColor: externalStatus.backgroundColor,
               foregroundColor: externalStatus.foregroundColor,
               badgeIcon: externalStatus.badgeIcon,
               translatedTerm: externalStatus.translatedTermInCurrentLanguage
            });
         });
      }
   });
};

/**
 * Fetch the droptypes.
 * @param {*} auth 
 */
const fetchDropTypes = async (auth) => {
   await syncTablePeriodic(auth, db.droptypes, 'get', 'droptypes', {
      refreshTime: 0,
      callback: async (result) => {
         db.droptypes.clear();
         result.data.forEach(dropType => db.droptypes.put(convertToLocalDropType(dropType)));
      }
   })
}

/**
 * Fetch the projecttypes.
 * @param {*} auth 
 */
const fetchProjectTypes = async (auth) => {
   let language = await db.languages.get({abbreviation: i18n.language.toUpperCase()});
   
   await syncTablePeriodic(auth, db.projecttypes, 'get', `enumtranslations/projecttype?langId=${language?.languageId}`, {
      refreshTime: 0,
      callback: async (result) => {
         db.projecttypes.clear();

         result.data.forEach(projectType => {
            db.projecttypes.put({
               id: projectType.enumValue,
               name: projectType.translatedTerm
            })
         });
      }
   });
}

/**
 * Fetch the route-statuses.
 * @param {*} auth 
 */
const fetchRouteStates = async (auth) => {
   let language = await db.languages.get({abbreviation: i18n.language.toUpperCase()});
   
   await syncTablePeriodic(auth, db.routestates, 'get', `enumtranslations/routestatus?langId=${language?.languageId}`, {
      refreshTime: 0,
      callback: async (result) => {
         db.routestates.clear();

         result.data.forEach(routeState => {
            db.routestates.put({
               id: routeState.enumValue,
               name: routeState.translatedTerm
            })
         });
      }
   });
}

/**
 * Fetch the drop-statuses.
 * @param {*} auth 
 */
const fetchDropStates = async (auth) => {
   let language = await db.languages.get({abbreviation: i18n.language.toUpperCase()});
   
   await syncTablePeriodic(auth, db.dropstates, 'get', `enumtranslations/routedropstatus?langId=${language?.languageId}`, {
      refreshTime: 0,
      callback: async (result) => {
         db.dropstates.clear();

         result.data.forEach(dropState => {
            db.dropstates.put({
               id: dropState.enumValue,
               name: dropState.translatedTerm
            })
         });
      }
   });
}

/**
 * Fetches the articles.
 * @param {*} auth 
 */
const fetchArticles = async (auth) => {
   let params = await db.params.where('id').notEqual(0).first();
   if (params.articleInventory) {
      await syncTablePeriodic(auth, db.articles, 'get', 'articles/articleupdate', {
         refreshTime: 86400000,
         relatedTables: [db.articleimages],
         callback: async (result) => {
            db.articleimages.clear();
      
            result.data.forEach(article => {
               db.articles.put(convertToLocalArticle(article));
      
               if (article.articleImages != null && article.articleImages.length > 0) {
                  var articleImages = article.articleImages.map(image => ({ ...image, articleId: article.articleId }));
                  db.articleimages.bulkPut(articleImages);
               }
            });
         }
      });
   }
};

const fetchFeedbackItems = async (auth) => {
   let user = await auth.getUser();
   await syncTablePeriodic(auth, db.feedbackitems, 'get', `feedbackoptions/fordriver/${user.profile.sub}`, {
      refreshTime: 0,
      callback: async (result) => {
         db.feedbackitems.clear();

         db.feedbackitems.put({
            key: 'CODES_PANNE',
            content: result.data
         });
      }
   });
};

/**
 * Fetches the routes and their data for the given user.
 * @param {*} auth 
 * @param {*} user 
 * @param {*} canFetchNewRoutes 
 */
export const fetchRoutes = async (auth, user, canFetchNewRoutes, actionsExecuted) => {
   // Fetch new route information + store in local db
   if (canFetchNewRoutes && navigator.onLine) {
      return await auth.fetch('get', `simulationsolutionroutes/planning/${user.profile.sub}`)
         .then(async (result) => {
            if (result && result.status === 200) {
               let apiData = result.data;
               //console.log(apiData);

               let convertedApiRoutePlans = apiData.map(plan => convertToLocalRoutePlan(plan));

               return db.transaction('rw', db.routeplans, db.routeplanitemdata, db.notifications, db.actions, db.locations, db.routes, db.droptypes,
                  db.returnables, db.returnablesdata, db.articles, db.dropdetails2, db.dropdetailsdata2, db.drops, db.dropdata,
                  db.driverremarks, db.skipremarks, db.bufferslots, db.unproductivetimes, async () => {
                  // Check updated data (new/deleted plannings and/or planned items) + create notification
                  let localRoutePlans = await db.routeplans.toArray();

                  const updatedRoutePlans = hasUpdatedRoutePlans(localRoutePlans, convertedApiRoutePlans);
                  const updatedRoutePlanItems = hasUpdatedRoutePlanItems(localRoutePlans, convertedApiRoutePlans);

                  let routeChangeDiscovered = updatedRoutePlans || updatedRoutePlanItems;
                  if (routeChangeDiscovered) {
                     // Generate a notification
                     db.notifications.put({
                        routesUpdated: updatedRoutePlans,
                        routePlanItemsUpdated: updatedRoutePlanItems
                     });
                  }

                  // Only apply the changes if all actions has been synchronized
                  if (await db.actions.count() === 0) {

                     if (routeChangeDiscovered) {
                        // Clear the previous data
                        db.routeplans.clear();
                        db.routes.clear();
                        db.droptypes.clear();
                        db.drops.clear();
                        db.bufferslots.clear();
                        db.unproductivetimes.clear();
                        db.locations.clear();
                        db.dropdetails2.clear();
                        // TO DO: check if articles need to be cleared OR NOT ????
                        db.returnables.clear();
                     }

                     apiData.map(apiRoutePlan => ({apiRoutePlan, routePlan: convertToLocalRoutePlan(apiRoutePlan, true)}))
                        .forEach(({apiRoutePlan, routePlan}) => {

                           if (routeChangeDiscovered) {
                              // Save the start/stop location
                              let startLocation = convertToLocalLocation(routePlan.route.startLocation);
                              db.locations.put(startLocation);

                              let stopLocation = convertToLocalLocation(routePlan.route.stopLocation);
                              db.locations.put(stopLocation);

                              // Save the route
                              db.routes.put(convertToLocalRoute(routePlan.route));

                              // Save the route-planning (without any payload because it will be saved separately)
                              const { route, ...routePlanSavingProps } = routePlan;
                              routePlanSavingProps.items = routePlanSavingProps.items.map(routePlanItem => { const { payload, ...routePlanItemSavingProps } = routePlanItem; return routePlanItemSavingProps; });
                              db.routeplans.put(routePlanSavingProps);
                           }

                           // Save the items of this route-planning
                           const { items } = routePlan;
                           items.forEach(async (item) => {
                              switch (item.type) {
                                 case PlanningItemType.DROP:
                                    await handleFetchedDropPlanningItem(routePlan.route?.id, item);
                                    break;
                                 case PlanningItemType.BUFFERSLOT:
                                    await handleFetchedBufferSlotPlanningItem(routePlan.route?.id, item);
                                    break;
                                 case PlanningItemType.UNPRODUCTIVE_TIME:
                                    await handleFetchedUnproductiveTimePlanningItem(routePlan.route?.id, item);
                                    break;
                                 default:
                                    break;
                              }

                              // Generate the local data for the planning-item (only if there isn't already local data for it)
                              let apiRoutePlanItem = (apiRoutePlan.items ?? []).find(apiRoutePlanItem => apiRoutePlanItem.hashCode === item.hashCode);
                              if (apiRoutePlanItem != null) {
                                 await db.routeplanitemdata.get({ routePlanId: routePlan.id, type: item.type, payloadId: item.payloadId })
                                    .then(result => {
                                       if (result == null) {
                                          db.routeplanitemdata.put(convertPlanningItemToLocalData(apiRoutePlan.route.id, apiRoutePlanItem));
                                       }
                                    });
                              }
                           });
                        });

                  }
               });
            }
         })
         .then(async () => {
            // Request the updated ArticleInventoryLevels
            return await auth.fetch('get', `articleinventorylevels/${user.profile.sub}`)
               .then(async (result) => {
                  if (result && result.status === 200) {
                     return db.transaction('rw', db.actions, db.articleinventorylevels, async () => {
                        // Only apply the changes if all actions has been syncrhonized
                        if (await db.actions.count() === 0) {
                           // Clear the previous data
                           db.articleinventorylevels.clear();
                           db.articleinventorylevels.bulkPut(result.data.map(articleInventoryLevel => convertToLocalArticleInventoryLevel(articleInventoryLevel)));
                        }
                     });
                  }
               });
         })
         .catch((error) => { return error })
   }
}

const handleFetchedDropPlanningItem = async (routeId, planItem) => {
   let drop = planItem.payload;

   // Save the location of the drop
   let dropLocation = convertToLocalLocation(drop.location);
   db.locations.put(dropLocation);
   
   // Save the returnables
   let returnables = drop.returnables;
   if (returnables != null && returnables.length > 0) {
      db.returnables.bulkPut(returnables.map(returnable => convertToLocalArticle(returnable)));

      // Generate the local data for the returnable (only if there isn't already local data for it)
      returnables.forEach(returnable => {
         if (returnable != null) {
            db.returnablesdata.get({dropId: drop.id, returnableId: returnable.articleId}, data => {
               // Make sure we don't overwrite existing local data
               if (!data) {
                  db.returnablesdata.put(convertReturnableToLocalData(drop.id, returnable));
               }
            });
         }
      });
   }

   // Save the articles
   let deliveryOrder = drop.deliveryOrder;
   if (deliveryOrder != null && deliveryOrder.deliveryOrderDetails != null) {
      let deliveryOrderDetails = deliveryOrder.deliveryOrderDetails.filter(deliveryOrderDetail => deliveryOrderDetail.article != null);

      db.articles.bulkPut(deliveryOrderDetails.map(deliveryOrderDetail => convertToLocalArticle(deliveryOrderDetail.article)));

      // Generate the dropdetails
      db.dropdetails2.bulkPut(deliveryOrderDetails.map(deliveryOrderDetail => convertDeliveryOrderDetailToDropDetail(drop.id, deliveryOrderDetail)));

      // Generate the local data for the article (only if there isn't already local data for it)
      deliveryOrderDetails.forEach(deliveryOrderDetail => {
         db.dropdetailsdata2.get({deliveryOrderDetailId: deliveryOrderDetail.deliveryOrderDetailId, dropId: drop.id}, data => {
            // Make sure we don't overwrite existing local data
            if (!data) {
               db.dropdetailsdata2.put(convertDeliveryOrderDetailToLocalData(drop.id, deliveryOrderDetail));
            }
         });
      });
   }

   let simulationDelivery = drop.simulationDelivery;
   if (simulationDelivery != null && simulationDelivery.simulationDeliveryDetails != null) {
      let simulationDeliveryDetails = simulationDelivery.simulationDeliveryDetails.filter(simulationDelivery => simulationDelivery.article != null);

      db.articles.bulkPut(simulationDeliveryDetails.map(simulationDeliveryDetail => convertToLocalArticle(simulationDeliveryDetail.article)));

      // Generate the dropdetails
      db.dropdetails2.bulkPut(simulationDeliveryDetails.map(simulationDeliveryDetail => convertSimulationDeliveryDetailToDropDetail(drop.id, simulationDeliveryDetail)));

      // Generate the local data for the article (only if there isn't already local data for it)
      simulationDeliveryDetails.forEach(simulationDeliveryDetail => {
         db.dropdetailsdata2.get({simulationDeliveryDetailId: simulationDeliveryDetail.simulationDeliveryDetailId, dropId: drop.id}, data => {
            // Make sure we don't overwrite existing local data
            if (!data) {
               db.dropdetailsdata2.put(convertSimulationDeliveryDetailToLocalData(drop.id, simulationDeliveryDetail));
            }
         });
      });
   }

   // Save the drop itself
   db.drops.put(convertToLocalDrop(drop, routeId, dropLocation.id));

   // Generate the local data for the drop (only if there isn't already local data for it)
   await db.dropdata.where({ dropId: drop.id })
      .toArray()
      .then(result => {
         if (result.length === 0) {
            db.dropdata.put(convertDropToLocalData(drop));
         }
      });

   // Save the DriverDone remarks
   await db.driverremarks.where({ dropId: drop.id })
      .toArray()
      .then(result => {
         if (result.length === 0) {
            let driverDoneRemarks = drop.driverRemarks;
            if (driverDoneRemarks.length > 0) {
               db.driverremarks.bulkPut(driverDoneRemarks.map(remark => convertRemarkToLocalRemark(remark)));
            }
         }
      });

   // Store the remarks for the driver on skip
   await db.skipremarks.where({ dropId: drop.id })
      .toArray()
      .then(result => {
         if (result.length === 0) {
            let skipRemarks = drop.skipRemarks;
            if (skipRemarks.length > 0) {
               db.skipremarks.bulkPut(skipRemarks.map(remark => convertRemarkToLocalRemark(remark)));
            }
         }
      });
};

const handleFetchedBufferSlotPlanningItem = async (routeId, planItem) => {
   let bufferSlot = planItem.payload;

   // Save the location of the bufferSlot
   let bufferSlotLocation;
   if (bufferSlot.location != null) {
      bufferSlotLocation = convertToLocalLocation(bufferSlot.location);
      db.locations.put(bufferSlotLocation);
   }

   // Save the bufferSlot itself
   await db.bufferslots.put(convertToLocalBufferSlot(bufferSlot, routeId, bufferSlotLocation?.id));
};

const handleFetchedUnproductiveTimePlanningItem = async (routeId, planItem) => {
   let unproductiveTime = planItem.payload;

   // Save the unproductive time itself
   await db.unproductivetimes.put(convertToLocalUnproductiveTime(unproductiveTime, routeId));
};

/**
 * Synchronizes the actions.
 * @param {*} auth 
 * @param {*} user 
 */
export const syncActions = async (auth) => {

   var apiAvailable = await auth.fetch('get', 'status')
      .then(result => result && result.status === 200)
      .catch(() => false);

   if (!navigator.onLine || !apiAvailable)
      return Promise.reject("Skipping the execution of syncActions because we're offline.");

   let syncing = await db.datastore.where('key').equals('syncing').first();
   if (syncing && syncing.value !== 0){
      let difference = new Date().getTime() - syncing.value;

      if (difference < 2 * 60 * 1000) {
         return Promise.reject(new Error('A sync is already running...'));
      } else {
         let syncStartedAt = new Date(syncing.value);
         let syncReleasedAt = new Date(syncStartedAt.getTime() + difference);

         await queries.addLogMessage("Long sync detected - Releasing Lock", ['SYNC ACTIONS'], {
            syncStartedAt: utils.toLocaleString(syncStartedAt),
            syncReleasedAt: utils.toLocaleString(syncReleasedAt)
         });

         await db.datastore.put({
            key: 'syncing',
            value: 0
         });
      }
   }

   const attemptLoggingTrigger = 100; // Currently set to a bigger number then attemptLimit to make sure we'll currently log only the delete-messages, but also keep to possibility to log them all again in the future.
   const attemptLimit = 5;

   return await db.datastore.put({
      key: 'syncing',
      value: new Date().getTime()
   }).then(async () => {
      return await db.actions.toArray()
         .then(async (actions) => {
            let canFetchNewRoutes = true;
            let actionsExecuted = false;
   
            // If there are any actions yet to sync, be sure they are all synced before we can fetch new routes.
            if (actions.length === 0) {
               return {canFetchNewRoutes: canFetchNewRoutes, actionsExecuted: actionsExecuted };
            }
            canFetchNewRoutes = false;
            actionsExecuted = true;
         
            // Make sure we sync the actions in sequential order
            actions = actions.sort((a, b) => { return a.id - b.id });
   
            return await auth.fetch('get', 'status')
               .then(result => result && result.status === 200)
               .then(async (apiAvailable) => {
                  if (apiAvailable) {
                     return await auth.getUser()
                        .then(async (user) => {
         
                           if (user.expired) {
                              return {canFetchNewRoutes: canFetchNewRoutes, actionsExecuted: actionsExecuted };
                           }
   
                           for (let index = 0; index < actions.length; index++) {
   
                              let action = actions[index];
   
                              // Check if previous actions has failed
                              let actionCount = await db.actions.where("id").below(action.id).count();
                              if (actionCount > 0) {
                                 break;
                              }
   
                              let actionAttempt = action.attempt ? ++action.attempt : 1;
                              await db.actions.update(action.id, {
                                 attempt: actionAttempt
                              });
         
                              switch (action.type) {
                                 case "RouteStart":
                                 case "RouteInterrupted":
                                 case "RouteStop":
                                    let routeStatus = 30; // RouteStart
                                    if (action.type === "RouteInterrupted")
                                       routeStatus = 70;
                                    else if (action.type === "RouteStop")
                                       routeStatus = 40;

                                    await db.routes
                                       .where({ id: parseInt(action.key) })
                                       .first()
                                       .then((route) => ({
                                          Id: parseInt(action.key),
                                          Time: action.time,
                                          Status: routeStatus,
                                          Lat: route.lat,
                                          Lon: route.lon
                                       }))
                                       .then(async (routeBody) => {
                                          return await auth.fetch('put', `simulationsolutionroutes/updateroutestatus`, routeBody)
                                             .then(async (result) => {
                                                if (!result || result.status !== 200) {

                                                   let unprocessableEntity = result?.status === 422;
                                                   if (unprocessableEntity || actionAttempt >= attemptLoggingTrigger) {
                                                      await queries.addLogMessage(`Synchronisation of action "${action.type}" failed. (Attempt: ${actionAttempt})`, +
                                                         utils.addStatusRelatedTags(result?.status, ['SYNC ACTIONS', 'ROUTE']), 
                                                         {
                                                            requestBody: routeBody,
                                                            response: result ? result : "No response"
                                                         });
                                                   }
         
                                                   if (unprocessableEntity || actionAttempt >= attemptLimit) {
                                                      await queries.addLogMessage(`Action "${action.type}" deleted after ${actionAttempt} attempts.`, 
                                                         utils.addStatusRelatedTags(result?.status, ['SYNC ACTIONS', 'ROUTE', 'ATTEMPT LIMIT REACHED']), 
                                                         {
                                                            action: action
                                                         });
         
                                                      await db.actions.where({ id: action.id }).delete();
                                                   }
                                                } else {
                                                         
                                                   if (actionAttempt > attemptLoggingTrigger) {
                                                      await queries.addLogMessage(`Synchronisation of action "${action.type}" succeeded. (Attempt: ${actionAttempt})`, 
                                                         ['SYNC ACTIONS', 'ROUTE'], 
                                                         {
                                                            requestBody: routeBody
                                                         });
                                                   }
         
                                                   await db.actions.where({ id: action.id }).delete();
                                                }
                                             });
                                       });
                                    break;
                                 case Actions.DEPRECATED_DROP_START:
                                 case Actions.DROP_START:
                                 case "DropFinish":
                                 case "DropSkip":
                                 case "DropInterrupted":
                                    if (action.type === Actions.DEPRECATED_DROP_START)
                                       action.type = Actions.DROP_START;

                                    let dropStatus = 30;
                                    if (action.type === "DropFinish")
                                       dropStatus = 40;
                                    else if (action.type === "DropSkip")
                                       dropStatus = 50;
                                    else if (action.type === "DropInterrupted")
                                       dropStatus = 70;
         
                                    await queries.joinDropData(
                                       await db.drops
                                          .where({ id: parseInt(action.key) })
                                          .with({ 
                                             driverRemarks: 'driverremarks',
                                             skipRemarks: 'skipremarks',
                                             feedback: 'feedback'
                                          })
                                    )
                                    .then(async (drops) => {
                                       if (drops && drops.length > 0) {
                                          let drop = drops[0];
                                          // Check if there is any DropDetail for this drop
                                          return await db.dropdetails2.where({ dropId: drop.id }).toArray()
                                             .then(async (dropDetails) => {
                                                for (let i=0; i < dropDetails.length; i++) {
                                                   let dropDetailDataBaseQuery = dropDetails[i].deliveryOrderDetailId != null ?
                                                      { deliveryOrderDetailId: dropDetails[i].deliveryOrderDetailId } :
                                                      { simulationDeliveryDetailId: dropDetails[i].simulationDeliveryDetailId };

                                                   await db.dropdetailsdata2.get({
                                                      ...dropDetailDataBaseQuery,
                                                      dropId: dropDetails[i].dropId
                                                   }, data => dropDetails[i].data = data);
                                                }
                                                return dropDetails;
                                             })
                                             .then(async dropDetails => {
                                                const articles = new Promise(async (resolve, reject) => {
                                                   if ([30, 70].indexOf(dropStatus) > -1) {
                                                      // The drop has been started or interrupted so we'll not send any of the updates from the article usages.
                                                      resolve([]);
                                                   } else {
                                                      await db.dropdetailsdata2.where('dropId').equals(drop.id)
                                                         .toArray()
                                                         .then(articlesData => articlesData.map(articleData => ({
                                                            DropDetailsDataId: articleData.id,
                                                            ArticleId: articleData.articleId,
                                                            DeliveryOrderDetailId: !articleData.createdLocally ? articleData.deliveryOrderDetailId : null,
                                                            CreatedLocally: articleData.createdLocally,
                                                            OriginalQuantityIn: articleData.originalQuantityIn,
                                                            QuantityIn: articleData.deliveredQuantityIn,
                                                            OriginalQuantityOut: articleData.originalQuantityOut,
                                                            QuantityOut: articleData.deliveredQuantityOut
                                                         })))
                                                         .then(result => resolve(result))
                                                         .catch(err => reject(err));
                                                   }
                                                });

                                                const returnables = new Promise(async (resolve, reject) => {
                                                   if ([30, 70].indexOf(dropStatus) > -1) {
                                                      // The drop has been interrupted so we'll not send any of the updates from the article usages.
                                                      resolve([]);
                                                   } else {
                                                      await db.returnablesdata.where('dropId').equals(drop.id)
                                                         .with({ returnable: 'returnableId' })
                                                         .then(returnablesData => returnablesData.map(returnableData => ({
                                                            ArticleId: returnableData.returnableId,
                                                            IsReturnable: true,
                                                            OriginalQuantityIn: returnableData.returnable?.defaultInQuantity,
                                                            QuantityIn: returnableData.deliveredQuantityIn,
                                                            OriginalQuantityOut: returnableData.returnable?.defaultOutQuantity,
                                                            QuantityOut: returnableData.deliveredQuantityOut
                                                         })))
                                                         .then(result => resolve(result))
                                                         .catch(err => reject(err));
                                                   }
                                                });

                                                return new Promise((resolve, reject) => Promise.all([articles, returnables])
                                                   .then(articleUsageUpdates => {
                                                      resolve(({
                                                         Id: drop.id,
                                                         Time: action.time,
                                                         Status: dropStatus,
                                                         DriverRemarks: dropStatus === 50 ? drop.skipRemarks : drop.driverRemarks,
                                                         RealIndex: 0,
                                                         Lat: drop.lat,
                                                         Lon: drop.lon,
                                                         Signature: drop.signature,
                                                         ETA: drop.eta ? drop.eta : new Date(0, 0),
                                                         ActiveAtArrival: action.activeAtArrival,
                                                         ActiveAtDeparture: action.activeAtDeparture,
                                                         ArticleUsageUpdates: articleUsageUpdates.flat(),
                                                         // We'll only send the feedback if the drop has been interrupted or finished
                                                         RouteDropFeedbacks: [40, 70].indexOf(dropStatus) > -1 ? drop.feedback?.map(feedbackItem => ({
                                                            FeedbackLabelId: feedbackItem.labelId,
                                                            FeedbackOptionId: feedbackItem.optionId
                                                         })) : []
                                                      }));
                                                   })
                                                   .catch(error => reject(error)));
                                             })
                                             .then(async (dropBody) => {
                                                return await auth.fetch('put', 'simulationsolutionroutedrops/updatedropstatus', dropBody)
                                                   .then(async (result) => {
                                                      if (!result || result.status !== 200) {

                                                         let unprocessableEntity = result?.status === 422;
                                                         if (unprocessableEntity || actionAttempt >= attemptLoggingTrigger) {
                                                            await queries.addLogMessage(`Synchronisation of action "${action.type}" failed. (Attempt: ${actionAttempt})`, 
                                                               utils.addStatusRelatedTags(result?.status, ['SYNC ACTIONS', 'DROP']), 
                                                               {
                                                                  requestBody: dropBody,
                                                                  response: result ? result : "No response"
                                                               });
                                                         }
            
                                                         if (unprocessableEntity || actionAttempt >= attemptLimit) {
                                                            await queries.addLogMessage(`Action "${action.type}" deleted after ${actionAttempt} attempts.`, 
                                                               utils.addStatusRelatedTags(result?.status, ['SYNC ACTIONS', 'DROP', 'ATTEMPT LIMIT REACHED']), 
                                                               {
                                                                  action: action
                                                               });
            
                                                            await db.actions.where({ id: action.id }).delete();
                                                         }

                                                      } else {

                                                         if (actionAttempt > attemptLoggingTrigger) {
                                                            await queries.addLogMessage(`Synchronisation of action "${action.type}" succeeded. (Attempt: ${actionAttempt})`, 
                                                               ['SYNC ACTIONS', 'DROP'], {
                                                                  requestBody: dropBody
                                                               });
                                                         }

                                                         // Delete the action
                                                         await db.actions.where({ id: action.id }).delete();
                                                      }
            
                                                   });
                                             });
                                       }
                                    });
                                    
                                    break;
                                 case "DropExternalStatus":
                                    let dropId = parseInt(action.key);
                                    let externalStatusId = action.externalStatusId;
                                    await auth.fetch('put', `simulationsolutionroutedrops/${dropId}/externalstatus/${externalStatusId}`)
                                       .then(async (result) => {
                                          if (!result || result.status !== 200) {
                                             let unprocessableEntity = result?.status === 422;
                                             if (unprocessableEntity || actionAttempt >= attemptLimit) {
                                                await queries.addLogMessage(`Action "${action.type}" deleted after ${actionAttempt} attempts.`, 
                                                   utils.addStatusRelatedTags(result?.status, ['SYNC ACTIONS', 'EXTERNAL-STATUS', 'ATTEMPT LIMIT REACHED']), 
                                                   {
                                                      action: action,
                                                      requestBody: pauseBody
                                                   });
                                                
                                                await db.actions.where({ id: action.id }).delete();
                                             }
                                          } else {
                                             await db.actions.where({ id: action.id }).delete();
                                          }
                                       });
                                    break;
                                 case Actions.BUFFERSLOT_START:
                                 case Actions.BUFFERSLOT_STOP:
                                    let bufferSlotBody = {
                                       Time: action.time,
                                       Start: action.type === Actions.BUFFERSLOT_START,
                                       BufferSlotId: action.key,
                                       SimulationSolutionRouteId: action.routeId
                                    };

                                    await auth.fetch('put', 'timeregistrations/bufferslot', bufferSlotBody)
                                       .then(async (result) => {
                                          if (!result || result.status !== 200) {
                                             let unprocessableEntity = result?.status === 422;
                                             if (unprocessableEntity || actionAttempt >= attemptLimit) {
                                                await queries.addLogMessage(`Action "${action.type}" deleted after ${actionAttempt} attempts.`, 
                                                   utils.addStatusRelatedTags(result?.status, ['SYNC ACTIONS', 'BUFFERSLOT', 'ATTEMPT LIMIT REACHED']), 
                                                   {
                                                      action: action,
                                                      requestBody: bufferSlotBody
                                                   });
                                                
                                                await db.actions.where({ id: action.id }).delete();
                                             }
                                          } else {
                                             await db.actions.where({ id: action.id }).delete();
                                          }
                                       });
                                    break;
                                 case Actions.UNPRODUCTIVETIME_START:
                                 case Actions.UNPRODUCTIVETIME_STOP:
                                    let unproductiveTimeBody = {
                                       Time: action.time,
                                       Start: action.type === Actions.UNPRODUCTIVETIME_START,
                                       RouteUnproductiveTimeId: action.key,
                                       SimulationSolutionRouteId: action.routeId,
                                       BreakTypeId: action.breakTypeId,
                                       Remark: action.remark
                                    };

                                    await auth.fetch('put', 'timeregistrations/pause', unproductiveTimeBody)
                                       .then(async (result) => {
                                          if (!result || result.status !== 200) {
                                             let unprocessableEntity = result?.status === 422;
                                             if (unprocessableEntity || actionAttempt >= attemptLimit) {
                                                await queries.addLogMessage(`Action "${action.type}" deleted after ${actionAttempt} attempts.`, 
                                                   utils.addStatusRelatedTags(result?.status, ['SYNC ACTIONS', 'UNPRODUCTIVETIME', 'ATTEMPT LIMIT REACHED']), 
                                                   {
                                                      action: action,
                                                      requestBody: unproductiveTimeBody
                                                   });
                                                
                                                await db.actions.where({ id: action.id }).delete();
                                             }
                                          } else {
                                             await db.actions.where({ id: action.id }).delete();
                                          }
                                       });
                                    break;
                                 case Actions.PAUSE_START:
                                 case Actions.DEPRECATED_PAUSE_START:
                                 case Actions.PAUSE_STOP:
                                 case Actions.DEPRECATED_PAUSE_STOP:
                                    if (action.type === Actions.DEPRECATED_PAUSE_START)
                                       action.type = Actions.PAUSE_START;
                                    else if (action.type === Actions.DEPRECATED_PAUSE_STOP)
                                       action.type = Actions.PAUSE_STOP;

                                    let pauseBody = {
                                       Time: action.time,
                                       Start: action.type === Actions.PAUSE_START,
                                       SimulationSolutionRouteId: action.simulationSolutionRouteId
                                    };
                     
                                    if (action.type === Actions.PAUSE_START) {
                                       pauseBody.BreakTypeId = action.breakTypeId;
                                       pauseBody.Remark = action.remark;
                                    }
                     
                                    await auth.fetch('put', 'timeregistrations/pause', pauseBody)
                                       .then(async (result) => {
                                          if (!result || result.status !== 200) {

                                             let unprocessableEntity = result?.status === 422;
                                             if (unprocessableEntity || actionAttempt >= attemptLimit) {
                                                await queries.addLogMessage(`Action "${action.type}" deleted after ${actionAttempt} attempts.`, 
                                                   utils.addStatusRelatedTags(result?.status, ['SYNC ACTIONS', 'PAUSE', 'ATTEMPT LIMIT REACHED']), 
                                                   {
                                                      action: action,
                                                      requestBody: pauseBody
                                                   });
                                                
                                                await db.actions.where({ id: action.id }).delete();
                                             }
                                          } else {
                                             await db.actions.where({ id: action.id }).delete();
                                          }
                                       });
                                    break;
                                 case "ImageAdd":
                                    await db.images
                                       .where({ id: parseInt(action.key) })
                                       .with({ drop: 'dropId' })
                                       .then((images) => {
                                          if (images === undefined || images.length === 0)
                                             return undefined;

                                          let image = images[0];
                                          return {
                                             Id: parseInt(image.dropId),
                                             ImageBlob: image.blob,
                                             ImageName: `${'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                                                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                                                return v.toString(16);
                                             })}${image.name}`,
                                             InternalUseOnly: image.internal,
                                             MainProjectId: image.drop ? image.drop.mainProjectId : null
                                          };
                                       })
                                       .then(async (imageBody) => {
                                          if (imageBody !== undefined) {
                                             return await auth.fetch('post', `simulationsolutionroutedrops/${imageBody.Id}/images`, imageBody)
                                                .then(async (result) => {
                                                   if (!result || result.status !== 200) {

                                                      let unprocessableEntity = result?.status === 422;
                                                      if (unprocessableEntity || actionAttempt >= attemptLimit) {
                                                         await queries.addLogMessage(`Action "${action.type}" deleted after ${actionAttempt} attempts.`, 
                                                            utils.addStatusRelatedTags(result?.status, ['SYNC ACTIONS', 'IMAGE', 'ATTEMPT LIMIT REACHED']), 
                                                            {
                                                               action: action,
                                                               requestBody: imageBody
                                                            });
   
                                                         await db.actions.where({ id: action.id }).delete();
                                                      }

                                                   } else {
                                                      await db.images.update(parseInt(action.key), { documentId: parseInt(result.data) });
                                                      await db.actions.where({ id: action.id }).delete();
                                                   }
                                                });
                                          } else {
                                             // The image has already been deleted
                                             await db.actions.where({ id: action.id }).delete();
                                          }
                                       });
                                    break;
                                 case "ImageDelete":
                                    await auth.fetch('delete', `simulationsolutionroutedrops/images/${action.key}`)
                                       .then(async (result) => {
                                          if (!result || result.status !== 200) {

                                             let unprocessableEntity = result?.status === 422;
                                             if (unprocessableEntity || actionAttempt >= attemptLimit) {
                                                await queries.addLogMessage(`Action "${action.type}" deleted after ${actionAttempt} attempts.`, 
                                                   utils.addStatusRelatedTags(result?.status, ['SYNC ACTIONS', 'IMAGE', 'ATTEMPT LIMIT REACHED']), 
                                                   {
                                                      action: action
                                                   });
   
                                                await db.actions.where({ id: action.id }).delete();
                                             }
                                          } else {
                                             await db.actions.where({ id: action.id }).delete();
                                          }
                                       });
                                    break;
                                 case "SYNC_ARTICLE_USAGES_INTERRUPTED_DROPS": // Triggers a sync of the article usages for drops which were interrupted
                                    let interruptedDrops = await db.drops.where('routeId').equals(parseInt(action.key))
                                       .toArray()
                                       .then(drops => drops.map(drop => drop.id))
                                       .then(async (dropIds) => {
                                          return await db.dropdata.where('dropId').anyOf(dropIds)
                                             .and((dropData => dropData.status === 70))
                                             .toArray();
                                       });

                                    const articleUsageUpdatesOnInterruptedDrops = [];

                                    for (const interruptedDrop of interruptedDrops) {
                                       const articles = await db.dropdetailsdata2.where('dropId').equals(interruptedDrop.dropId)
                                         .toArray()
                                          .then(articlesData => articlesData.map(articleData => ({
                                             DropDetailsDataId: articleData.id,
                                             ArticleId: articleData.articleId,
                                             DeliveryOrderDetailId: !articleData.createdLocally ? articleData.deliveryOrderDetailId : null,
                                             CreatedLocally: articleData.createdLocally,
                                             OriginalQuantityIn: articleData.originalQuantityIn,
                                             QuantityIn: articleData.deliveredQuantityIn,
                                             OriginalQuantityOut: articleData.originalQuantityOut,
                                             QuantityOut: articleData.deliveredQuantityOut
                                          })));

                                       const returnables = await db.returnablesdata.where('dropId').equals(interruptedDrop.dropId)
                                          .with({ returnable: 'returnableId' })
                                          .then(returnablesData => returnablesData.map(returnableData => ({
                                             ArticleId: returnableData.returnableId,
                                             IsReturnable: true,
                                             OriginalQuantityIn: returnableData.returnable?.defaultInQuantity,
                                             QuantityIn: returnableData.deliveredQuantityIn,
                                             OriginalQuantityOut: returnableData.returnable?.defaultOutQuantity,
                                             QuantityOut: returnableData.deliveredQuantityOut
                                          })));

                                       articleUsageUpdatesOnInterruptedDrops.push(
                                          new Promise((resolve, reject) => Promise.all([articles, returnables])
                                             .then(articleUsageUpdates => {
                                                resolve(({
                                                   Id: interruptedDrop.dropId,
                                                   Status: interruptedDrop.status,
                                                   ArticleUsageUpdates: articleUsageUpdates.flat()
                                                }));
                                             })
                                             .catch(error => reject(error)))
                                       );
                                    }

                                    await Promise.all(articleUsageUpdatesOnInterruptedDrops)
                                       .then(async (routeDropUpdates) => {
                                          if (routeDropUpdates.length === 0) {
                                             // Possible when there were no interrupted drops in this route, so we can delete this action to prevent it from blocking other actions
                                             await db.actions.where({ id: action.id }).delete();
                                          } else {
                                             for (const routeDropUpdate of routeDropUpdates) {
                                                await auth.fetch('put', 'simulationsolutionroutedrops/updatearticleusage', routeDropUpdate)
                                                   .then(async (result) => {
                                                      if (!result || result.status !== 200) {

                                                         let unprocessableEntity = result?.status === 422;
                                                         if (unprocessableEntity || actionAttempt >= attemptLoggingTrigger) {
                                                            await queries.addLogMessage(`Synchronisation of action "${action.type}" failed. (Attempt: ${actionAttempt})`, 
                                                               utils.addStatusRelatedTags(result?.status, ['SYNC ACTIONS', 'ARTICLE_USAGES_INTERRUPTED_DROPS']), 
                                                               {
                                                                  requestBody: routeDropUpdate,
                                                                  response: result ? result : "No response"
                                                               });
                                                         }
               
                                                         if (unprocessableEntity || actionAttempt >= attemptLimit) {
                                                            await queries.addLogMessage(`Action "${action.type}" deleted after ${actionAttempt} attempts.`, 
                                                               utils.addStatusRelatedTags(result?.status, ['SYNC ACTIONS', 'ARTICLE_USAGES_INTERRUPTED_DROPS', 'ATTEMPT LIMIT REACHED']), 
                                                               {
                                                                  action: action
                                                               });
               
                                                            await db.actions.where({ id: action.id }).delete();
                                                         }
                                                      } else {
                                                            
                                                         if (actionAttempt > attemptLoggingTrigger) {
                                                            await queries.addLogMessage(`Synchronisation of action "${action.type}" succeeded. (Attempt: ${actionAttempt})`, ['SYNC ACTIONS', 'ARTICLE_USAGES_INTERRUPTED_DROPS'], {
                                                               requestBody: routeDropUpdate
                                                            });
                                                         }
            
                                                         await db.actions.where({ id: action.id }).delete();
                                                      }
                                                   });
                                             }
                                          }
                                       });
                                    break;
                                 case 'LanguageChanged':
                                    await auth.fetch('put', `users/language/${action.key}`)
                                       .then(async (result) => {
                                          if (!result || result.status !== 200) {
                                             let unprocessableEntity = result?.status === 422;
                                             if (unprocessableEntity || actionAttempt >= attemptLimit) {
                                                await queries.addLogMessage(`Action "${action.type}" deleted after ${actionAttempt} attempts.`, 
                                                   utils.addStatusRelatedTags(result?.status, ['SYNC ACTIONS', 'LANGUAGE_CHANGED', 'ATTEMPT LIMIT REACHED']), 
                                                   {
                                                      action: action
                                                   });
   
                                                await db.actions.where({ id: action.id }).delete();
                                             }
                                          } else {
                                             await db.actions.where({ id: action.id }).delete();
                                          }
                                       });
                                    break;
                                 default:
                                    break;
                              }
                           }
         
                           canFetchNewRoutes = await db.actions.count() === 0;
         
                           return {canFetchNewRoutes: canFetchNewRoutes, actionsExecuted: actionsExecuted };
                        });
         
                  } else {
                     return {canFetchNewRoutes: canFetchNewRoutes, actionsExecuted: actionsExecuted };
                  }
               })
               .catch(error => {
                  return {canFetchNewRoutes: canFetchNewRoutes, actionsExecuted: actionsExecuted };
               });
         });
   })
   .finally(async () => {
      await db.actions.where("attempt").aboveOrEqual(attemptLimit)
         .toArray()
         .then(async (actions) => {
            if (actions && actions.length > 0) {
               for (let i=0; i < actions.length; i++) {
                  let action = actions[i];
                  await queries.addLogMessage(`Action "${action.type}" deleted after ${action.attempt} attempts. (>= ${attemptLimit})`, ['SYNC ACTIONS', 'FINALLY', 'ATTEMPT LIMIT REACHED'], {
                     action: action
                  });

                  await db.actions.where({ id: actions[i].id }).delete();
               }
            }
         }).finally(async () => {
            return await db.datastore.put({
               key: 'syncing',
               value: 0
            });
         });
   });
}

/**
 * Synchronizes the logs.
 * @param {*} auth 
 */
const syncLogs = async (auth) => {
   if (navigator.onLine) {
      return auth.getUser().then(async (user) => {
         let logs = await db.logs.toArray()
            .then(async (logs) => logs.map(log => {
               let {isError, id, message, stacktrace, date, ...otherProps} = log;

               return { 
                  isError: isError ?? true,
                  id: id,
                  message: message, 
                  stackTrace: stacktrace, 
                  date: date,
                  ...otherProps
               };
         }));

         if (logs.length > 0) {
            auth.fetch('post', `logs/driverapp`, logs)
               .then(async (result) => {
                  if (result && result.status === 200) {
                     await db.logs.where("id").anyOf(logs.map(log => log.id)).delete();
                  }
               });
         }
      });
   }
}

const syncVersion = async (auth) => {
   if (navigator.onLine) {
      return auth.getUser().then(async (user) => {
         auth.fetch('put', `drivers/driverapp/version?userId=${user.profile.sub}&version=${version}`);
      });
   }
}
