import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRef } from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../providers/AuthProvider';
import ExportLogsButton from '../exportLogsButton';

const Support = () => {
    const [t] = useTranslation();
    const exportLogsButtonRef = useRef();
    const auth = useContext(AuthContext);
    const [sendSupportEmailDisabled, setSendSupportEmailDisabled] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const sendExportedLogs = async () => {
        return new Promise((resolve, reject) => {
            if (!navigator.onLine) {
                showOfflineNotification();
                reject("Offline");
            }
            else {
                auth.getUser()
                    .then(async (user) => {
                        return (exportLogsButtonRef != null && exportLogsButtonRef.current != null) ? 
                            await exportLogsButtonRef.current.generateDataToExport(user) : {};
                    })
                    .then(exportData => auth.fetch('post', 'logs/driverapp/export', exportData))
                    .then(result => {
                        if (result != null && result.status === 200 && typeof(result.data) !== "undefined" && result.data !== null && result.data !== "") {
                            resolve(result.data);
                        } else {
                            reject(result);
                        }
                    })
                    .catch(err => reject(err));
            }
        });
    };

    const sendSupportEmail = () => {
        try {
            setSendSupportEmailDisabled(true);
            sendExportedLogs()
                .then(guid => {
                    /*var supportEmailAddress = process.env.REACT_APP_SUPPORT_EMAIL;
                    var supportSubject = `[DriverApp - v${version}] Support request`;
                    var supportBody = `${t("SupportMailBody")}\n\nGuid: ${guid}`;

                    var link = `mailto:${supportEmailAddress}?subject=${encodeURIComponent(supportSubject)}&body=${encodeURIComponent(supportBody)}`;

                    window.location.href = link;*/
                    showSupportConfirmationNotification();
                })
                .finally(() => setSendSupportEmailDisabled(false));
        }
        catch (error) {
            // Make sure we do not create extra errors
            console.error(error);
        }
    };

    const handleCloseNotification = useCallback((key) => {
        return <FontAwesomeIcon icon={["fal", "times"]} 
                    onClick={() => { closeSnackbar(key); }} style={{ marginRight: 10 }} />;
    }, [closeSnackbar]);

    const showOfflineNotification = useCallback(() => {
        enqueueSnackbar(t('YouAreOffline'), {
            variant: 'error',
            action: (key) => handleCloseNotification(key),
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right'
            },
            autoHideDuration: 3000
        });
    }, [enqueueSnackbar, t, handleCloseNotification]);

    const showSupportConfirmationNotification = useCallback(() => {
        enqueueSnackbar(t('SupportRequestSent'), {
            variant: 'success',
            action: (key) => handleCloseNotification(key),
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right'
            },
            autoHideDuration: 3000
        });
    }, [enqueueSnackbar, t, handleCloseNotification]);

    return (
        <Box margin={2}>
            <ExportLogsButton ref={exportLogsButtonRef} />
            <Button onClick={sendSupportEmail} disabled={sendSupportEmailDisabled}>
                {t("SendLogs")}
            </Button>
        </Box>
    );

};

export default Support;