import React, { useContext, useState } from 'react';
import { AuthContext } from '../../providers/AuthProvider';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FontAwesomeDuoToneStyle } from '../../fontawesome';
import queries from '../../utils/dexie/queries';
import db from '../../utils/dexie/driverappdb';

const TaskButton = ({ visible }) => {
    const auth = useContext(AuthContext);
    const [t,] = useTranslation();
    const [isDisabled, setIsDisabled] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [requestLocationInfoDialogOpen, /*setRequestLocationInfoDialogOpen*/] = useState(false);
    const [currentLocation, setCurrentLocation] = useState({ lat: null, lon: null });

    const requestCurrentPosition = (enableHighAccuracy = true, timeout = 1000) => {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                location => resolve(location),
                error => reject(error),
                { 
                    enableHighAccuracy: enableHighAccuracy,
                    timeout: timeout
                }
            )
        });
    };

    const determineCurrentPosition = () => {
        return new Promise((resolve, reject) => {
            requestCurrentPosition(true, 2000)
                .then(resolve)
                .catch(error1 => {
                    if (error1?.code !== 1) {
                        queries.addLogMessage(`Unable to get current position because of "${error1?.message} (Code: ${error1?.code})". Switching to fallback-mode ...`, ['EXTRA-TASK'], error1);
                        resolve(); // Resolve every error (except: PERMISSION_DENIED) so we can fallback to the saved positions inside IndexedDB.
                    } else {
                        reject(error1);
                    }
                    /*setRequestLocationInfoDialogOpen(true);
                    requestCurrentPosition(true, 5000)
                        .then(data => {
                            setRequestLocationInfoDialogOpen(false);
                            resolve(data);
                        })
                        .catch(error2 => {
                            requestCurrentPosition(false, 10000)
                                .then(data => {
                                    queries.addLogMessage(
                                        `Unable to get the current position with high accuracy. But we were able to determine it without high accuracy...`, 
                                        ['EXTRA-TASK'], 
                                        { 
                                            lat: data?.coords?.latitude, 
                                            lon: data?.coords?.longitude, 
                                            accuracy: data?.coords?.accuracy,
                                            heading: data?.coords?.heading
                                        }
                                    );
                                    resolve(data);
                                })
                                .catch(error3 => reject(error3))
                                .finally(() => setRequestLocationInfoDialogOpen(false));
                        });*/
                });
        });
    }

    const handleButtonClick = async () => {
        await determineCurrentPosition()
            .then(async (location) => {
                let currentPositionItem = {
                    timestamp: location?.timestamp,
                    lat: location?.coords?.latitude,
                    lon: location?.coords?.longitude,
                    accuracy: location?.coords?.accuracy
                };

                // Determine the minimum accuracy
                let minAccuracy = process.env.REACT_APP_GEOLOCATION_MIN_ACCURACY != null && !isNaN(process.env.REACT_APP_GEOLOCATION_MIN_ACCURACY) ? 
                    parseInt(process.env.REACT_APP_GEOLOCATION_MIN_ACCURACY) : null;

                // Check if the location has been found and if it's vallid according to the minimum accuracy
                if (location != null && currentPositionItem.accuracy <= minAccuracy) {
                    setCurrentLocation({ lat: currentPositionItem.lat, lon: currentPositionItem.lon });
                    setDialogIsOpen(true);
                } else {
                    // Fallback to the positions which are requested within the previous max lifetime.
                    let maxLifeTime = process.env.REACT_APP_GEOLOCATION_MAX_LIFETIME != null && !isNaN(process.env.REACT_APP_GEOLOCATION_MAX_LIFETIME) ? 
                        parseInt(process.env.REACT_APP_GEOLOCATION_MAX_LIFETIME) : 0;
                    let now = new Date();
                    now.setMinutes(now.getMinutes() - maxLifeTime);
                    let timeStampLimit = now.getTime();

                    let numberOfItemsToDetectBestAccuracy = 1;
                    let latestPositionRecords = await db.positions.where('timestamp').aboveOrEqual(timeStampLimit)
                        .limit(numberOfItemsToDetectBestAccuracy).reverse().sortBy('timestamp');
                    if (latestPositionRecords != null && latestPositionRecords.length > 0) {
                        // Find the item with the best accuracy (= lowest value)
                        let latestPositionRecordWithBestAccuracy = latestPositionRecords.reduce((prev, current) => prev.accuracy < current.accuracy ? prev : current);
                        setCurrentLocation({ lat: latestPositionRecordWithBestAccuracy.lat, lon: latestPositionRecordWithBestAccuracy.lon });
                        setDialogIsOpen(true);
                    } else {
                        let overallLatestPositionRecords = await db.positions.limit(numberOfItemsToDetectBestAccuracy).reverse().sortBy('timestamp');
                        if (overallLatestPositionRecords != null && overallLatestPositionRecords.length > 0) {
                            // Find the most recent item
                            let overallLatestPositionRecord = overallLatestPositionRecords[0];
                            setCurrentLocation({ lat: overallLatestPositionRecord.lat, lon: overallLatestPositionRecord.lon });
                            setDialogIsOpen(true);
                        } else {
                            throw new Error("No fallback options found for the current position while requesting an extra task.");
                        }
                    }
                }
            })
            .catch(error => {
                let notificationMessage = '';
                switch (error?.code) {
                    case 1: // PERMISSION_DENIED
                        notificationMessage = t('TaskRequestErrorPermissionDenied');
                        break;
                    case 2: // POSITION_UNAVAILABLE
                        notificationMessage = t('TaskRequestErrorLocationNotAvailable');
                        break;
                    case 3: // TIMEOUT
                        notificationMessage = t('TaskRequestErrorTimeOut');
                        break;
                    default:
                        notificationMessage = t('SomethingUnexpectedHappend');
                        break;
                }

                enqueueSnackbar(notificationMessage, {
                    variant: 'error',
                    action: (key) => handleCloseSnackbar(key),
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    autoHideDuration: 10000
                });

                queries.addLogMessage(`Unable to get current position because of "${error?.message} (Code: ${error?.code})"`, ['EXTRA-TASK'], error);
            });
    }

    const requestExtraTask = async (auth) => {
        setDialogIsOpen(false);
        if (navigator.onLine) {
            setIsDisabled(true);
            auth.getUser()
                .then((user) => {
                    auth.fetch('get', `projectphases/maintenanceprojectphase/${user.profile.sub}?lat=${currentLocation.lat}&lon=${currentLocation.lon}`)
                        .then(async (result) => {
                            let snackbarMessage = t((result && result.status === 200) ? 'RequestSuccessfullySent' : 'RequestFailed');
                            let snackbarVariant = (result && result.status === 200) ? 'success' : 'error';

                            enqueueSnackbar(snackbarMessage, {
                                variant: snackbarVariant,
                                action: (key) => handleCloseSnackbar(key),
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                },
                                autoHideDuration: 3000
                            });
                        })
                        .catch(error => {
                            let snackbarErrorMessage = t(
                                (error != null && error.response != null && error.response.status === 404) ? 
                                    'NoMaintenanceProjectFound' : 'RequestFailed'
                            );

                            enqueueSnackbar(snackbarErrorMessage, {
                                variant: 'error',
                                action: (key) => handleCloseSnackbar(key),
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                },
                                autoHideDuration: 3000
                            });
                        })
                        .finally(() => setIsDisabled(false));
            });
        } else {
            enqueueSnackbar(t('YouAreOffline'), {
                variant: 'error',
                action: (key) => handleCloseSnackbar(key),
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                },
                autoHideDuration: 3000
            });
        }
     }

     const handleCloseSnackbar = (key) => {
        return <FontAwesomeIcon icon={["fal", "times"]} 
                    onClick={() => { closeSnackbar(key); }} style={{ marginRight: 10 }} />;
     }

    return (
        visible ?
            <>
                <button onClick={handleButtonClick} className="default-link" disabled={isDisabled}>
                    <FontAwesomeIcon icon={["fad", "plus-circle"]} size="2x" style={isDisabled ? "" : FontAwesomeDuoToneStyle} />
                </button>
                <Dialog open={requestLocationInfoDialogOpen}>
                    <DialogTitle id="alert-dialog-title">{t("Info")}</DialogTitle>
                    <DialogContent id="alert-dialog-description">
                        <DialogContentText>{t('TaskRequestErrorInformation')}</DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={dialogIsOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{t("ConfirmRequest")}</DialogTitle>
                    <DialogContent id="alert-dialog-description">
                        <DialogContentText>{t("RequestTaskConfirmation")}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={() => setDialogIsOpen(false)}>{t("Cancel")}</Button>
                        <Button color="primary" onClick={() => requestExtraTask(auth)} autoFocus disabled={isDisabled}>{t("Yes")}</Button>
                    </DialogActions>
                </Dialog>
            </> :
            <></>
    );
}

export default TaskButton;