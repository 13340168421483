import React, { useState } from 'react';
import { motion } from "framer-motion";
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';

const useStyles = makeStyles({
    root: {
        width: '100%'
    }
});

const PermissionsView = ({ permissions }) => {
    const [cachedPermissions, setCachedPermissions] = useState([]);
    const [t,] = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        setCachedPermissions(permissions.sort((a, b) => a.name.localeCompare(b.name)));
    }, [permissions]);

    const getPermissionStateIcon = (state) => {
        switch(state) {
            case 'granted':
                return <FontAwesomeIcon icon={["fas", "check"]} size="2x" style={{ color: 'green' }} />;
            case 'prompt':
                return <FontAwesomeIcon icon={["fas", "question-circle"]} size="2x" style={{ color: 'orange' }} />;
            case 'denied':
                return <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} size="2x" style={{ color: 'red' }} />;
            default:
                return <></>;
        }
    };

    return (
        <motion.div className="settings-container" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            {
                cachedPermissions && cachedPermissions.length > 0 ?
                    <List className={classes.root}>
                        {
                            cachedPermissions.map(permission => 
                                <ListItem key={permission.name}>
                                    <ListItemText primary={t("AppPermission_" + permission.name)} />
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end">
                                            {getPermissionStateIcon(permission.state)}
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        }
                    </List> : <></>
            }
        </motion.div>
    );
};

export default PermissionsView;