import { useCallback, useRef } from "react";

/**
 * Hook to prevent multiple button presses while executing the handler of the first button press.
 */
export function useSinglePress(clickHandler) {
    const executingHandler = useRef(false);

    const singlePressHandler = useCallback(async (...args) => {
        if (executingHandler.current)
            return;

        if (clickHandler != null && typeof(clickHandler) === 'function') {
            executingHandler.current = true;
            Promise.resolve(clickHandler(...args))
                .finally(() => executingHandler.current = false);
        }
    }, [clickHandler]);

    return singlePressHandler;
};