import React, { useState, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AuthContext } from '../../providers/AuthProvider';

function DownloadButton({ url }) {
    const [t] = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const auth = useContext(AuthContext);

    const handleClose = (key) => {
        return <FontAwesomeIcon icon={["fal", "times"]} 
                    onClick={() => { closeSnackbar(key); }} style={{ marginRight: 10 }} />;
    }

    const handleFileDownload = (e) => {
        e.preventDefault();
        setIsLoading(true);

        setTimeout(() => {
            auth.getUser().then(async (user) => {
                await auth.fetch('get', url)
                    .then(handleResponse)
                    .catch(err => console.log(err));
            }).catch(() => {
                enqueueSnackbar(t('DownloadFailed'), {
                    variant: 'error',
                    action: (key) => handleClose(key)
                });
                setIsLoading(false);
            });
        }, 700);
        
    }

    const handleResponse = (response) => {  const byteCharacters = atob(response.data.filedata);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const objectUrl = window.URL.createObjectURL(new Blob([new Uint8Array(byteNumbers)], { type: response.data.mimetype }));
        const link = document.createElement('a');
        link.href = objectUrl;
        link.setAttribute('download', response.data.filename);
        document.body.appendChild(link);
        link.click();
        setTimeout(() => window.URL.revokeObjectURL(objectUrl), 100);
        setIsLoading(false);
    }

    return (
        <Button href="#" onClick={handleFileDownload} variant="outlined" color="primary" style={{ height: "34px", width: "64px" }}>
            <>
                {
                    isLoading ?
                        <CircularProgress size={21} /> :
                        <span className="button-text-with-icon" style={{ padding: "0px" }}>{t("Open")}</span>
                }
            </>
        </Button>
    );
}

export default DownloadButton;