import { Box, makeStyles, useTheme } from "@material-ui/core";
import ResponsiveButton from "../../../../../components/responsive-button/responsive-button";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    hasNoLabel: {
        '& .MuiButton-startIcon': {
            marginRight: 0
        }
    }
}));

const UpdateStatusButton = ({ 
    icon, 
    iconColor = 'primary', 
    label,
    disabled = false, 
    onClick 
}) => {
    const theme = useTheme();
    const classes = useStyles();
    
    return (
        <Box component='span' style={{color: theme.palette[iconColor]?.main ?? iconColor}}>
            <ResponsiveButton color="inherit" onClick={onClick} disabled={disabled} startIcon={icon} className={clsx({ [classes.hasNoLabel]: label == null })}>
                {label}
            </ResponsiveButton>
        </Box>
    );
}

export default UpdateStatusButton;