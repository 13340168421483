import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PauseButton from '../../components/pauseButton';
import ModernisationProjectsButton from '../../components/modernisationProjectsButton';
import TaskButton from '../../components/taskButton';
import { Typography, makeStyles, Card, CardContent, CardActions, IconButton, Box, LinearProgress } from '@material-ui/core';
import hexToRgba from 'hex-to-rgba';
import { FontAwesomeDuoToneStyle } from '../../fontawesome';
import DataReloader from '../../components/dataReloader/data-reloader';
import { DateUtils } from '../../utils/date-utils';
import { AppContext } from '../../utils/contexts/appContext';
import { useApplicationParams } from '../../hooks/app-params';

const useStyles = makeStyles(theme => ({
    card: {
        alignItems: 'center',
        border: `2px solid ${theme.palette.secondary.contrastText} !important`,
        borderBottomColor: `${theme.palette.primary.main} !important`,
        boxShadow: `0 1px 15px 1px ${hexToRgba(theme.palette.secondary.main, .2)} !important`,
        borderRadius: 8
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 0 auto'
    },
    content: {
        display: 'flex'
    },
    contentBottom: {
        marginTop: 10
    },
    navigation: {
        width: 40,
        textAlign: 'center'
    },
    sectionTitle: {
        textTransform: 'uppercase'
    },
    routeTitle: {
        padding: '10px 5px 5px'
    },
    actionButton: {
        marginLeft: 'auto'
    }
}));

const RouteCard = ({ route, progressStatistics = {} }) => {
    const classes = useStyles();
    const history = useHistory();
    const [t,] = useTranslation();
    const { url } = useRouteMatch();
    const [routeProgress, setRouteProgress] = useState(0);

    useEffect(() => {
        if (progressStatistics != null) {
            let totalItems = progressStatistics.totalDrops ?? 0 + progressStatistics.totalBufferSlots ?? 0;
            let finishedItems = progressStatistics.finishedDrops ?? 0 + progressStatistics.finishedBufferSlots ?? 0;

            setRouteProgress(totalItems > 0 ? Math.round((finishedItems/totalItems)*100) : 100);
        }
    }, [progressStatistics]);

    const navigateToRouteOverview = (e, routeId) => {
        e.preventDefault();
        history.push(`/routes/${routeId}/overview`);
    };

    return (
        <Box>
            <div className={classes.routeTitle}>
                {route.name}
            </div>
            <Link to={`${url}/${route.id}`}>
                <Card className={classes.card}>
                    <CardContent className={classes.content} style={{alignItems: 'center'}}>
                        <div className={classes.contentWrapper}>
                            <Typography>
                                <FontAwesomeIcon icon={["fal", "car"]} size="lg" />
                                {route.vehicle}
                            </Typography>
                            <Typography>
                                <FontAwesomeIcon icon={["far", "calendar-alt"]} size="lg" /> 
                                {route.date}
                            </Typography>
                            <Typography>
                                <FontAwesomeIcon icon={["fal", "clock"]} size="lg" /> 
                                {`${route.startString} - ${route.stopString}`}
                            </Typography>
                            <Typography>
                                <FontAwesomeIcon icon={["fal", "road"]} size="lg" /> 
                                <span>{route.distance} km</span>
                            </Typography>
                            <Box display="flex" alignItems="center" mt={3}>
                                <Box width="100%" mr={4}>
                                    <LinearProgress variant="determinate" value={routeProgress} />
                                </Box>
                                <Box minWidth={35}>
                                    <Typography variant="body2" color="textSecondary">
                                        {`${Math.round(routeProgress)}%`}
                                    </Typography>
                                </Box>
                            </Box>
                        </div>
                        <div className={classes.navigation}>
                            <FontAwesomeIcon icon={["far", "angle-right"]} size="lg" />
                        </div>
                    </CardContent>
                    <CardActions>
                        <Box display="flex" width="100%">
                            <Box flexGrow={1}>
                                {
                                    route.realStart ?
                                        <div className={classes.contentBottom}>
                                            <span>{`${t("StartedOn")}: ${DateUtils.getFormattedDateTimeString(new Date(route.realStart))}`}</span>
                                        </div> : <></>
                                }
                            </Box>
                            <IconButton onClick={(e) => navigateToRouteOverview(e, route.id)} className={classes.actionButton}>
                                <FontAwesomeIcon icon={["fad", "file-alt"]} size="lg"  style={FontAwesomeDuoToneStyle} />
                            </IconButton>
                        </Box>
                    </CardActions>
                </Card>
            </Link>
        </Box>
    );
};

const RouteList = ({ routes, progressStatistics = {} }) => {
    const [t,] = useTranslation();

    if (routes.length <= 0) {
        return (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <div>{t("NoRoutesAvailable")}</div>
            </motion.div>
        );
    }

    return (
        <>{routes.map(route => <RouteCard key={route.id} route={route} progressStatistics={progressStatistics[route.id]} />)}</>
    );
}

const RoutesView = ({ openRoutes, interruptedRoutes, newRoutes, finishedRoutes, progressStatistics }) => {
    const classes = useStyles();
    const appParams = useApplicationParams();
    const [t,] = useTranslation();
    const [{ executingPlanningItem }] = useContext(AppContext);

    return (
        <>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <div style={{ paddingBottom: "20px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <Typography color="primary" variant="h6" className={classes.sectionTitle}>
                                <FontAwesomeIcon icon={["fad", "hourglass-half"]} size="sm" /> {t("Open")} ({openRoutes.length})
                            </Typography>
                        </div>
                        <div style={{ alignSelf: "center" }}>
                            <ModernisationProjectsButton visible={appParams?.selectModernisationProjectAllowed} />
                            <TaskButton visible={appParams?.extraTaskAllowed} />
                            <PauseButton visible={appParams?.pauseAllowed && openRoutes.length > 0 && openRoutes.some((route) => route.status === 30) && !executingPlanningItem} />
                        </div>
                    </div>
                    <RouteList routes={openRoutes} progressStatistics={progressStatistics} />
                </div>
                <div style={{ paddingBottom: "20px" }}>
                    <Typography color="primary" variant="h6" className={classes.sectionTitle}>
                        <FontAwesomeIcon icon={["fad", "hourglass"]} size="sm" /> {t("Interrupted")} ({interruptedRoutes.length})
                    </Typography>
                    <RouteList routes={interruptedRoutes} progressStatistics={progressStatistics} />
                </div>
                <div style={{ paddingBottom: "20px" }}>
                    <Typography color="primary" variant="h6" className={classes.sectionTitle}>
                        <FontAwesomeIcon icon={["fad", "hourglass-start"]} size="sm" /> {t("New")} ({newRoutes.length})
                    </Typography>
                    <RouteList routes={newRoutes} progressStatistics={progressStatistics} />
                </div>
                <div>
                    <Typography color="primary" variant="h6" className={classes.sectionTitle}>
                        <FontAwesomeIcon icon={["fad", "check"]} size="sm" /> {t("Finished")} ({finishedRoutes.length})
                    </Typography>
                    <RouteList routes={finishedRoutes} progressStatistics={progressStatistics} />
                </div>
                <DataReloader />
            </motion.div>
        </>
    );
}

export default RoutesView;