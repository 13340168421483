import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { useAppContext } from '../../utils/contexts/appContext';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { motion } from "framer-motion";
import './Admin.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSinglePress } from '../../hooks/single-press';

function Admin() {
    const [, dispatch] = useAppContext();
    const [t] = useTranslation();

    const [location, setLocation] = useState();
    const [position, setPosition] = useState([51.505, -0.09]);
    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
    });

    const handleLocation = () => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(function (location) {
                setLocation(`lat: ${location.coords.latitude} & lng: ${location.coords.longitude}`);
                setPosition([location.coords.latitude, location.coords.longitude]);
            },
            error => console.info(error),
            { 
                enableHighAccuracy: true,
                timeout: 1000
            });
        } else {
            alert('Geolocation API not supported.');
        }
    }

    const onClickHandlerTester = useSinglePress(async (e) => {
        console.log("executing click handler", e);
        
        await new Promise((resolve, reject) => {
            setTimeout(resolve, 6000);
        }).then(() => {
            console.log("onClickHandlerTester", new Date().toISOString());
        });
    });

    useEffect(() => {
        dispatch({ type: 'changeHeader', header: { title: t("Admin"), child: false } });
    }, [dispatch, t]);

    return (
        <motion.div className="center" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div className="gradient"></div>
            <div className="global-container">
                <div className="card">
                    <div className="card-header-tab card-header">
                        <div className="card-header-title">
                            <FontAwesomeIcon icon={["fal", "map-marker-alt"]} /> VOORBEELD LOCATIEBEPALING
                        </div>
                    </div>

                    <div className="card-body">
                        <Button onClick={handleLocation} variant="contained" color="primary">
                            Bepaal locatie
                        </Button>
                        <div style={{ padding: "15px" }}>{location}</div>
                        <div >
                            <MapContainer center={position} zoom={13}>
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                                />
                                <Marker position={position}>
                                    <Popup>Jouw huidige locatie...(als je op `bepaal locatie` geklikt hebt)</Popup>
                                </Marker>
                            </MapContainer>
                        </div>
                        <Button onClick={onClickHandlerTester} variant="contained" color="primary">
                            Test click-handler
                        </Button>
                    </div>
                </div>
            </div>
        </motion.div>
    );
}

export default Admin;