import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useAppContext } from '../../utils/contexts/appContext';
import db from '../../utils/dexie/driverappdb';
import queries from '../../utils/dexie/queries';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FontAwesomeDuoToneStyle } from '../../fontawesome';
import { Actions } from '../../utils/enums/actions';
import PauseDialog from '../pause-dialog';
import { useRef } from 'react';

const PauseButton = ({ visible, useIconStyle = true }) => {
    let history = useHistory();
    const [{ pause, pauseId }, dispatch] = useAppContext();
    const globalPause = pause && pauseId == null;
    
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const dialogRef = useRef(null);

    const handlePauseButton = async () => {
        if (globalPause) {
            stopPause();
        } else {
            await dialogRef.current.resetDialog()
                .finally(() => setDialogIsOpen(true));
        }
    };

    const startPause = async (breakType, remark) => {
        try {            
            let now = new Date();
            var utc = now.getTime() - now.getTimezoneOffset() * 60000;

            var openRoute = await db.routes.where("status").equals(30)
                .first();

            let pauseStartAction = { 
                type: Actions.PAUSE_START, 
                time: utc, 
                breakTypeId: breakType, 
                remark: remark.value,
                simulationSolutionRouteId: openRoute?.id
            };

            queries.isActionAllowed(pauseStartAction)
                .then(allowed => {
                    if (allowed) {
                        db.rests.add({ start: utc, end: undefined }).then(() => {                 
                            dispatch({ type: 'togglePause', pause: true });
                            db.actions.put(pauseStartAction);
                        });
                        setDialogIsOpen(false);
                    }
                });
        } catch (error) {
            queries.addErrorToLogs(error);
            history.push(`/error`);
        }
    }

    const stopPause = () => {
        db.rests.orderBy('id').last()
            .then(async function (rest) {
                if (rest !== undefined) {
                    let now = new Date();
                    var utc = now.getTime() - now.getTimezoneOffset() * 60000;
                    
                    var openRoute = await db.routes.where("status").equals(30)
                        .first();

                    let pauseEndAction = { 
                        type: Actions.PAUSE_STOP, 
                        time: utc,
                        simulationSolutionRouteId: openRoute?.id
                    };

                    queries.isActionAllowed(pauseEndAction)
                        .then(allowed => {
                            if (allowed) {
                                db.rests.update(rest.id, { end: utc }).then(() => {
                                    dispatch({ type: 'togglePause', pause: false });
                                    db.actions.put(pauseEndAction);
                                });
                            }
                        });
                }
            });
    }

    return (
        visible && pauseId == null ?
            <PauseDialog
                ref={dialogRef}
                isOpen={dialogIsOpen}
                onConfirm={startPause}
                onCancel={() => setDialogIsOpen(false)}>
                <button onClick={handlePauseButton} className="default-link">
                    <FontAwesomeIcon icon={["fad", globalPause ? "play-circle" : "pause-circle"]} style={useIconStyle ? FontAwesomeDuoToneStyle : null} size="2x" />
                </button>
            </PauseDialog> :
            null
    );
}

export default PauseButton;