import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@material-ui/core";

const NavigateButton = ({ location, navigationApp, iconColor }) => {

    const handleNavigation = () => {
        switch (navigationApp) {
            case "google":
                window.open("https://www.google.com/maps/dir/?api=1&destination=" + encodeURI(location.address) + "&directionsmode=driving");
                break;
            case "here":
                window.open(`https://share.here.com/r/${location.lat},${location.lon},${encodeURI(location.address)}?m=d`);
                break;
            case "waze":
            default:
                window.open("https://www.waze.com/ul?q=" + encodeURI(location.address) + "&navigate=yes&zoom=17");
                break;
        }
    }

    return (
        location ?
            <Button color={iconColor ?? "primary"} onClick={handleNavigation}>
                <FontAwesomeIcon size="lg" icon={["fad", "map-marker-alt"]} />
            </Button> : null
    );
};

export default NavigateButton;