export const RemarkFieldType = {
    TEXT: 1,
    CHECKBOX: 2,
    EMAIL: 3,
    PHONENUMBER: 4,
    URL: 5,
    INT: 6,
    DOUBLE: 7,
    FLOAT: 8,
    DROPDOWN: 9
};