import { useContext } from "react";
import db from "../utils/dexie/driverappdb";
import { RoutePlanningContext, RoutePlanningDispatchContext } from "../utils/contexts/route-planning-context";
import { RoutePlanRepository } from "../utils/dexie/repositories/route-plan-repository";
import { useApplicationSettings } from "./app-settings";
import utils from "../utils";
import { AppContext } from "../utils/contexts/appContext";
import { PlanningItemStatus } from "../utils/enums/planning-item-status";

export function usePlanItemStatusManagement(routePlanId, type, payloadId, hashCode) {
    const [getSetting] = useApplicationSettings();
    const [, dispatchApp] = useContext(AppContext);
    const dispatch = useContext(RoutePlanningDispatchContext);
    const { planningItems, filteredPlanningItems } = useContext(RoutePlanningContext);

    const updatePlanItemStatus = async (newStatus) => {
        let updatedPlanItemData = {
            status: newStatus
        };

        await db.routeplanitemdata.update({ routePlanId: routePlanId, type: type, payloadId: payloadId }, updatedPlanItemData)
            .then(async () => {
                let openPlanningItems = await RoutePlanRepository.countOpenPlanningItems(routePlanId);

                let itemsWithPayload = await RoutePlanRepository.getItemsWithPayload(routePlanId, utils.toBoolean(getSetting('ShowFinishedDeliveries')), 
                    utils.toBoolean(getSetting('ShowFinishedBufferSlots')), utils.toBoolean(getSetting('ShowFinishedUnproductiveTimes')));

                let updatedPlanningItems = itemsWithPayload
                    .filter(item => planningItems.find(planningItem => planningItem.hashCode === item.hashCode) != null)
                    .map(planningItem => {
                        if (planningItem.hashCode === hashCode)
                            planningItem.status = newStatus;
                        return planningItem
                    });

                let updatedFilteredPlanningItems = itemsWithPayload
                    .filter(item => filteredPlanningItems.find(filteredPlanningItem => filteredPlanningItem.hashCode === item.hashCode) != null)
                    .map(planningItem => {
                        if (planningItem.hashCode === hashCode)
                            planningItem.status = newStatus;
                        return planningItem
                    });

                if (dispatch != null) {
                    // If we've found a RoutePlanningDispatchContext (= we're on the route planning screen)
                    dispatch({
                        type: 'planningitem.status',
                        payload: {
                            newPlanningItemStatus: newStatus,
                            hashCode: hashCode,
                            openPlanningItemsCount: openPlanningItems,
                            updatedPlanningItems: updatedPlanningItems,
                            updatedFilteredPlanningItems: updatedFilteredPlanningItems
                        }
                    });
                }

                if (dispatchApp != null) {
                    dispatchApp({
                        type: 'planningitem.execution',
                        executingPlanningItem: newStatus === PlanningItemStatus.IN_PROGRESS
                    });
                }
            });
    };

    return updatePlanItemStatus;
};