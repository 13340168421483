import { Container } from "@material-ui/core";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Loader from "../../../../components/loader";
import db from "../../../../utils/dexie/driverappdb";
import ReturnablesManagerView from "./ReturnablesManagerView";

const ReturnablesManagerContainer = forwardRef(({ dropId, readonly }, ref) => {

    const [isLoading, setIsLoading] = useState(true);
    const [returnables, setReturnables] = useState([]);

    const returnablesView = useRef();

    useEffect(() => {
        db.transaction('r', db.returnables, db.returnablesdata, async () => {
            return await db.returnablesdata.where({ dropId: parseInt(dropId) })
                .toArray(returnablesData => {
                    const returnablesPromises = returnablesData.map(returnableData => db.returnables.get(returnableData.returnableId));
                    return Promise.all(returnablesPromises).then(returnables => {
                        returnables.forEach((returnable, i) => {
                            if (returnable != null) {
                                Object.defineProperty(returnable, 'data', {
                                    value: returnablesData[i],
                                    enumerable: false
                                });
                            }
                        });
                        
                        return returnables.sort((a, b) => {
                            if (a.seqNr == null)
                                return 1;
                            else if (b.seqNr == null)
                                return -1;
                            else
                                return a.seqNr - b.seqNr;
                        });
                    });
                })
                .then(returnables => setReturnables(returnables));
        })
        .finally(() => setIsLoading(false));
    }, [dropId]);

    useImperativeHandle(ref, () => ({
        save: () => {
            returnablesView.current.save();
        }
    }));

    return (
        <Container>
            {
                isLoading ?
                    <Loader /> :
                    <ReturnablesManagerView ref={returnablesView} dropId={dropId} returnables={returnables} readonly={readonly} />
            }
        </Container>
    );

});

export default ReturnablesManagerContainer;