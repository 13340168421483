import { useContext, useEffect } from "react";
import Depot from "./depot"
import { RoutePlanningContext, RoutePlanningDispatchContext } from "../../../../../utils/contexts/route-planning-context";
import PlanningItemActions from "../planning-item-actions";
import NavigateButton from "../action-buttons/navigate-button";
import UpdateStatusButton from "../action-buttons/update-status-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRouteStatusManagement } from "../../../../../hooks/route-status-management";
import { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../../../utils/contexts/appContext";
import { RouteStatus } from "../../../../../utils/enums/route-status";
import { RouteRepository } from "../../../../../utils/dexie/repositories/route-repository";
import { RoutePlanRepository } from "../../../../../utils/dexie/repositories/route-plan-repository";
import { useIsMounted } from "../../../../../hooks/is-mounted";
import { useSinglePress } from "../../../../../hooks/single-press";
import PlanningItemHeader from "../planning-item-header";
import PlanningItemHeaderBadge from "../planning-item-header-badge";
import { FontAwesomeDuoToneStyle } from "../../../../../fontawesome";

const StopDepot = (props) => {
    const { location } = props;
    const [t] = useTranslation();
    const history = useHistory();
    const isMounted = useIsMounted();

    const [{pause}] = useContext(AppContext);
    const {routeId, route, navigationApp, filteredPlanningItems, openPlanningItemsCount} = useContext(RoutePlanningContext);
    const dispatch = useContext(RoutePlanningDispatchContext);
    const [routeHasBusyPlanningItem, setRouteHasBusyPlanningItem] = useState(false);

    const [interruptRouteConfirmationOpen, setInterruptRouteConfirmationOpen] = useState(false);
    const updateRouteStatus = useRouteStatusManagement(routeId);

    useEffect(() => {
        async function init() {
            let busy = await RoutePlanRepository.hasBusyPlanItem(routeId);
            if (isMounted()) {
                setRouteHasBusyPlanningItem(busy);
            }
        }

        init();

        // Effects:
        // - filteredPlanningItems: on status update of a planning item, we need to recheck if there is a busy item
    }, [routeId, filteredPlanningItems, isMounted]);

    const stopRoute = useSinglePress(async (e) => {
        await updateRouteStatus(RouteStatus.FINISHED)
            .then(dispatch({ 
                type: 'route.status', 
                payload: {
                    newStatus: RouteStatus.FINISHED,
                    openRoutesCount: await RouteRepository.countOpenRoutes()
                }
            }));
    });

    const interruptRoute = useSinglePress(async (e) => {
        setInterruptRouteConfirmationOpen(false);
        await updateRouteStatus(RouteStatus.INTERRUPTED)
            .then(dispatch({ 
                type: 'route.status', 
                payload: {
                    newStatus: RouteStatus.INTERRUPTED,
                    openRoutesCount: await RouteRepository.countOpenRoutes()
                }
            }))
            .then(() => history.push('/routes'));
    });

    return (
        route != null ?
            <Depot {...props}
                header={
                    <PlanningItemHeader>
                        <PlanningItemHeaderBadge>
                            <FontAwesomeIcon icon={["fad", "clock"]} style={FontAwesomeDuoToneStyle} />
                            <Typography component="span">
                                {route.stopString}
                            </Typography>
                        </PlanningItemHeaderBadge>
                    </PlanningItemHeader>
                }>
                {
                    route.status < RouteStatus.FINISHED ?
                        <PlanningItemActions>
                            <NavigateButton location={location} navigationApp={navigationApp} />
                            <UpdateStatusButton iconColor="success" icon={<FontAwesomeIcon size="lg" icon={["fad", "check"]} />} 
                                disabled={pause || route.status !== RouteStatus.BUSY || routeHasBusyPlanningItem || openPlanningItemsCount > 0} onClick={stopRoute} />
                            <UpdateStatusButton iconColor="success" icon={<FontAwesomeIcon size="lg" icon={["fad", "pause-circle"]} />} 
                                disabled={pause || route.status !== RouteStatus.BUSY || routeHasBusyPlanningItem} onClick={() => setInterruptRouteConfirmationOpen(true)} />
                        </PlanningItemActions> : null
                }
                <Dialog open={interruptRouteConfirmationOpen} onClose={() => setInterruptRouteConfirmationOpen(false)}>
                    <DialogTitle>{t('Interrupt')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{t("ConfirmRouteInterruptRequest")}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setInterruptRouteConfirmationOpen(false)}>{t("Cancel")}</Button>
                        <Button color="primary" onClick={interruptRoute} autoFocus>{t("Interrupt")}</Button>
                    </DialogActions>
                </Dialog>
            </Depot> 
            : null
    );
}

export default StopDepot;