import React from 'react';
import { Link, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FontAwesomeDuoToneStyle } from '../../fontawesome';

const useStyle = makeStyles(theme => ({
    root: {
        padding: '1px 6px'
    }
}));

const ModernisationProjectsButton = ({ visible }) => {
    const classes = useStyle();
    const [t] = useTranslation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const checkNavigationPossible = (e) => {
        if (!navigator.onLine) {
            e.preventDefault();
            enqueueSnackbar(t('YouAreOffline'), {
                variant: 'error',
                action: (key) => handleClose(key),
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                },
                autoHideDuration: 3000
            });
        }
    }

    const handleClose = (key) => {
        return <FontAwesomeIcon icon={["fal", "times"]} 
                    onClick={() => { closeSnackbar(key); }} style={{ marginRight: 10 }} />;
    };

    return (
        visible ?
            <Link onClick={checkNavigationPossible} href="/modernisations" className={classes.root}>
                <FontAwesomeIcon icon={["fad", "list-alt"]} size="2x" style={FontAwesomeDuoToneStyle} />
            </Link> :
            <></>
    )
}

export default ModernisationProjectsButton;