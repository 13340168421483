import { UserManager, WebStorageStateStore, Log } from 'oidc-client';
import queries from '../utils/dexie/queries';
import { fetchOfflineData, fetchRoutes, syncActions, cleanLocalData } from '../utils/dexie/sync';
import db from '../utils/dexie/driverappdb';
import axios from 'axios';
import i18next from 'i18next';

const IDENTITY_CONFIG = {
    authority: process.env.REACT_APP_AUTH_URL, //(string): The URL of the OIDC provider.
    client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID, //(string): Your client application's identifier as registered with the OIDC provider.
    redirect_uri: process.env.REACT_APP_REDIRECT_URL + "/signin-oidc", //The URI of your client application to receive a response from the OIDC provider.
    automaticSilentRenew: false, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
    loadUserInfo: false, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
    silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URL + "/silentrenew", //(string): The URL for the page containing the code handling the silent renew.
    post_logout_redirect_uri: process.env.REACT_APP_LOGOFF_REDIRECT_URL + "/logout/callback", // (string): The OIDC post-logout redirect URI.
    response_type: "id_token token", //(string, default: 'id_token'): The type of response desired from the OIDC provider.
    scope: "openid profile MoveteXLiteFleetApi",
    api_url: process.env.REACT_APP_API_URL
};

const METADATA_OIDC = {
    issuer: process.env.REACT_APP_AUTH_URL,
    jwks_uri: process.env.REACT_APP_AUTH_URL + "/.well-known/openid-configuration/jwks",
    authorization_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/authorize",
    token_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/token",
    userinfo_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/userinfo",
    end_session_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/endsession",
    check_session_iframe: process.env.REACT_APP_AUTH_URL + "/connect/checksession",
    revocation_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/revocation",
    introspection_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/introspect"
};

export default class AuthService {

    userManager;

    constructor() {
        this.userManager = new UserManager({
            ...IDENTITY_CONFIG,
            userStore: new WebStorageStateStore({ 
                store: window.localStorage 
            }),
            metadata: {
                ...METADATA_OIDC
            }
        });

        // Logger
        Log.logger = console;
        Log.level = Log.ERROR;

        this.bindToEvents();
    }

    /**
     * Binds functions to the events of the UserManager.
     */
    bindToEvents = () => {

        // Raised when a user session has been established (or re-established).
        this.userManager.events.addUserLoaded(user => {
            if (window.location.href.indexOf("signin-oidc") !== -1) {
                // Make sure the language is the language which has been configured in the back-end for the user
                i18next.changeLanguage(user?.profile?.locale?.toLowerCase());

                // Only in the case that the user comes from the login redirect url, we are going to redirect the user to the principal private screen
                this.navigateToScreen();
            }
        });

        // Raised when the automatic silent renew has failed.
        this.userManager.events.addSilentRenewError(e => {
            console.log("[AuthService.js] Silent renew error", e.message);
            queries.addErrorToLogs(e);
        });

        // Raised after the access token has expired.
        this.userManager.events.addAccessTokenExpired(async () => {
            console.log("[AuthService.js] Token expired");
            await this.signinSilent();
        });

    }

    /**
     * Navigates to the principal private screen.
     */
    navigateToScreen = () => {
        window.location.replace(localStorage.getItem(`${process.env.REACT_APP_IDENTITY_CLIENT_ID}_redirectURL`));
    }

    parseJwt = (token) => {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        return JSON.parse(window.atob(base64));
    }

    getIdentityStorage = () => {
        return JSON.parse(localStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`));
    }

    isAuthenticated = () => {
        const oidcStorage = this.getIdentityStorage();
        return (!!oidcStorage && !!oidcStorage.access_token);
    }

    getUser = async () => {
        const user = await this.userManager.getUser();

        if (!user) {
            return await this.userManager.signinRedirectCallback();
        }

        return user;
    }

    signinRedirect = () => {
        localStorage.setItem(`${process.env.REACT_APP_IDENTITY_CLIENT_ID}_redirectURL`, window.location.pathname);
        this.userManager.signinRedirect({});
    }

    signinRedirectCallback = async () => {
        this.userManager.signinRedirectCallback()
            .then(async (user) => {
                return await db.datastore.put({
                    key: 'syncing',
                    value: 0
                })
                .then(async () => {
                    return await fetchOfflineData(this)
                        .then(async () => syncActions(this))
                        .then(({canFetchNewRoutes, actionsExecuted}) => fetchRoutes(this, user, canFetchNewRoutes, actionsExecuted))
                        .then(async () => await cleanLocalData())
                        .catch(error => console.error(error))
                        .finally(() => this.navigateToScreen());
                })
                .catch(error => console.error(error))
                .finally(() => this.navigateToScreen());
            })
            .catch(error => {
                window.history.replaceState({},
                    window.document.title,
                    window.location.origin + window.location.pathname);
                window.location = "/";
            });
    }

    signinSilent = () => {
        if (navigator.onLine) {
            return this.userManager.signinSilent()
                .catch(error => this.logout());
        }
    };

    signinSilentCallback = () => {
        this.userManager.signinSilentCallback();
    }

    createSigninRequest = () => {
        return this.userManager.createSigninRequest();
    }

    logout = () => {
        const oidcStorage = this.getIdentityStorage();

        this.userManager.signoutRedirect({
            id_token_hint: (!!oidcStorage && !!oidcStorage.id_token) ? oidcStorage.id_token : ""
        });

        this.userManager.clearStaleState();
    };

    signoutRedirectCallback = () => {
        this.userManager.signoutRedirectCallback()
            .then(() => {
                localStorage.clear();
                window.location.replace(process.env.REACT_APP_PUBLIC_URL);
            });
        this.userManager.clearStaleState();
    };

    async fetch(type, url, params) {
        const requestUrl = `${IDENTITY_CONFIG.api_url}/${url}`;
        const user = await this.getUser(); 

        if (user && user.access_token) {
            
            return this._callApi(type, requestUrl, params, user.access_token)
                .catch(error => {
                    if (error.response !== undefined && error.response.status === 401) {
                        return this.signinSilent()
                            .then(renewedUser => {
                                return this._callApi(type, requestUrl, params, renewedUser.access_token)
                                    .catch(error => { throw error });
                            });
                    } else {
                        throw error;
                    }
                });
        }
        else if (user) {
            if (url.match(/updateroutestatus|updatedropstatus/)) {
                await queries.addLogMessage(`No access token available (Type: ${type}, Url: ${url})`, ['Fetch Call'], { params: params });
            }

            return this.signinSilent()
                .then(renewedUser => {
                    return this._callApi(type, requestUrl, params, renewedUser.access_token);
                });
        }
        else {
            throw new Error('user is not logged in');
        }
    }

    async _callApi(type, url, params, token) {
        const headers = {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token
        };

        /**
         * Determines whether the promise should be resolve or rejected.
         * By default, Axios will resolve for each statuscode >= 200 and < 300.
         * We'll return a 422 when the entity does not exists anymore on the backend.
         * So we need to make sure it's also resolved to be able to check the status during the synchronization of the actions.
         * @param {*} status 
         * @returns 
         */
        const validateStatus = (status) => (status >= 200 && status < 300) || status === 422;

        if (type.toLowerCase() === "get") {
            try {
                return axios.get(url, { headers, validateStatus });
            }
            catch (error) {
                throw new Error('api get error');
            }
        }

        if (type.toLowerCase() === "post") {
            try {
                return axios.post(url, params, { headers, validateStatus });
            } catch (error) {
                throw new Error('api post error');
            }
        }

        if (type.toLowerCase() === "put") {
            try {
                return axios.put(url, params, { headers, validateStatus });

            } catch (error) {
                throw new Error('api put error');
            }
        }     
        
        if (type.toLowerCase() === "delete") {
            try {
                return axios.delete(url, { headers, validateStatus });
            } catch (error) {
                throw new Error('api delete error');
            }
        }          

        throw new Error(`type ${type} not supported`);
    }

}