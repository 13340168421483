import React from 'react';
import { createContext } from 'react';
import UserAgentService from '../services/UserAgentService';

export const UserAgentContext = createContext({
    getOperatingSystem: () => {},
    checkBrowserSupport: () => {},
    isFeatureSupported: (feature) => {}
});

export const UserAgentConsumer = UserAgentContext.Consumer;

export const UserAgentProvider = (props) => {

    const userAgentService = new UserAgentService();

    return (
       <UserAgentContext.Provider value={userAgentService}>
           {props.children}
       </UserAgentContext.Provider>
    );

}