import { Card, CardContent, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'inline-flex',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    inner: {
        display: 'inline-flex',
        alignItems: 'center',
        padding: `${theme.spacing(1)} !important`,
        '&> span': {
            fontWeight: 700,
            paddingLeft: theme.spacing(2)
        }
    }
}));

const PlanningItemHeaderBadge = (props) => {
    const classes = useStyles();

    return (
        <Card component="span" mb={2} elevation={2} className={classes.root}>
            <CardContent component="span" className={classes.inner}>{props.children}</CardContent>
        </Card>
    );
};

export default PlanningItemHeaderBadge;