import { useHistory } from "react-router-dom";
import { DateUtils } from "../../../../utils/date-utils";
import queries from "../../../../utils/dexie/queries";
import { Actions } from "../../../../utils/enums/actions";
import { PlanningItemStatus } from "../../../../utils/enums/planning-item-status";
import db from "../../../../utils/dexie/driverappdb";
import { usePlanItemStatusManagement } from "../../../../hooks/plan-item-status-management";
import { PlanningItemType } from "../../../../utils/enums/planning-item-type";

export function useBufferSlotStatusManagement(bufferSlotId, routeId, hashCode) {
    const history = useHistory();
    const updateRoutePlanningItemStatus = usePlanItemStatusManagement(routeId, PlanningItemType.BUFFERSLOT, bufferSlotId, hashCode);

    const updateBufferSlotStatus = async (planningItemStatus) => {
        try {
            let actionType;
            switch (planningItemStatus) {
                case PlanningItemStatus.IN_PROGRESS:
                    actionType = Actions.BUFFERSLOT_START;
                    break;
                case PlanningItemStatus.FINISHED:
                    actionType = Actions.BUFFERSLOT_STOP;
                    break;
                default:
                    break;
            }

            if (actionType) {
                let now = new Date();
                let nowUTC = DateUtils.convertLocalToUTC(now);

                let updateBufferSlotStatusAction = {
                    type: actionType,
                    key: bufferSlotId,
                    time: nowUTC,
                    routeId: routeId
                };
        
                let isAllowed = await queries.isActionAllowed(updateBufferSlotStatusAction);
                if (isAllowed) {
                    await updateRoutePlanningItemStatus(planningItemStatus);
                    await db.actions.put(updateBufferSlotStatusAction);
                }
            }
        } catch (error) {
            queries.addErrorToLogs(error);
            history.push('/error');
        }
    };

    return updateBufferSlotStatus;
};