import { useState } from "react";
import { useRouteStatusManagement } from "./route-status-management";
import { RouteStatus } from "../utils/enums/route-status";
import { useContext } from "react";
import { RoutePlanningContext } from "../utils/contexts/route-planning-context";
import { useEffect } from "react";
import { PlanningItemType } from "../utils/enums/planning-item-type";
import { DropStatus } from "../utils/enums/drop-status";
import { PlanningItemStatus } from "../utils/enums/planning-item-status";

export function useRouteAutoStart(planningItem) {
    const { routeId, route, routeAutoStart } = useContext(RoutePlanningContext);

    const [autoStartEnabled, setAutoStartEnabled] = useState(false);
    const updateRouteStatus = useRouteStatusManagement(routeId);

    useEffect(() => {
        let planningItemTypeValidCheck = true;
        let routeValidCheck = routeAutoStart && (route.status < RouteStatus.BUSY || route.status === RouteStatus.INTERRUPTED);
        
        switch (planningItem.type) {
            case PlanningItemType.DROP:
                let drop = planningItem.payload;
                planningItemTypeValidCheck = drop.status < DropStatus.BUSY || drop.status === DropStatus.INTERRUPTED;
                break;
            default:
                planningItemTypeValidCheck = planningItem.status === PlanningItemStatus.TO_DO;
                break;
        }

        setAutoStartEnabled(routeValidCheck && planningItemTypeValidCheck);
    }, [planningItem.type, planningItem.status, planningItem.payload, route.status, routeAutoStart]);

    const autoStartRoute = async () => await updateRouteStatus(RouteStatus.BUSY);

    return [autoStartEnabled, autoStartRoute];
};