import { useEffect } from "react";

const NotFound = () => {
    
    useEffect(() => {
        window.location = '/routes';
    }, []);

    return <></>;
};

export default NotFound;